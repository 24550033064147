/* Full screen workflow
/* ---------------------------------------------------------- */

.gh-flow {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 100%;
    background: linear-gradient(135deg, #fff, #f4f4f4);
}

.gh-flow-head {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    padding-top: 4vh;
    padding-bottom: 20px;
}

.gh-flow-content-wrap {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
    padding-bottom: 8vh;
}

.gh-flow-back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin: 0 0 0 3%;
    padding: 2px 9px 2px 5px;
    border: transparent 1px solid;
    border-radius: 4px;
    color: #7d878a;
    font-weight: 300;
    transition: all 0.3s ease;
}

.gh-flow-back svg {
    margin-right: 4px;
    height: 12px;
    line-height: 14px;
}

.gh-flow-back svg path {
    stroke: #7d878a;
    stroke-width: 1.2px;
}

.gh-flow-back:hover {
    border: #dae1e3 1px solid;
}

.gh-flow-nav {
    position: relative;
    flex: 1;
}

.gh-flow-nav ol {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    width: 160px;
    list-style: none;
}

.gh-flow-nav li {
    margin: 0;
}

.gh-flow-nav .divider {
    align-self: center;
    width: 22px;
    height: 2px;
    background-image: linear-gradient(to right, var(--green) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x;
}

.gh-flow-nav .active ~ .divider {
    background-image: linear-gradient(to right, #e3e3e3 33%, rgba(255, 255, 255, 0) 0%);
}

.gh-flow-nav .step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: transparent 2px solid;
    background: var(--green);
    border-radius: 100%;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1;
}

.gh-flow-nav .step .num {
    display: none;
}

.gh-flow-nav .step svg {
    width: 26px;
    height: 26px;
    fill: #fff;
    stroke: #fff;
    stroke-width: 2px;
}

.gh-flow-nav .step svg path {
    stroke: #fff;
}

.gh-flow-nav .active ~ li:not(divider) .step {
    border: #e3e3e3 2px solid;
    background: transparent;
    color: #cdcdcd;
}

.gh-flow-nav .active ~ li:not(divider) .step .num {
    display: block;
}

.gh-flow-nav .active ~ li:not(divider) .step svg {
    display: none;
}

.gh-flow-nav .active .step {
    border: var(--green) 2px solid;
    background: transparent;
    color: color-mod(var(--green) lightness(-10%));
    cursor: default;
}

.gh-flow-nav .active .step .num {
    display: block;
}

.gh-flow-nav .active .step svg {
    display: none;
}

.gh-flow-nav .done {
    border: none;
    background: var(--green);
    color: #fff;
}


.gh-flow-content {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    color: var(--midgrey);
    text-align: center;
    font-size: 1.9rem;
    line-height: 1.5em;
    font-weight: 300;
}

@media (max-width: 500px) {
    .gh-flow-content {
        font-size: 4vw;
    }
}

.gh-flow-content header {
    margin: 0 auto;
    max-width: 520px;
}

.gh-flow-content h1 {
    font-size: 4.2rem;
    font-weight: 300;
}

@media (max-width: 600px) {
    .gh-flow-content h1 {
        font-size: 7vw;
    }
}

.gh-flow-content strong {
    font-weight: 400;
}

.gh-flow-content em {
    color: var(--blue);
    font-weight: 400;
    font-style: normal;
}

.gh-flow-content .gh-flow-screenshot {
    display: flex;
    align-items: center;
    margin: 0;
    height: 45vh;
}

.gh-flow-content .gh-flow-screenshot img {
    position: relative;
    left: -3%;
    flex-shrink: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
}

@media (max-width: 860px) {
    .gh-flow-content .gh-flow-screenshot img {
        left: 0;
    }
}
@media (max-width: 600px) {
    .gh-flow-content .gh-flow-screenshot {
        height: auto;
    }
}

.gh-flow-content .gh-btn {
    display: block;
    margin: 40px auto 0;
    max-width: 400px;
}

.gh-flow-content .login span {
    height: 37px !important;
    line-height: 37px !important;
}

.gh-flow-content .gh-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gh-separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
}

.gh-separator::before,
.gh-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--lightgrey);
}

.gh-separator:not(:empty)::before {
  margin-right: 6px;
}

.gh-separator:not(:empty)::after {
  margin-left: 6px;
}

.gh-flow-content .gh-input:focus {
    box-shadow: none;
    border-color: color-mod(var(--midlightgrey) l(+10%));
}

.gh-flow-content .gh-flow-skip {
    display: inline-block;
    margin-top: 5px;
    color: #7d878a;
    font-size: 1.2rem;
}

.gh-flow-content .gh-flow-create {
    position: relative;
    margin: 70px auto 30px;
    padding: 50px 40px 40px;
    max-width: 400px;
    width: 100%;
    border: #dae1e3 1px solid;
    background: #fff;
    border-radius: 5px;
    text-align: left;
    box-shadow: 0 20px 45px -10px rgba(0, 0, 0, 0.1);
}

.gh-flow-create .gh-btn-create-account span {
    height: 37px;
    line-height: 37px;
}

.gh-flow-content .account-image {
    position: absolute;
    top: -50px;
    left: 50%;
    overflow: hidden;
    margin: 0;
    margin-left: -50px;
    padding: 4px;
    width: 100px;
    height: 100px;
    border: #d1d9db 1px solid;
    background: #fff;
    border-radius: 100%;
    text-align: center;
}

.gh-flow-content .account-image:hover .edit-account-image {
    opacity: 1;
}

.gh-flow-content .edit-account-image {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 8px);
    background: rgba(87, 163, 232, 0.7);
    border-radius: 100%;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 90px;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
}

.gh-flow-content .edit-account-image svg {
    fill: #fff;
    height: 3rem;
    width: auto;
    flex: 1 1 3rem;
}

.gh-flow-content .placeholder-img {
    display: block;
    width: 90px;
    height: 90px;
    background-color: #f8fbfd;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    animation: fade-in 1s;
}

.gh-flow-content .gravatar-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    box-sizing: content-box;
    width: calc(100% - 8px);
    width: 90px;
    height: 90px;
    border: #fff 4px solid;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    animation: fade-in 1s;
}

.gh-flow-content .file-uploader {
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 23px;
    opacity: 0;
    cursor: pointer;
    transform: scale(14);
    transform-origin: right;
    direction: ltr;
}

.gh-flow-content .form-group {
    margin-bottom: 20px !important;
}

.gh-flow-content .form-group label {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
}

.gh-flow-content .form-group a {
    text-decoration: underline;
}

.gh-flow-content input {
    padding: 10px 10px 10px 30px;
    font-size: 1.4rem;
    line-height: 1.4em;
    font-weight: 400;
}

.gh-flow-content .pw-strength {
    position: absolute;
    top: 50%;
    right: 1px;
    margin-top: -11px;
    padding: 0 10px;
    height: 24px;
    background: rgba(255, 255, 255, 0.9);
}

.gh-flow-content .pw-strength-dot {
    display: block;
    margin-top: 2px;
    width: 3px;
    height: 3px;
    background-color: #d9e0e3;
    border-radius: 100%;
}

.gh-flow-content .pw-strength-dot:first-child {
    margin-top: 0;
}

.gh-flow-content .pw-strength-activedot {
    background-color: var(--red);
}

.gh-flow-content .gh-flow-invite {
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    text-align: left;
}

.gh-flow-invite label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gh-flow-invite label svg {
    width: 14px;
    height: 14px;
    line-height: 1.4em;
}

.gh-flow-invite textarea {
    background: var(--input-bg-color) url(img/invite-placeholder.png) 10px 8px no-repeat;
    background-size: 203px 35px;
    border: var(--input-border);
    box-shadow: none; /* Remove some default styling for Firefox (required attribute) */
    font-size: 1.5rem;
    line-height: 1.8rem;
}

.gh-flow-invite textarea:valid {
    background: none;
}

@media (max-width: 460px) {
    .gh-flow-content .gh-flow-invite label svg {
        display: none;
    }
}

.gh-flow-content .gh-flow-faces {
    margin-bottom: 2vw;
    width: 100%;
}

.gh-flow-content textarea {
    width: 100%;
    height: 160px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
}

.gh-flow-content .response {
    position: absolute;
    right: 0;
    bottom: -25px;
    margin: 0;
    color: #a6b0b3;
    text-align: right;
    font-size: 1.2rem;
}

.gh-flow-content form:not(.gh-signin) .success .gh-input-icon svg {
    fill: var(--green);
}

.error,
.error-content,
.main-error {
    user-select: text;
    font-weight: 400;
}

.gh-flow-content .error input {
    border-color: var(--red);
    box-shadow: none;
}

.gh-flow-content .error .gh-input-icon svg {
    fill: var(--red);
}

.gh-flow-content .error .response {
    color: var(--red);
}

.gh-flow-content .main-error {
    margin-top: 5px;
    color: var(--red);
    font-size: 1.3rem;
}
