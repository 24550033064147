/* Exclude CodeMirror from the border-box reset to avoid scrollbar problems */
.CodeMirror,
.CodeMirror * {
    box-sizing: initial;
}

/* Re-apply CodeMirror's content-box styles overridden by above reset */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-linenumber {
    min-width: 14px;
}
