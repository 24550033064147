/* Text colors */

.blue           { color: var(--blue); }
.green          { color: var(--green); }
.purple          { color: var(--purple); }
.yellow         { color: var(--yellow); }
.red            { color: var(--red); }
.pink           { color: var(--pink); }
.white          { color: var(--white); }

.white-10          { color: var(--white-10); }
.white-20          { color: var(--white-20); }
.white-30          { color: var(--white-30); }
.white-40          { color: var(--white-40); }
.white-50          { color: var(--white-50); }
.white-60          { color: var(--white-60); }
.white-70          { color: var(--white-70); }
.white-80          { color: var(--white-80); }
.white-90          { color: var(--white-90); }

.black-10          { color: var(--black-10); }
.black-20          { color: var(--black-20); }
.black-30          { color: var(--black-30); }
.black-40          { color: var(--black-40); }
.black-50          { color: var(--black-50); }
.black-60          { color: var(--black-60); }
.black-70          { color: var(--black-70); }
.black-80          { color: var(--black-80); }
.black-90          { color: var(--black-90); }

.darkgrey       { color: var(--darkgrey); }
.middarkgrey    { color: var(--middarkgrey); }
.midgrey        { color: var(--midgrey); }
.midlightgrey   { color: var(--midlightgrey); }
.lightgrey      { color: var(--lightgrey); }
.whitegrey      { color: var(--whitegrey); }

/* Shades */
.blue-l3       { color: var(--blue-l3); }
.blue-l2       { color: var(--blue-l2); }
.blue-l1       { color: var(--blue-l1); }
.blue-d1       { color: var(--blue-d1); }
.blue-d2       { color: var(--blue-d2); }
.blue-d3       { color: var(--blue-d3); }

.green-l3       { color: var(--green-l3); }
.green-l2       { color: var(--green-l2); }
.green-l1       { color: var(--green-l1); }
.green-d1       { color: var(--green-d1); }
.green-d2       { color: var(--green-d2); }
.green-d3       { color: var(--green-d3); }

.purple-l3       { color: var(--purple-l3); }
.purple-l2       { color: var(--purple-l2); }
.purple-l1       { color: var(--purple-l1); }
.purple-d1       { color: var(--purple-d1); }
.purple-d2       { color: var(--purple-d2); }
.purple-d3       { color: var(--purple-d3); }

.yellow-l3       { color: var(--yellow-l3); }
.yellow-l2       { color: var(--yellow-l2); }
.yellow-l1       { color: var(--yellow-l1); }
.yellow-d1       { color: var(--yellow-d1); }
.yellow-d2       { color: var(--yellow-d2); }
.yellow-d3       { color: var(--yellow-d3); }

.red-l3       { color: var(--red-l3); }
.red-l2       { color: var(--red-l2); }
.red-l1       { color: var(--red-l1); }
.red-d1       { color: var(--red-d1); }
.red-d2       { color: var(--red-d2); }
.red-d3       { color: var(--red-d3); }

.pink-l3       { color: var(--pink-l3); }
.pink-l2       { color: var(--pink-l2); }
.pink-l1       { color: var(--pink-l1); }
.pink-d1       { color: var(--pink-d1); }
.pink-d2       { color: var(--pink-d2); }
.pink-d3       { color: var(--pink-d3); }

.darkgrey-l2       { color: var(--darkgrey-l2); }
.darkgrey-l1       { color: var(--darkgrey-l1); }
.darkgrey-d1       { color: var(--darkgrey-d1); }
.darkgrey-d2       { color: var(--darkgrey-d2); }

.middarkgrey-l2       { color: var(--middarkgrey-l2); }
.middarkgrey-l1       { color: var(--middarkgrey-l1); }
.middarkgrey-d1       { color: var(--middarkgrey-d1); }
.middarkgrey-d2       { color: var(--middarkgrey-d2); }

.midgrey-l2       { color: var(--midgrey-l2); }
.midgrey-l1       { color: var(--midgrey-l1); }
.midgrey-d1       { color: var(--midgrey-d1); }
.midgrey-d2       { color: var(--midgrey-d2); }

.midlightgrey-l2       { color: var(--midlightgrey-l2); }
.midlightgrey-l1       { color: var(--midlightgrey-l1); }
.midlightgrey-d1       { color: var(--midlightgrey-d1); }
.midlightgrey-d2       { color: var(--midlightgrey-d2); }

.lightgrey-l2       { color: var(--lightgrey-l2); }
.lightgrey-l1       { color: var(--lightgrey-l1); }
.lightgrey-d1       { color: var(--lightgrey-d1); }
.lightgrey-d2       { color: var(--lightgrey-d2); }

.whitegrey-l2       { color: var(--whitegrey-l2); }
.whitegrey-l1       { color: var(--whitegrey-l1); }
.whitegrey-d1       { color: var(--whitegrey-d1); }
.whitegrey-d2       { color: var(--whitegrey-d2); }

.color-inherit { color: inherit; }

/* Background colors */
.bg-blue           { background-color: var(--blue); }
.bg-green          { background-color: var(--green); }
.bg-purple          { background-color: var(--purple); }
.bg-yellow         { background-color: var(--yellow); }
.bg-red            { background-color: var(--red); }
.bg-pink           { background-color: var(--pink); }
.bg-white          { background-color: var(--white); }
.bg-transparent     { background-color: transparent !important;}

.bg-white-10          { background-color: var(--white-10); }
.bg-white-20          { background-color: var(--white-20); }
.bg-white-30          { background-color: var(--white-30); }
.bg-white-40          { background-color: var(--white-40); }
.bg-white-50          { background-color: var(--white-50); }
.bg-white-60          { background-color: var(--white-60); }
.bg-white-70          { background-color: var(--white-70); }
.bg-white-80          { background-color: var(--white-80); }
.bg-white-90          { background-color: var(--white-90); }

.bg-black-10          { background-color: var(--black-10); }
.bg-black-20          { background-color: var(--black-20); }
.bg-black-30          { background-color: var(--black-30); }
.bg-black-40          { background-color: var(--black-40); }
.bg-black-50          { background-color: var(--black-50); }
.bg-black-60          { background-color: var(--black-60); }
.bg-black-70          { background-color: var(--black-70); }
.bg-black-80          { background-color: var(--black-80); }
.bg-black-90          { background-color: var(--black-90); }

.bg-darkgrey       { background-color: var(--darkgrey); }
.bg-middarkgrey    { background-color: var(--middarkgrey); }
.bg-midgrey        { background-color: var(--midgrey); }
.bg-midlightgrey   { background-color: var(--midlightgrey); }
.bg-lightgrey      { background-color: var(--lightgrey); }
.bg-whitegrey      { background-color: var(--whitegrey); }

/* Shades */
.bg-blue-l3       { background-color: var(--blue-l3); }
.bg-blue-l2       { background-color: var(--blue-l2); }
.bg-blue-l1       { background-color: var(--blue-l1); }
.bg-blue-d1       { background-color: var(--blue-d1); }
.bg-blue-d2       { background-color: var(--blue-d2); }
.bg-blue-d3       { background-color: var(--blue-d3); }

.bg-green-l3       { background-color: var(--green-l3); }
.bg-green-l2       { background-color: var(--green-l2); }
.bg-green-l1       { background-color: var(--green-l1); }
.bg-green-d1       { background-color: var(--green-d1); }
.bg-green-d2       { background-color: var(--green-d2); }
.bg-green-d3       { background-color: var(--green-d3); }

.bg-purple-l3       { background-color: var(--purple-l3); }
.bg-purple-l2       { background-color: var(--purple-l2); }
.bg-purple-l1       { background-color: var(--purple-l1); }
.bg-purple-d1       { background-color: var(--purple-d1); }
.bg-purple-d2       { background-color: var(--purple-d2); }
.bg-purple-d3       { background-color: var(--purple-d3); }

.bg-yellow-l3       { background-color: var(--yellow-l3); }
.bg-yellow-l2       { background-color: var(--yellow-l2); }
.bg-yellow-l1       { background-color: var(--yellow-l1); }
.bg-yellow-d1       { background-color: var(--yellow-d1); }
.bg-yellow-d2       { background-color: var(--yellow-d2); }
.bg-yellow-d3       { background-color: var(--yellow-d3); }

.bg-red-l3       { background-color: var(--red-l3); }
.bg-red-l2       { background-color: var(--red-l2); }
.bg-red-l1       { background-color: var(--red-l1); }
.bg-red-d1       { background-color: var(--red-d1); }
.bg-red-d2       { background-color: var(--red-d2); }
.bg-red-d3       { background-color: var(--red-d3); }

.bg-pink-l3       { background-color: var(--pink-l3); }
.bg-pink-l2       { background-color: var(--pink-l2); }
.bg-pink-l1       { background-color: var(--pink-l1); }
.bg-pink-d1       { background-color: var(--pink-d1); }
.bg-pink-d2       { background-color: var(--pink-d2); }
.bg-pink-d3       { background-color: var(--pink-d3); }

.bg-darkgrey-l2       { background-color: var(--darkgrey-l2); }
.bg-darkgrey-l1       { background-color: var(--darkgrey-l1); }
.bg-darkgrey-d1       { background-color: var(--darkgrey-d1); }
.bg-darkgrey-d2       { background-color: var(--darkgrey-d2); }

.bg-middarkgrey-l2       { background-color: var(--middarkgrey-l2); }
.bg-middarkgrey-l1       { background-color: var(--middarkgrey-l1); }
.bg-middarkgrey-d1       { background-color: var(--middarkgrey-d1); }
.bg-middarkgrey-d2       { background-color: var(--middarkgrey-d2); }

.bg-midgrey-l2       { background-color: var(--midgrey-l2); }
.bg-midgrey-l1       { background-color: var(--midgrey-l1); }
.bg-midgrey-d1       { background-color: var(--midgrey-d1); }
.bg-midgrey-d2       { background-color: var(--midgrey-d2); }

.bg-midlightgrey-l2       { background-color: var(--midlightgrey-l2); }
.bg-midlightgrey-l1       { background-color: var(--midlightgrey-l1); }
.bg-midlightgrey-d1       { background-color: var(--midlightgrey-d1); }
.bg-midlightgrey-d2       { background-color: var(--midlightgrey-d2); }

.bg-lightgrey-l2       { background-color: var(--lightgrey-l2); }
.bg-lightgrey-l1       { background-color: var(--lightgrey-l1); }
.bg-lightgrey-d1       { background-color: var(--lightgrey-d1); }
.bg-lightgrey-d2       { background-color: var(--lightgrey-d2); }

.bg-whitegrey-l2       { background-color: var(--whitegrey-l2); }
.bg-whitegrey-l1       { background-color: var(--whitegrey-l1); }
.bg-whitegrey-d1       { background-color: var(--whitegrey-d1); }
.bg-whitegrey-d2       { background-color: var(--whitegrey-d2); }

/* Special colors */
.bg-error-red       { background-color: var(--errorbg-lightred); }
