/* Tables
/* ---------------------------------------------------------- */

/* Base */
table,
.table {
    margin: 1.6em 0;
    max-width: 100%;
    width: 100%;
    background-color: transparent;
}

table th,
table td,
.table th,
.table td {
    padding: 8px;
    vertical-align: middle;
    text-align: left;
    line-height: 20px;
    user-select: text;
}


/* Default Table
/* ---------------------------------------------------------- */

.table th,
.table td {
    border-top: 1px solid #dfe1e3;
}

.table th {
    color: var(--midgrey);
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
    border-top: 0;
}

.table tbody + tbody {
    border-top: 2px solid #dfe1e3;
}

.table table table {
    background-color: #fff;
}

.table tbody > tr:nth-child(odd) > td,
.table tbody > tr:nth-child(odd) > th {
    background-color: color-mod(#dfe1e3 lightness(+10%));
}

.table.plain tbody > tr:nth-child(odd) > td,
.table.plain tbody > tr:nth-child(odd) > th {
    background: transparent;
}

/* Ember Light Table
/* ---------------------------------------------------------- */

.ember-light-table th {
    white-space: nowrap;
}

.ember-light-table .lt-column .lt-sort-icon {
    display: inline-block;
    float: none;
    margin-left: 0.3rem;
}

.lt-sort-icon.gh-icon-ascending {
    background: url(icons/arrow-down-small.svg);
}

.lt-sort-icon.gh-icon-descending {
    background: url(icons/arrow-up-small.svg);
}

.lt-sort-icon.gh-icon-ascending,
.lt-sort-icon.gh-icon-descending {
    fill: var(--darkgrey);
    background-size: 10px, auto, contain;
    background-repeat: no-repeat;
    height: 9px;
    width: 15px;
    top: 1px;
    position: relative;
}

/* Plain list tables */
.table.list, 
table.list td {
    padding: 8px 0;
}

.table.list .data-label, 
table.list .data-label {
    color: var(--midgrey);
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    padding-right: 8px;
    vertical-align: top;
}

.table.list .data, 
table.list .data {
    color: var(--darkgrey);
    font-weight: 500;
    font-size: 1.4rem;
    padding: 8px;
    word-wrap: break-word;
}

.table.list .data.highlight-hover:hover,
table.list .data.highlight-hover:hover {
    background: var(--whitegrey-l1);
}