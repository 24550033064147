/*

  BOX-SHADOW

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

 */

.shadow-1           { box-shadow: var(--shadow-1); }
.shadow-2           { box-shadow: var(--shadow-2); }
.shadow-3           { box-shadow: var(--shadow-3); }
