/*

   FORMS

*/

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-reset::-moz-focus-inner,
.input-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.form-icon {
    position: absolute;
    top: calc(50% + 2px);
    left: 12px;
    z-index: 100;
    height: 14px;
    fill: #a6bac5;
}

.form-text {
    transition: border-color .15s linear;
    -webkit-appearance: none;
    outline: none;
}

.form-text::placeholder {
    color: var(--midlightgrey);
}

.form-input-w-icon {
    padding-left: 35px;
}

.form-input-w-button {
    padding-right: 77px;
}

.form-btn-forgot {
    right: 1px;
    padding: 5px 11px 5px 12px;
    border: none;
    border-left: 1px solid var(--lightgrey);
    background-color: transparent;
    top: 27px;
    line-height: 1.1em;
}

.form-response {
    top: 0;
    right: 0;
}

.form-checkbox:before {
    height: 6px;
    left: 6px;
    top: 7px;
    width: 15px;
    border: 2px solid #fff;
    border-right: none;
    border-top: none;
    content: "";
    position: absolute;
    transform: rotate(-45deg);
    transition: opacity .15s ease-in-out;
}


/* The slider */
.slider {
    transition: .4s;
}

.slider:before {
    z-index: 999;
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: .3s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--green);
    border-color: var(--green-d1);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
}

.onofflabel:before {
    position: absolute;
    content: "ON";
    top: 8px;
    left: 12px;
    color: var(--white-80);
    opacity: 0;
    transition: .3s;
}

.onofflabel:after {
    position: absolute;
    content: "OFF";
    top: 8px;
    left: 34px;
    color: var(--lightgrey-d2);
    opacity: 1;
    transition: .3s;
}

input:checked + .slider + .onofflabel:before {
    opacity: 1;
}

input:checked + .slider + .onofflabel:after {
    opacity: 0;
}
