.bg-lgr-blue {
    background: linear-gradient(color-mod(var(--blue-l3)), color-mod(var(--blue)));
}
.bg-rgr-blue {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--blue-l3)) 0%, color-mod(var(--blue))) 100%;
}

.bg-lgr-green {
    background: linear-gradient(color-mod(var(--green-l3)), color-mod(var(--green)));
}
.bg-rgr-green {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--green-l3)) 0%, color-mod(var(--green))) 100%;
}

.bg-lgr-purple {
    background: linear-gradient(color-mod(var(--purple-l3)), color-mod(var(--purple)));
}
.bg-rgr-purple {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--purple-l3)) 0%, color-mod(var(--purple))) 100%;
}

.bg-lgr-yellow {
    background: linear-gradient(color-mod(var(--yellow-l3)), color-mod(var(--yellow)));
}
.bg-rgr-yellow {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--yellow-l3)) 0%, color-mod(var(--yellow))) 100%;
}

.bg-lgr-red {
    background: linear-gradient(color-mod(var(--red-l3)), color-mod(var(--red)));
}
.bg-rgr-red {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--red-l3)) 0%, color-mod(var(--red))) 100%;
}

.bg-lgr-pink {
    background: linear-gradient(color-mod(var(--pink-l3)), color-mod(var(--pink)));
}
.bg-rgr-pink {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--pink-l3)) 0%, color-mod(var(--pink))) 100%;
}

.bg-lgr-whitegrey {
    background: linear-gradient(color-mod(var(--whitegrey-l2)), color-mod(var(--whitegrey)));
}
.bg-rgr-whitegrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--whitegrey-l2)) 0%, color-mod(var(--whitegrey))) 100%;
}

.bg-lgr-lightgrey {
    background: linear-gradient(color-mod(var(--lightgrey-l2)), color-mod(var(--lightgrey)));
}
.bg-rgr-lightgrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--lightgrey-l2)) 0%, color-mod(var(--lightgrey))) 100%;
}

.bg-lgr-midlightgrey {
    background: linear-gradient(color-mod(var(--midlightgrey-l2)), color-mod(var(--midlightgrey)));
}
.bg-rgr-midlightgrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--midlightgrey-l2)) 0%, color-mod(var(--midlightgrey))) 100%;
}

.bg-lgr-midgrey {
    background: linear-gradient(color-mod(var(--midgrey-l2)), color-mod(var(--midgrey-d1)));
}
.bg-rgr-midgrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--midgrey-l2)) 0%, color-mod(var(--midgrey-d1))) 100%;
}

.bg-lgr-middarkgrey {
    background: linear-gradient(color-mod(var(--middarkgrey-l2)), color-mod(var(--middarkgrey-d1)));
}
.bg-rgr-middarkgrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--middarkgrey-l2)) 0%, color-mod(var(--middarkgrey-d1))) 100%;
}

.bg-lgr-darkgrey {
    background: linear-gradient(color-mod(var(--darkgrey-l2)), color-mod(var(--darkgrey-d2)));
}
.bg-rgr-darkgrey {
    background: radial-gradient(circle farthest-corner at left bottom, color-mod(var(--darkgrey-l2)) 0%, color-mod(var(--darkgrey-d2))) 100%;
}

.bg-image-overlay-top {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: #fff;
    background-image: linear-gradient(180deg,rgba(0,0,0,0.2) 0,transparent 40%,transparent 100%);
    opacity: 0;
    transition: all 0.15s ease-in-out;
}
