/* Ghost Dropdown
/* -------------------------------------------------------- */
.trans-from-up      {  transform: scale(0.9) translateY(-10px); }

.dd-w1,
.dd-w2,
.dd-w3              {
    margin-right: 50%;
    pointer-events: none;
}

.dd-w1              {  width: 16rem;  right: -8rem;  }
.dd-w2              {  width: 20rem;  right: -10rem;  }
.dd-w3              {  width: 24rem;  right: -12rem;  }

.gd-drop.active .dd-w1,
.gd-drop.active .dd-w2,
.gd-drop.active .dd-w3,
.gd-drop.dropdown-expanded .dd-w1,
.gd-drop.dropdown-expanded .dd-w2,
.gd-drop.dropdown-expanded .dd-w3 {
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: scale(1) translateY(0px);
    pointer-events: auto;
}

/* Dropdown triagle */
.dd-w1:after,
.dd-w3:after,
.dd-w2:after        {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-width: 0 0.6rem 0.6rem 0.6rem;
    border-style: solid;
    border-color: #fff transparent;
    left: calc(50% - 6px);
}

.dd-w1:before,
.dd-w3:before,
.dd-w2:before       {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: rgba(0,0,0,0.07) transparent;
    left: calc(50% - 8px);
}

.dd-up {
    top: auto;
    bottom: 85%;
    right: calc(50% - 12rem);
    margin-right: 0;
}

.dd-up:after,
.dd-up:before {
    content: "";
    position: absolute;
    top: 100%;
    right: auto;
    bottom: auto;
    width: 0;
    height: 0;
    border-style: solid;
}

.dd-up:before {
    left: calc(50% - 8px);
    border-width: 8px 8px 0;
    border-color: rgba(0,0,0,.07) transparent;
}

.dd-up:after {
    left: calc(50% - 6px);
    border-width: 6px 6px 0;
    border-color: #fff transparent;
}


/* TO BE DONE: add all classes (per group) to media queries */
@media (--breakpoint-not-small) { }

@media (--breakpoint-medium) { }

@media (--breakpoint-large) { }
