/* Labels
/* ---------------------------------------------------------- */

.label {
    display: inline;
    padding: 2px 4px;
    border-radius: 0.25em;
    color: #fff;
    vertical-align: baseline;
    text-align: center;
    white-space: nowrap;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 400;
}

.label:empty {
    display: none;
}

.gh-btn .label {
    position: relative;
    top: -1px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    position: relative;
    top: -0.18em;
    display: inline-block;
    padding: 0.2em 0.5em 0.25em;
    font-size: 70%;
    line-height: 70%;
}

a.label:hover,
a.label:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}


/* Label Colours
/* ---------------------------------------------------------- */

.label-default {
    background: var(--lightgrey);
    color: var(--midgrey);
}

.label-default[href] {
    color: var(--midgrey);
}

.label-default[href]:hover,
.label-default[href]:focus {
    background: color-mod(var(--lightgrey) l(-5%));
}

.label-alt {
    background-color: #666;
    color: #fff;
}

.label-alt[href] {
    color: #fff;
}

.label-alt[href]:hover,
.label-alt[href]:focus {
    background-color: color-mod(#666 lightness(-10%));
}

.label-blue {
    background-color: var(--blue);
    color: #fff;
}

.label-blue[href] {
    color: #fff;
}

.label-blue[href]:hover,
.label-blue[href]:focus {
    background-color: color-mod(var(--blue) lightness(-10%));
}

.label-green {
    background-color: var(--green);
    color: #fff;
}

.label-green[href] {
    color: #fff;
}

.label-green[href]:hover,
.label-green[href]:focus {
    background-color: color-mod(var(--green) lightness(-10%));
}

.label-red {
    background-color: var(--red);
    color: #fff;
}

.label-red[href] {
    color: #fff;
}

.label-red[href]:hover,
.label-red[href]:focus {
    background-color: color-mod(var(--red) lightness(-10%));
}
