.gh-browserpreview-previewcontainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 3px !important;
    box-shadow:
        0 0 0 1px rgba(0,0,0,0.02),
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07);
    ;
}

.gh-browserpreview-iframecontainer {
    flex-grow: 1;
    position: relative;
}

.gh-browserpreview-iframecontainer .site-frame {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.gh-browserpreview-browser {
    background: var(--whitegrey-d1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.gh-browserpreview-browser .tabs {
    display: flex;
    align-items: center;
    padding: 16px 13px;
}

.gh-browserpreview-browser .tabs ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    line-height: 1;
}

.gh-browserpreview-browser .tabs ul li {
    display: block;
    padding: 0;
    margin: 0;
    margin-right: 8px;
    background: var(--midgrey);
    border-radius: 999px;
    width: 12px;
    height: 12px;
    opacity: 0.3;
}

.gh-browserpreview-browser .tabs div {
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: -7px 6px -15px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    background: var(--whitegrey-l1);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.gh-browserpreview-browser .favicon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.gh-browserpreview-browser .favicon img {
    width: 16px;
    height: 16px;
}

.gh-browserpreview-browser .favicon svg {
    width: 8px;
    height: 8px;
}

.gh-browserpreview-browser .favicon.default svg {
    width: 16px;
    height: 16px;
}