/*

    Base:
        mw = max width

    Value:
        (n) = (n * grid size)
        -(m) = (m)%
        -s = small
        -m = medium
        -l = large
        -xl = extra large
        -none = none

    Media Query Extensions:
        -ns = not-small
        -m  = medium
        -l  = large

*/

.mw1 {    max-width: calc(var(--grid-size) * 1); }
.mw2 {    max-width: calc(var(--grid-size) * 2); }
.mw3 {    max-width: calc(var(--grid-size) * 3); }
.mw4 {    max-width: calc(var(--grid-size) * 4); }
.mw5 {    max-width: calc(var(--grid-size) * 5); }
.mw6 {    max-width: calc(var(--grid-size) * 6); }
.mw7 {    max-width: calc(var(--grid-size) * 7); }
.mw8 {    max-width: calc(var(--grid-size) * 8); }
.mw9 {    max-width: calc(var(--grid-size) * 9); }
.mw10 {    max-width: calc(var(--grid-size) * 10); }
.mw11 {    max-width: calc(var(--grid-size) * 11); }
.mw12 {    max-width: calc(var(--grid-size) * 12); }
.mw13 {    max-width: calc(var(--grid-size) * 13); }
.mw14 {    max-width: calc(var(--grid-size) * 14); }
.mw15 {    max-width: calc(var(--grid-size) * 15); }
.mw16 {    max-width: calc(var(--grid-size) * 16); }
.mw17 {    max-width: calc(var(--grid-size) * 17); }
.mw18 {    max-width: calc(var(--grid-size) * 18); }
.mw19 {    max-width: calc(var(--grid-size) * 19); }
.mw20 {    max-width: calc(var(--grid-size) * 20); }
.mw25 {    max-width: calc(var(--grid-size) * 25); }
.mw30 {    max-width: calc(var(--grid-size) * 30); }
.mw40 {    max-width: calc(var(--grid-size) * 40); }
.mw50 {    max-width: calc(var(--grid-size) * 50); }
.mw70 {    max-width: calc(var(--grid-size) * 70); }
.mw88 {    max-width: calc(var(--grid-size) * 88); }

.mw-70   { max-width: 70%; }
.mw-80   { max-width: 80%; }
.mw-90   { max-width: 90%; }
.mw-100  { max-width: 100%; }

.mw-s  {  max-width: 48rem; }
.mw-m  {  max-width: 68rem; }
.mw-l {  max-width: 98rem; }
.mw-xl {  max-width: 114rem; }
.mw-none { max-width: none; }

.mw-65vw { max-width: 65vw; }
.mw-100vw { max-width: 100vw; }
.mw-100vw--sidebar { max-width: calc(100vw - 280px); }


@media (--breakpoint-not-small) {
    .mw1-ns {    max-width: calc(var(--grid-size) * 1); }
    .mw2-ns {    max-width: calc(var(--grid-size) * 2); }
    .mw3-ns {    max-width: calc(var(--grid-size) * 3); }
    .mw4-ns {    max-width: calc(var(--grid-size) * 4); }
    .mw5-ns {    max-width: calc(var(--grid-size) * 5); }
    .mw6-ns {    max-width: calc(var(--grid-size) * 6); }
    .mw7-ns {    max-width: calc(var(--grid-size) * 7); }
    .mw8-ns {    max-width: calc(var(--grid-size) * 8); }
    .mw9-ns {    max-width: calc(var(--grid-size) * 9); }
    .mw10-ns {    max-width: calc(var(--grid-size) * 10); }
    .mw11-ns {    max-width: calc(var(--grid-size) * 11); }
    .mw12-ns {    max-width: calc(var(--grid-size) * 12); }
    .mw13-ns {    max-width: calc(var(--grid-size) * 13); }
    .mw14-ns {    max-width: calc(var(--grid-size) * 14); }
    .mw15-ns {    max-width: calc(var(--grid-size) * 15); }
    .mw16-ns {    max-width: calc(var(--grid-size) * 16); }
    .mw17-ns {    max-width: calc(var(--grid-size) * 17); }
    .mw18-ns {    max-width: calc(var(--grid-size) * 18); }
    .mw19-ns {    max-width: calc(var(--grid-size) * 19); }
    .mw20-ns {    max-width: calc(var(--grid-size) * 20); }
    .mw25-ns {    max-width: calc(var(--grid-size) * 25); }
    .mw30-ns {    max-width: calc(var(--grid-size) * 30); }
    .mw40-ns {    max-width: calc(var(--grid-size) * 40); }
    .mw50-ns {    max-width: calc(var(--grid-size) * 50); }
    .mw70-ns {    max-width: calc(var(--grid-size) * 70); }
    .mw88-ns {    max-width: calc(var(--grid-size) * 88); }

    .mw-70-ns { max-width: 70%; }
    .mw-80-ns { max-width: 80%; }
    .mw-90-ns { max-width: 90%; }
    .mw-100-ns { max-width: 100%; }

    .mw-s-ns {  max-width: 48rem; }
    .mw-m-ns {  max-width: 68rem; }
    .mw-l-ns {  max-width: 98rem; }
    .mw-xl-ns {  max-width: 114rem; }
    .mw-none-ns { max-width: none; }

    .mw-65vw-ns { max-width: 65vw; }
    .mw-100vw-ns { max-width: 100vw; }
    .mw-100vw--sidebar-ns { max-width: calc(100vw - 280px); }
}

@media (--breakpoint-medium) {
    .mw1-m {    max-width: calc(var(--grid-size) * 1); }
    .mw2-m {    max-width: calc(var(--grid-size) * 2); }
    .mw3-m {    max-width: calc(var(--grid-size) * 3); }
    .mw4-m {    max-width: calc(var(--grid-size) * 4); }
    .mw5-m {    max-width: calc(var(--grid-size) * 5); }
    .mw6-m {    max-width: calc(var(--grid-size) * 6); }
    .mw7-m {    max-width: calc(var(--grid-size) * 7); }
    .mw8-m {    max-width: calc(var(--grid-size) * 8); }
    .mw9-m {    max-width: calc(var(--grid-size) * 9); }
    .mw10-m {    max-width: calc(var(--grid-size) * 10); }
    .mw11-m {    max-width: calc(var(--grid-size) * 11); }
    .mw12-m {    max-width: calc(var(--grid-size) * 12); }
    .mw13-m {    max-width: calc(var(--grid-size) * 13); }
    .mw14-m {    max-width: calc(var(--grid-size) * 14); }
    .mw15-m {    max-width: calc(var(--grid-size) * 15); }
    .mw16-m {    max-width: calc(var(--grid-size) * 16); }
    .mw17-m {    max-width: calc(var(--grid-size) * 17); }
    .mw18-m {    max-width: calc(var(--grid-size) * 18); }
    .mw19-m {    max-width: calc(var(--grid-size) * 19); }
    .mw20-m {    max-width: calc(var(--grid-size) * 20); }
    .mw25-m {    max-width: calc(var(--grid-size) * 25); }
    .mw30-m {    max-width: calc(var(--grid-size) * 30); }
    .mw40-m {    max-width: calc(var(--grid-size) * 40); }
    .mw50-m {    max-width: calc(var(--grid-size) * 50); }
    .mw70-m {    max-width: calc(var(--grid-size) * 70); }
    .mw88-m {    max-width: calc(var(--grid-size) * 88); }

    .mw-70-m { max-width: 70%; }
    .mw-80-m { max-width: 80%; }
    .mw-90-m { max-width: 90%; }
    .mw-100-m { max-width: 100%; }

    .mw-s-m {  max-width: 48rem; }
    .mw-m-m {  max-width: 68rem; }
    .mw-l-m {  max-width: 98rem; }
    .mw-xl-m {  max-width: 114rem; }
    .mw-none-m { max-width: none; }

    .mw-65vw-m { max-width: 65vw; }
    .mw-100vw-m { max-width: 100vw; }
    .mw-100vw--sidebar-m { max-width: calc(100vw - 280px); }
}

@media (--breakpoint-large) {
    .mw1-l {    max-width: calc(var(--grid-size) * 1); }
    .mw2-l {    max-width: calc(var(--grid-size) * 2); }
    .mw3-l {    max-width: calc(var(--grid-size) * 3); }
    .mw4-l {    max-width: calc(var(--grid-size) * 4); }
    .mw5-l {    max-width: calc(var(--grid-size) * 5); }
    .mw6-l {    max-width: calc(var(--grid-size) * 6); }
    .mw7-l {    max-width: calc(var(--grid-size) * 7); }
    .mw8-l {    max-width: calc(var(--grid-size) * 8); }
    .mw9-l {    max-width: calc(var(--grid-size) * 9); }
    .mw10-l {    max-width: calc(var(--grid-size) * 10); }
    .mw11-l {    max-width: calc(var(--grid-size) * 11); }
    .mw12-l {    max-width: calc(var(--grid-size) * 12); }
    .mw13-l {    max-width: calc(var(--grid-size) * 13); }
    .mw14-l {    max-width: calc(var(--grid-size) * 14); }
    .mw15-l {    max-width: calc(var(--grid-size) * 15); }
    .mw16-l {    max-width: calc(var(--grid-size) * 16); }
    .mw17-l {    max-width: calc(var(--grid-size) * 17); }
    .mw18-l {    max-width: calc(var(--grid-size) * 18); }
    .mw19-l {    max-width: calc(var(--grid-size) * 19); }
    .mw20-l {    max-width: calc(var(--grid-size) * 20); }
    .mw25-l {    max-width: calc(var(--grid-size) * 25); }
    .mw30-l {    max-width: calc(var(--grid-size) * 30); }
    .mw40-l {    max-width: calc(var(--grid-size) * 40); }
    .mw50-l {    max-width: calc(var(--grid-size) * 50); }
    .mw70-l {    max-width: calc(var(--grid-size) * 70); }
    .mw88-l {    max-width: calc(var(--grid-size) * 88); }

    .mw-70-l { max-width: 70%; }
    .mw-80-l { max-width: 80%; }
    .mw-90-l { max-width: 90%; }
    .mw-100-l { max-width: 100%; }

    .mw-s-l {  max-width: 48rem; }
    .mw-l-l {  max-width: 68rem; }
    .mw-l-l {  max-width: 98rem; }
    .mw-xl-l {  max-width: 114rem; }
    .mw-none-l { max-width: none; }

    .mw-65vw-l { max-width: 65vw; }
    .mw-100vw-l { max-width: 100vw; }
    .mw-100vw--sidebar-l { max-width: calc(100vw - 280px); }
}
