/*

    Base:
        h = height

    Value:
        (n) = (n * grid size)
        -(m) = (m)%
        -auto = auto
        -inherit = inherit

    ---------------------------------------------

    Base:
        vh = viewport based height

    Value:
        -(m) = (m)vh

    Media Query Extensions:
        -ns = not-small
        -m  = medium
        -l  = large

*/

.h1 {    height: calc(var(--grid-size) * 1); }
.h2 {    height: calc(var(--grid-size) * 2); }
.h3 {    height: calc(var(--grid-size) * 3); }
.h4 {    height: calc(var(--grid-size) * 4); }
.h5 {    height: calc(var(--grid-size) * 5); }
.h6 {    height: calc(var(--grid-size) * 6); }
.h7 {    height: calc(var(--grid-size) * 7); }
.h8 {    height: calc(var(--grid-size) * 8); }
.h9 {    height: calc(var(--grid-size) * 9); }
.h10 {    height: calc(var(--grid-size) * 10); }
.h11 {    height: calc(var(--grid-size) * 11); }
.h12 {    height: calc(var(--grid-size) * 12); }
.h13 {    height: calc(var(--grid-size) * 13); }
.h14 {    height: calc(var(--grid-size) * 14); }
.h15 {    height: calc(var(--grid-size) * 15); }
.h16 {    height: calc(var(--grid-size) * 16); }
.h17 {    height: calc(var(--grid-size) * 17); }
.h18 {    height: calc(var(--grid-size) * 18); }
.h19 {    height: calc(var(--grid-size) * 19); }
.h20 {    height: calc(var(--grid-size) * 20); }
.h25 {    height: calc(var(--grid-size) * 25); }
.h30 {    height: calc(var(--grid-size) * 30); }
.h40 {    height: calc(var(--grid-size) * 40); }
.h50 {    height: calc(var(--grid-size) * 50); }
.h70 {    height: calc(var(--grid-size) * 70); }

.h-25 {  height:  25%; }
.h-50 {  height:  50%; }
.h-75 {  height:  75%; }
.h-100 { height: 100%; }

.vh-25 {  height:  25vh; }
.vh-50 {  height:  50vh; }
.vh-75 {  height:  75vh; }
.vh-100 { height: 100vh; }

.h-auto {     height: auto; }
.h-inherit {  height: inherit; }

@media (--breakpoint-not-small) {
    .h1-ns {    height: calc(var(--grid-size) * 1); }
    .h2-ns {    height: calc(var(--grid-size) * 2); }
    .h3-ns {    height: calc(var(--grid-size) * 3); }
    .h4-ns {    height: calc(var(--grid-size) * 4); }
    .h5-ns {    height: calc(var(--grid-size) * 5); }
    .h6-ns {    height: calc(var(--grid-size) * 6); }
    .h7-ns {    height: calc(var(--grid-size) * 7); }
    .h8-ns {    height: calc(var(--grid-size) * 8); }
    .h9-ns {    height: calc(var(--grid-size) * 9); }
    .h10-ns {    height: calc(var(--grid-size) * 10); }
    .h11-ns {    height: calc(var(--grid-size) * 11); }
    .h12-ns {    height: calc(var(--grid-size) * 12); }
    .h13-ns {    height: calc(var(--grid-size) * 13); }
    .h14-ns {    height: calc(var(--grid-size) * 14); }
    .h15-ns {    height: calc(var(--grid-size) * 15); }
    .h16-ns {    height: calc(var(--grid-size) * 16); }
    .h17-ns {    height: calc(var(--grid-size) * 17); }
    .h18-ns {    height: calc(var(--grid-size) * 18); }
    .h19-ns {    height: calc(var(--grid-size) * 19); }
    .h20-ns {    height: calc(var(--grid-size) * 20); }
    .h25-ns {    height: calc(var(--grid-size) * 25); }
    .h30-ns {    height: calc(var(--grid-size) * 30); }
    .h40-ns {    height: calc(var(--grid-size) * 40); }
    .h50-ns {    height: calc(var(--grid-size) * 50); }
    .h70-ns {    height: calc(var(--grid-size) * 70); }

    .h-25-ns {  height:  25%; }
    .h-50-ns {  height:  50%; }
    .h-75-ns {  height:  75%; }
    .h-100-ns { height: 100%; }

    .vh-25-ns {  height:  25vh; }
    .vh-50-ns {  height:  50vh; }
    .vh-75-ns {  height:  75vh; }
    .vh-100-ns { height: 100vh; }

    .h-auto-ns {     height: auto; }
    .h-inherit-ns {  height: inherit; }
}

@media (--breakpoint-medium) {
    .h1-m {    height: calc(var(--grid-size) * 1); }
    .h2-m {    height: calc(var(--grid-size) * 2); }
    .h3-m {    height: calc(var(--grid-size) * 3); }
    .h4-m {    height: calc(var(--grid-size) * 4); }
    .h5-m {    height: calc(var(--grid-size) * 5); }
    .h6-m {    height: calc(var(--grid-size) * 6); }
    .h7-m {    height: calc(var(--grid-size) * 7); }
    .h8-m {    height: calc(var(--grid-size) * 8); }
    .h9-m {    height: calc(var(--grid-size) * 9); }
    .h10-m {    height: calc(var(--grid-size) * 10); }
    .h11-m {    height: calc(var(--grid-size) * 11); }
    .h12-m {    height: calc(var(--grid-size) * 12); }
    .h13-m {    height: calc(var(--grid-size) * 13); }
    .h14-m {    height: calc(var(--grid-size) * 14); }
    .h15-m {    height: calc(var(--grid-size) * 15); }
    .h16-m {    height: calc(var(--grid-size) * 16); }
    .h17-m {    height: calc(var(--grid-size) * 17); }
    .h18-m {    height: calc(var(--grid-size) * 18); }
    .h19-m {    height: calc(var(--grid-size) * 19); }
    .h20-m {    height: calc(var(--grid-size) * 20); }
    .h25-m {    height: calc(var(--grid-size) * 25); }
    .h30-m {    height: calc(var(--grid-size) * 30); }
    .h40-m {    height: calc(var(--grid-size) * 40); }
    .h50-m {    height: calc(var(--grid-size) * 50); }
    .h70-m {    height: calc(var(--grid-size) * 70); }

    .h-25-m {  height:  25%; }
    .h-50-m {  height:  50%; }
    .h-75-m {  height:  75%; }
    .h-100-m { height: 100%; }

    .vh-25-m {  height:  25vh; }
    .vh-50-m {  height:  50vh; }
    .vh-75-m {  height:  75vh; }
    .vh-100-m { height: 100vh; }

    .h-auto-m {     height: auto; }
    .h-inherit-m {  height: inherit; }
}

@media (--breakpoint-large) {
    .h1-l {    height: calc(var(--grid-size) * 1); }
    .h2-l {    height: calc(var(--grid-size) * 2); }
    .h3-l {    height: calc(var(--grid-size) * 3); }
    .h4-l {    height: calc(var(--grid-size) * 4); }
    .h5-l {    height: calc(var(--grid-size) * 5); }
    .h6-l {    height: calc(var(--grid-size) * 6); }
    .h7-l {    height: calc(var(--grid-size) * 7); }
    .h8-l {    height: calc(var(--grid-size) * 8); }
    .h9-l {    height: calc(var(--grid-size) * 9); }
    .h10-l {    height: calc(var(--grid-size) * 10); }
    .h11-l {    height: calc(var(--grid-size) * 11); }
    .h12-l {    height: calc(var(--grid-size) * 12); }
    .h13-l {    height: calc(var(--grid-size) * 13); }
    .h14-l {    height: calc(var(--grid-size) * 14); }
    .h15-l {    height: calc(var(--grid-size) * 15); }
    .h16-l {    height: calc(var(--grid-size) * 16); }
    .h17-l {    height: calc(var(--grid-size) * 17); }
    .h18-l {    height: calc(var(--grid-size) * 18); }
    .h19-l {    height: calc(var(--grid-size) * 19); }
    .h20-l {    height: calc(var(--grid-size) * 20); }
    .h25-l {    height: calc(var(--grid-size) * 25); }
    .h30-l {    height: calc(var(--grid-size) * 30); }
    .h40-l {    height: calc(var(--grid-size) * 40); }
    .h50-l {    height: calc(var(--grid-size) * 50); }
    .h70-l {    height: calc(var(--grid-size) * 70); }

    .h-25-l {  height:  25%; }
    .h-50-l {  height:  50%; }
    .h-75-l {  height:  75%; }
    .h-100-l { height: 100%; }

    .vh-25-l {  height:  25vh; }
    .vh-50-l {  height:  50vh; }
    .vh-75-l {  height:  75vh; }
    .vh-100-l { height: 100vh; }

    .h-auto-l {     height: auto; }
    .h-inherit-l {  height: inherit; }
}
