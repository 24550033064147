/* Publish Menu
/* ---------------------------------------------------------- */

.gh-publishmenu-trigger {
    cursor: pointer;
}

.gh-publishmenu-trigger svg {
    height: 8px;
    width: 8px;
}

.gh-publishmenu-trigger svg path {
    stroke: var(--darkgrey);
    stroke-width: 2px;
}

.gh-publishmenu-trigger:focus {
    outline: 0;
}

.gh-publishmenu {
    position: relative;
    z-index: 1000;
    margin-right: 8px;
}

.gh-publishmenu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    margin: 5px 0 20px 0;
    padding: 0px;
    width: 332px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    list-style: none;
    text-align: left;
    text-transform: none;
    font-size: 1.4rem;
    font-weight: normal;
    will-change: transform, opacity;
    z-index: 99999; /* needs to sit on top of preview modal */
    /* box-shadow: 0 0 0 1px rgba(99,114,130,0.06), 0 8px 16px rgba(27,39,51,0.08); */
    box-shadow: var(--box-shadow-m);
}

.gh-publishmenu-dropdown.ember-basic-dropdown--transitioning-in {
    animation: fade-in-scale 0.2s;
    animation-fill-mode: forwards;
}

.gh-publishmenu-dropdown.ember-basic-dropdown--transitioning-out {
    animation: fade-out 0.5s;
    animation-fill-mode: forwards;
}

.gh-publishmenu-heading {
    margin: 0 0 15px 0;
    padding: 20px 20px 0;
    font-size: 1.8rem;
    line-height: 1.25em;
}

.gh-publishmenu-content {
    border-bottom: var(--whitegrey) 1px solid;
}

.gh-publishmenu-footer {
    margin: 15px 0 0 0;
    padding: 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.gh-publishmenu-button {
    float: right;
    margin-left: 8px;
}

.gh-publishmenu-radio {
    display: flex;
    margin: 20px 0;
}

.gh-publishmenu-section {
    padding: 0 20px;
    border-top: var(--whitegrey) 1px solid;
}

.gh-publishmenu-radio-button {
    flex-shrink: 0;
    position: relative;
    width: 15px;
    height: 15px;
    border: color-mod(var(--whitegrey) l(-10%)) 1px solid;
    border-radius: 100%;
    background: #fff;
}

.gh-publishmenu-radio-content {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 15px;
    width: 100%;
}

.gh-publishmenu-radio-label {
    display: block;
    font-size: 1.4rem;
    line-height: 1.2em;
    font-weight: 500;
}

.gh-publishmenu-radio-desc {
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 300;
    color: var(--midgrey-l1);
    margin-top: 2px;
}

.gh-publishmenu-radio-label:hover,
.gh-publishmenu-radio-button:hover {
    cursor: pointer;
}

.gh-publishmenu-radio.active .gh-publishmenu-radio-button {
    border-color: var(--black);
    background: var(--black);
}

.gh-publishmenu-radio.active .gh-publishmenu-radio-button:before {
    display: block;
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    background: var(--white);
    border-radius: 100%;
    box-shadow: rgba(0,0,0,0.25) 0 1px 3px;
}

.gh-date-time-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gh-date-time-picker .ember-basic-dropdown{
    width: 100%;
}

.gh-date-time-picker-date,
.gh-date-time-picker-time {
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    margin: 7px 0 4px;
    padding: 6px 8px;
    border: var(--input-border);
    border-radius: 3px;
    transition: border-color 0.15s linear;
    background: var(--input-bg-color);
}

.gh-date-time-picker-time {
    margin-left: 10px;
    width: calc(100% - 4px);
}

.gh-date-time-picker-date.error,
.gh-date-time-picker-time.error {
    border-color: var(--red);
}

.gh-date-time-picker input {
    display: block;
    padding: 0;
    width: 100%;
    border: none;
    color: color-mod(var(--midgrey) l(-18%));
    font-size: 1.3rem;
    line-height: 1em;
    font-weight: 400;
    user-select: text;
    background: transparent;

    -webkit-appearance: none;
}

.gh-date-time-picker input:focus {
    outline: 0;
}

.gh-date-time-picker-date:focus,
.gh-date-time-picker-time:focus {
    border-color: color-mod(var(--whitegrey) l(-15%) s(-10%));
}

.gh-date-time-picker-date svg {
    width: 14px;
    height: 14px;
    fill: color-mod(var(--midgrey) l(+15%));
}

.gh-date-time-picker-timezone {
    font-size: 1.1rem;
    font-weight: 300;
    color: color-mod(var(--midgrey) l(+17%));
    margin-left: -30px;
}

.gh-date-time-picker-error {
    display: block;
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 300;
    color: var(--red);
}

.gh-publishmenu-email {
    margin: 15px 0;
    justify-content: space-between;
    align-items: center;
}

.gh-publishmenu-email .gh-box {
    padding: 12px 16px;
    font-size: 1.3rem;
    line-height: 1.5em;
}

.gh-publishmenu-email .select-members {
    margin-bottom: .2rem;
}

.gh-publishmenu-email .segment-totals {
    color: var(--midgrey-l1);
    font-size: 1.3rem;
    font-weight: 300;
}

.for-checkbox .gh-publishmenu-email-checkbox {
    margin-right: 0;
    margin-top: -2px;
    background: var(--white);
}

.gh-publishmenu-email-label.disabled {
    pointer-events: none;
}

.gh-publishmenu-content .for-switch.pe-none {
    opacity: 0.6;
}

.gh-publishmenu-email-info {
    margin: 15px 0;
    color: var(--midgrey);
}

.gh-publishmenu-send-to-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.gh-publishmenu-send-to-option p {
    color: var(--darkgrey);
    margin: 0;
}

.gh-publishmenu-send-to-option .for-switch {
    line-height: 1;
}

.gh-publishmenu-send-to-option .for-switch label {
    margin: 0;
    width: 38px !important;
    height: 22px !important;
}

.gh-publishmenu-emailcount {
    color: var(--midlightgrey);
}

.gh-publishmenu-checkbox-disabled {
    color: var(--midlightgrey);
    opacity: 0.6;
    pointer-events: none;
}

.gh-publishmenu-checkbox-disabled p {
    color: var(--midgrey) !important;
}
