/*

    Base:
        top
        right
        bottom
        left

    Value:
        -(n) = (n * grid)

    Media Query Extensions:
        -ns = not-small
        -m  = medium
        -l  = large

*/

.top-0 {    top: 0; }
.top-1 {    top: calc(var(--grid-size) * 1); }
.top-2 {    top: calc(var(--grid-size) * 2); }
.top-3 {    top: calc(var(--grid-size) * 3); }
.top-4 {    top: calc(var(--grid-size) * 4); }
.top-5 {    top: calc(var(--grid-size) * 5); }
.top-6 {    top: calc(var(--grid-size) * 6); }
.top-7 {    top: calc(var(--grid-size) * 7); }
.top-8 {    top: calc(var(--grid-size) * 8); }
.top-9 {    top: calc(var(--grid-size) * 9); }
.top-10 {    top: calc(var(--grid-size) * 10); }
.top-11 {    top: calc(var(--grid-size) * 11); }
.top-12 {    top: calc(var(--grid-size) * 12); }
.top-13 {    top: calc(var(--grid-size) * 13); }
.top-14 {    top: calc(var(--grid-size) * 14); }
.top-15 {    top: calc(var(--grid-size) * 15); }
.top-16 {    top: calc(var(--grid-size) * 16); }
.top-17 {    top: calc(var(--grid-size) * 17); }
.top-18 {    top: calc(var(--grid-size) * 18); }
.top-19 {    top: calc(var(--grid-size) * 19); }
.top-20 {    top: calc(var(--grid-size) * 20); }
.top-25 {    top: calc(var(--grid-size) * 25); }
.top-30 {    top: calc(var(--grid-size) * 30); }
.top-40 {    top: calc(var(--grid-size) * 40); }
.top-50 {    top: calc(var(--grid-size) * 50); }

.right-0 {    right: 0; }
.right-1 {    right: calc(var(--grid-size) * 1); }
.right-2 {    right: calc(var(--grid-size) * 2); }
.right-3 {    right: calc(var(--grid-size) * 3); }
.right-4 {    right: calc(var(--grid-size) * 4); }
.right-5 {    right: calc(var(--grid-size) * 5); }
.right-6 {    right: calc(var(--grid-size) * 6); }
.right-7 {    right: calc(var(--grid-size) * 7); }
.right-8 {    right: calc(var(--grid-size) * 8); }
.right-9 {    right: calc(var(--grid-size) * 9); }
.right-10 {    right: calc(var(--grid-size) * 10); }
.right-11 {    right: calc(var(--grid-size) * 11); }
.right-12 {    right: calc(var(--grid-size) * 12); }
.right-13 {    right: calc(var(--grid-size) * 13); }
.right-14 {    right: calc(var(--grid-size) * 14); }
.right-15 {    right: calc(var(--grid-size) * 15); }
.right-16 {    right: calc(var(--grid-size) * 16); }
.right-17 {    right: calc(var(--grid-size) * 17); }
.right-18 {    right: calc(var(--grid-size) * 18); }
.right-19 {    right: calc(var(--grid-size) * 19); }
.right-20 {    right: calc(var(--grid-size) * 20); }
.right-25 {    right: calc(var(--grid-size) * 25); }
.right-30 {    right: calc(var(--grid-size) * 30); }
.right-40 {    right: calc(var(--grid-size) * 40); }
.right-50 {    right: calc(var(--grid-size) * 50); }

.bottom-0 {    bottom: 0; }
.bottom-1 {    bottom: calc(var(--grid-size) * 1); }
.bottom-2 {    bottom: calc(var(--grid-size) * 2); }
.bottom-3 {    bottom: calc(var(--grid-size) * 3); }
.bottom-4 {    bottom: calc(var(--grid-size) * 4); }
.bottom-5 {    bottom: calc(var(--grid-size) * 5); }
.bottom-6 {    bottom: calc(var(--grid-size) * 6); }
.bottom-7 {    bottom: calc(var(--grid-size) * 7); }
.bottom-8 {    bottom: calc(var(--grid-size) * 8); }
.bottom-9 {    bottom: calc(var(--grid-size) * 9); }
.bottom-10 {    bottom: calc(var(--grid-size) * 10); }
.bottom-11 {    bottom: calc(var(--grid-size) * 11); }
.bottom-12 {    bottom: calc(var(--grid-size) * 12); }
.bottom-13 {    bottom: calc(var(--grid-size) * 13); }
.bottom-14 {    bottom: calc(var(--grid-size) * 14); }
.bottom-15 {    bottom: calc(var(--grid-size) * 15); }
.bottom-16 {    bottom: calc(var(--grid-size) * 16); }
.bottom-17 {    bottom: calc(var(--grid-size) * 17); }
.bottom-18 {    bottom: calc(var(--grid-size) * 18); }
.bottom-19 {    bottom: calc(var(--grid-size) * 19); }
.bottom-20 {    bottom: calc(var(--grid-size) * 20); }
.bottom-25 {    bottom: calc(var(--grid-size) * 25); }
.bottom-30 {    bottom: calc(var(--grid-size) * 30); }
.bottom-40 {    bottom: calc(var(--grid-size) * 40); }
.bottom-50 {    bottom: calc(var(--grid-size) * 50); }

.left-0 {    left: 0; }
.left-1 {    left: calc(var(--grid-size) * 1); }
.left-2 {    left: calc(var(--grid-size) * 2); }
.left-3 {    left: calc(var(--grid-size) * 3); }
.left-4 {    left: calc(var(--grid-size) * 4); }
.left-5 {    left: calc(var(--grid-size) * 5); }
.left-6 {    left: calc(var(--grid-size) * 6); }
.left-7 {    left: calc(var(--grid-size) * 7); }
.left-8 {    left: calc(var(--grid-size) * 8); }
.left-9 {    left: calc(var(--grid-size) * 9); }
.left-10 {    left: calc(var(--grid-size) * 10); }
.left-11 {    left: calc(var(--grid-size) * 11); }
.left-12 {    left: calc(var(--grid-size) * 12); }
.left-13 {    left: calc(var(--grid-size) * 13); }
.left-14 {    left: calc(var(--grid-size) * 14); }
.left-15 {    left: calc(var(--grid-size) * 15); }
.left-16 {    left: calc(var(--grid-size) * 16); }
.left-17 {    left: calc(var(--grid-size) * 17); }
.left-18 {    left: calc(var(--grid-size) * 18); }
.left-19 {    left: calc(var(--grid-size) * 19); }
.left-20 {    left: calc(var(--grid-size) * 20); }
.left-25 {    left: calc(var(--grid-size) * 25); }
.left-30 {    left: calc(var(--grid-size) * 30); }
.left-40 {    left: calc(var(--grid-size) * 40); }
.left-50 {    left: calc(var(--grid-size) * 50); }

.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


@media (--breakpoint-not-small) {
    .top-0-ns {    top: 0; }
    .top-1-ns {    top: calc(var(--grid-size) * 1); }
    .top-2-ns {    top: calc(var(--grid-size) * 2); }
    .top-3-ns {    top: calc(var(--grid-size) * 3); }
    .top-4-ns {    top: calc(var(--grid-size) * 4); }
    .top-5-ns {    top: calc(var(--grid-size) * 5); }
    .top-6-ns {    top: calc(var(--grid-size) * 6); }
    .top-7-ns {    top: calc(var(--grid-size) * 7); }
    .top-8-ns {    top: calc(var(--grid-size) * 8); }
    .top-9-ns {    top: calc(var(--grid-size) * 9); }
    .top-10-ns {    top: calc(var(--grid-size) * 10); }
    .top-11-ns {    top: calc(var(--grid-size) * 11); }
    .top-12-ns {    top: calc(var(--grid-size) * 12); }
    .top-13-ns {    top: calc(var(--grid-size) * 13); }
    .top-14-ns {    top: calc(var(--grid-size) * 14); }
    .top-15-ns {    top: calc(var(--grid-size) * 15); }
    .top-16-ns {    top: calc(var(--grid-size) * 16); }
    .top-17-ns {    top: calc(var(--grid-size) * 17); }
    .top-18-ns {    top: calc(var(--grid-size) * 18); }
    .top-19-ns {    top: calc(var(--grid-size) * 19); }
    .top-20-ns {    top: calc(var(--grid-size) * 20); }
    .top-25-ns {    top: calc(var(--grid-size) * 25); }
    .top-30-ns {    top: calc(var(--grid-size) * 30); }
    .top-40-ns {    top: calc(var(--grid-size) * 40); }
    .top-50-ns {    top: calc(var(--grid-size) * 50); }

    .right-0-ns {    right: 0; }
    .right-1-ns {    right: calc(var(--grid-size) * 1); }
    .right-2-ns {    right: calc(var(--grid-size) * 2); }
    .right-3-ns {    right: calc(var(--grid-size) * 3); }
    .right-4-ns {    right: calc(var(--grid-size) * 4); }
    .right-5-ns {    right: calc(var(--grid-size) * 5); }
    .right-6-ns {    right: calc(var(--grid-size) * 6); }
    .right-7-ns {    right: calc(var(--grid-size) * 7); }
    .right-8-ns {    right: calc(var(--grid-size) * 8); }
    .right-9-ns {    right: calc(var(--grid-size) * 9); }
    .right-10-ns {    right: calc(var(--grid-size) * 10); }
    .right-11-ns {    right: calc(var(--grid-size) * 11); }
    .right-12-ns {    right: calc(var(--grid-size) * 12); }
    .right-13-ns {    right: calc(var(--grid-size) * 13); }
    .right-14-ns {    right: calc(var(--grid-size) * 14); }
    .right-15-ns {    right: calc(var(--grid-size) * 15); }
    .right-16-ns {    right: calc(var(--grid-size) * 16); }
    .right-17-ns {    right: calc(var(--grid-size) * 17); }
    .right-18-ns {    right: calc(var(--grid-size) * 18); }
    .right-19-ns {    right: calc(var(--grid-size) * 19); }
    .right-20-ns {    right: calc(var(--grid-size) * 20); }
    .right-25-ns {    right: calc(var(--grid-size) * 25); }
    .right-30-ns {    right: calc(var(--grid-size) * 30); }
    .right-40-ns {    right: calc(var(--grid-size) * 40); }
    .right-50-ns {    right: calc(var(--grid-size) * 50); }

    .bottom-0-ns {    bottom: 0; }
    .bottom-1-ns {    bottom: calc(var(--grid-size) * 1); }
    .bottom-2-ns {    bottom: calc(var(--grid-size) * 2); }
    .bottom-3-ns {    bottom: calc(var(--grid-size) * 3); }
    .bottom-4-ns {    bottom: calc(var(--grid-size) * 4); }
    .bottom-5-ns {    bottom: calc(var(--grid-size) * 5); }
    .bottom-6-ns {    bottom: calc(var(--grid-size) * 6); }
    .bottom-7-ns {    bottom: calc(var(--grid-size) * 7); }
    .bottom-8-ns {    bottom: calc(var(--grid-size) * 8); }
    .bottom-9-ns {    bottom: calc(var(--grid-size) * 9); }
    .bottom-10-ns {    bottom: calc(var(--grid-size) * 10); }
    .bottom-11-ns {    bottom: calc(var(--grid-size) * 11); }
    .bottom-12-ns {    bottom: calc(var(--grid-size) * 12); }
    .bottom-13-ns {    bottom: calc(var(--grid-size) * 13); }
    .bottom-14-ns {    bottom: calc(var(--grid-size) * 14); }
    .bottom-15-ns {    bottom: calc(var(--grid-size) * 15); }
    .bottom-16-ns {    bottom: calc(var(--grid-size) * 16); }
    .bottom-17-ns {    bottom: calc(var(--grid-size) * 17); }
    .bottom-18-ns {    bottom: calc(var(--grid-size) * 18); }
    .bottom-19-ns {    bottom: calc(var(--grid-size) * 19); }
    .bottom-20-ns {    bottom: calc(var(--grid-size) * 20); }
    .bottom-25-ns {    bottom: calc(var(--grid-size) * 25); }
    .bottom-30-ns {    bottom: calc(var(--grid-size) * 30); }
    .bottom-40-ns {    bottom: calc(var(--grid-size) * 40); }
    .bottom-50-ns {    bottom: calc(var(--grid-size) * 50); }

    .left-0-ns {    left: 0; }
    .left-1-ns {    left: calc(var(--grid-size) * 1); }
    .left-2-ns {    left: calc(var(--grid-size) * 2); }
    .left-3-ns {    left: calc(var(--grid-size) * 3); }
    .left-4-ns {    left: calc(var(--grid-size) * 4); }
    .left-5-ns {    left: calc(var(--grid-size) * 5); }
    .left-6-ns {    left: calc(var(--grid-size) * 6); }
    .left-7-ns {    left: calc(var(--grid-size) * 7); }
    .left-8-ns {    left: calc(var(--grid-size) * 8); }
    .left-9-ns {    left: calc(var(--grid-size) * 9); }
    .left-10-ns {    left: calc(var(--grid-size) * 10); }
    .left-11-ns {    left: calc(var(--grid-size) * 11); }
    .left-12-ns {    left: calc(var(--grid-size) * 12); }
    .left-13-ns {    left: calc(var(--grid-size) * 13); }
    .left-14-ns {    left: calc(var(--grid-size) * 14); }
    .left-15-ns {    left: calc(var(--grid-size) * 15); }
    .left-16-ns {    left: calc(var(--grid-size) * 16); }
    .left-17-ns {    left: calc(var(--grid-size) * 17); }
    .left-18-ns {    left: calc(var(--grid-size) * 18); }
    .left-19-ns {    left: calc(var(--grid-size) * 19); }
    .left-20-ns {    left: calc(var(--grid-size) * 20); }
    .left-25-ns {    left: calc(var(--grid-size) * 25); }
    .left-30-ns {    left: calc(var(--grid-size) * 30); }
    .left-40-ns {    left: calc(var(--grid-size) * 40); }
    .left-50-ns {    left: calc(var(--grid-size) * 50); }

    .absolute--fill-ns {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
}

@media (--breakpoint-medium) {
    .top-0-m {    top: 0; }
    .top-1-m {    top: calc(var(--grid-size) * 1); }
    .top-2-m {    top: calc(var(--grid-size) * 2); }
    .top-3-m {    top: calc(var(--grid-size) * 3); }
    .top-4-m {    top: calc(var(--grid-size) * 4); }
    .top-5-m {    top: calc(var(--grid-size) * 5); }
    .top-6-m {    top: calc(var(--grid-size) * 6); }
    .top-7-m {    top: calc(var(--grid-size) * 7); }
    .top-8-m {    top: calc(var(--grid-size) * 8); }
    .top-9-m {    top: calc(var(--grid-size) * 9); }
    .top-10-m {    top: calc(var(--grid-size) * 10); }
    .top-11-m {    top: calc(var(--grid-size) * 11); }
    .top-12-m {    top: calc(var(--grid-size) * 12); }
    .top-13-m {    top: calc(var(--grid-size) * 13); }
    .top-14-m {    top: calc(var(--grid-size) * 14); }
    .top-15-m {    top: calc(var(--grid-size) * 15); }
    .top-16-m {    top: calc(var(--grid-size) * 16); }
    .top-17-m {    top: calc(var(--grid-size) * 17); }
    .top-18-m {    top: calc(var(--grid-size) * 18); }
    .top-19-m {    top: calc(var(--grid-size) * 19); }
    .top-20-m {    top: calc(var(--grid-size) * 20); }
    .top-25-m {    top: calc(var(--grid-size) * 25); }
    .top-30-m {    top: calc(var(--grid-size) * 30); }
    .top-40-m {    top: calc(var(--grid-size) * 40); }
    .top-50-m {    top: calc(var(--grid-size) * 50); }

    .right-0-m {    right: 0; }
    .right-1-m {    right: calc(var(--grid-size) * 1); }
    .right-2-m {    right: calc(var(--grid-size) * 2); }
    .right-3-m {    right: calc(var(--grid-size) * 3); }
    .right-4-m {    right: calc(var(--grid-size) * 4); }
    .right-5-m {    right: calc(var(--grid-size) * 5); }
    .right-6-m {    right: calc(var(--grid-size) * 6); }
    .right-7-m {    right: calc(var(--grid-size) * 7); }
    .right-8-m {    right: calc(var(--grid-size) * 8); }
    .right-9-m {    right: calc(var(--grid-size) * 9); }
    .right-10-m {    right: calc(var(--grid-size) * 10); }
    .right-11-m {    right: calc(var(--grid-size) * 11); }
    .right-12-m {    right: calc(var(--grid-size) * 12); }
    .right-13-m {    right: calc(var(--grid-size) * 13); }
    .right-14-m {    right: calc(var(--grid-size) * 14); }
    .right-15-m {    right: calc(var(--grid-size) * 15); }
    .right-16-m {    right: calc(var(--grid-size) * 16); }
    .right-17-m {    right: calc(var(--grid-size) * 17); }
    .right-18-m {    right: calc(var(--grid-size) * 18); }
    .right-19-m {    right: calc(var(--grid-size) * 19); }
    .right-20-m {    right: calc(var(--grid-size) * 20); }
    .right-25-m {    right: calc(var(--grid-size) * 25); }
    .right-30-m {    right: calc(var(--grid-size) * 30); }
    .right-40-m {    right: calc(var(--grid-size) * 40); }
    .right-50-m {    right: calc(var(--grid-size) * 50); }

    .bottom-0-m {    bottom: 0; }
    .bottom-1-m {    bottom: calc(var(--grid-size) * 1); }
    .bottom-2-m {    bottom: calc(var(--grid-size) * 2); }
    .bottom-3-m {    bottom: calc(var(--grid-size) * 3); }
    .bottom-4-m {    bottom: calc(var(--grid-size) * 4); }
    .bottom-5-m {    bottom: calc(var(--grid-size) * 5); }
    .bottom-6-m {    bottom: calc(var(--grid-size) * 6); }
    .bottom-7-m {    bottom: calc(var(--grid-size) * 7); }
    .bottom-8-m {    bottom: calc(var(--grid-size) * 8); }
    .bottom-9-m {    bottom: calc(var(--grid-size) * 9); }
    .bottom-10-m {    bottom: calc(var(--grid-size) * 10); }
    .bottom-11-m {    bottom: calc(var(--grid-size) * 11); }
    .bottom-12-m {    bottom: calc(var(--grid-size) * 12); }
    .bottom-13-m {    bottom: calc(var(--grid-size) * 13); }
    .bottom-14-m {    bottom: calc(var(--grid-size) * 14); }
    .bottom-15-m {    bottom: calc(var(--grid-size) * 15); }
    .bottom-16-m {    bottom: calc(var(--grid-size) * 16); }
    .bottom-17-m {    bottom: calc(var(--grid-size) * 17); }
    .bottom-18-m {    bottom: calc(var(--grid-size) * 18); }
    .bottom-19-m {    bottom: calc(var(--grid-size) * 19); }
    .bottom-20-m {    bottom: calc(var(--grid-size) * 20); }
    .bottom-25-m {    bottom: calc(var(--grid-size) * 25); }
    .bottom-30-m {    bottom: calc(var(--grid-size) * 30); }
    .bottom-40-m {    bottom: calc(var(--grid-size) * 40); }
    .bottom-50-m {    bottom: calc(var(--grid-size) * 50); }

    .left-0-m {    left: 0; }
    .left-1-m {    left: calc(var(--grid-size) * 1); }
    .left-2-m {    left: calc(var(--grid-size) * 2); }
    .left-3-m {    left: calc(var(--grid-size) * 3); }
    .left-4-m {    left: calc(var(--grid-size) * 4); }
    .left-5-m {    left: calc(var(--grid-size) * 5); }
    .left-6-m {    left: calc(var(--grid-size) * 6); }
    .left-7-m {    left: calc(var(--grid-size) * 7); }
    .left-8-m {    left: calc(var(--grid-size) * 8); }
    .left-9-m {    left: calc(var(--grid-size) * 9); }
    .left-10-m {    left: calc(var(--grid-size) * 10); }
    .left-11-m {    left: calc(var(--grid-size) * 11); }
    .left-12-m {    left: calc(var(--grid-size) * 12); }
    .left-13-m {    left: calc(var(--grid-size) * 13); }
    .left-14-m {    left: calc(var(--grid-size) * 14); }
    .left-15-m {    left: calc(var(--grid-size) * 15); }
    .left-16-m {    left: calc(var(--grid-size) * 16); }
    .left-17-m {    left: calc(var(--grid-size) * 17); }
    .left-18-m {    left: calc(var(--grid-size) * 18); }
    .left-19-m {    left: calc(var(--grid-size) * 19); }
    .left-20-m {    left: calc(var(--grid-size) * 20); }
    .left-25-m {    left: calc(var(--grid-size) * 25); }
    .left-30-m {    left: calc(var(--grid-size) * 30); }
    .left-40-m {    left: calc(var(--grid-size) * 40); }
    .left-50-m {    left: calc(var(--grid-size) * 50); }

    .absolute--fill-m {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
}

@media (--breakpoint-large) {
    .top-0-l {    top: 0; }
    .top-1-l {    top: calc(var(--grid-size) * 1); }
    .top-2-l {    top: calc(var(--grid-size) * 2); }
    .top-3-l {    top: calc(var(--grid-size) * 3); }
    .top-4-l {    top: calc(var(--grid-size) * 4); }
    .top-5-l {    top: calc(var(--grid-size) * 5); }
    .top-6-l {    top: calc(var(--grid-size) * 6); }
    .top-7-l {    top: calc(var(--grid-size) * 7); }
    .top-8-l {    top: calc(var(--grid-size) * 8); }
    .top-9-l {    top: calc(var(--grid-size) * 9); }
    .top-10-l {    top: calc(var(--grid-size) * 10); }
    .top-11-l {    top: calc(var(--grid-size) * 11); }
    .top-12-l {    top: calc(var(--grid-size) * 12); }
    .top-13-l {    top: calc(var(--grid-size) * 13); }
    .top-14-l {    top: calc(var(--grid-size) * 14); }
    .top-15-l {    top: calc(var(--grid-size) * 15); }
    .top-16-l {    top: calc(var(--grid-size) * 16); }
    .top-17-l {    top: calc(var(--grid-size) * 17); }
    .top-18-l {    top: calc(var(--grid-size) * 18); }
    .top-19-l {    top: calc(var(--grid-size) * 19); }
    .top-20-l {    top: calc(var(--grid-size) * 20); }
    .top-25-l {    top: calc(var(--grid-size) * 25); }
    .top-30-l {    top: calc(var(--grid-size) * 30); }
    .top-40-l {    top: calc(var(--grid-size) * 40); }
    .top-50-l {    top: calc(var(--grid-size) * 50); }

    .right-0-l {    right: 0; }
    .right-1-l {    right: calc(var(--grid-size) * 1); }
    .right-2-l {    right: calc(var(--grid-size) * 2); }
    .right-3-l {    right: calc(var(--grid-size) * 3); }
    .right-4-l {    right: calc(var(--grid-size) * 4); }
    .right-5-l {    right: calc(var(--grid-size) * 5); }
    .right-6-l {    right: calc(var(--grid-size) * 6); }
    .right-7-l {    right: calc(var(--grid-size) * 7); }
    .right-8-l {    right: calc(var(--grid-size) * 8); }
    .right-9-l {    right: calc(var(--grid-size) * 9); }
    .right-10-l {    right: calc(var(--grid-size) * 10); }
    .right-11-l {    right: calc(var(--grid-size) * 11); }
    .right-12-l {    right: calc(var(--grid-size) * 12); }
    .right-13-l {    right: calc(var(--grid-size) * 13); }
    .right-14-l {    right: calc(var(--grid-size) * 14); }
    .right-15-l {    right: calc(var(--grid-size) * 15); }
    .right-16-l {    right: calc(var(--grid-size) * 16); }
    .right-17-l {    right: calc(var(--grid-size) * 17); }
    .right-18-l {    right: calc(var(--grid-size) * 18); }
    .right-19-l {    right: calc(var(--grid-size) * 19); }
    .right-20-l {    right: calc(var(--grid-size) * 20); }
    .right-25-l {    right: calc(var(--grid-size) * 25); }
    .right-30-l {    right: calc(var(--grid-size) * 30); }
    .right-40-l {    right: calc(var(--grid-size) * 40); }
    .right-50-l {    right: calc(var(--grid-size) * 50); }

    .bottom-0-l {    bottom: 0; }
    .bottom-1-l {    bottom: calc(var(--grid-size) * 1); }
    .bottom-2-l {    bottom: calc(var(--grid-size) * 2); }
    .bottom-3-l {    bottom: calc(var(--grid-size) * 3); }
    .bottom-4-l {    bottom: calc(var(--grid-size) * 4); }
    .bottom-5-l {    bottom: calc(var(--grid-size) * 5); }
    .bottom-6-l {    bottom: calc(var(--grid-size) * 6); }
    .bottom-7-l {    bottom: calc(var(--grid-size) * 7); }
    .bottom-8-l {    bottom: calc(var(--grid-size) * 8); }
    .bottom-9-l {    bottom: calc(var(--grid-size) * 9); }
    .bottom-10-l {    bottom: calc(var(--grid-size) * 10); }
    .bottom-11-l {    bottom: calc(var(--grid-size) * 11); }
    .bottom-12-l {    bottom: calc(var(--grid-size) * 12); }
    .bottom-13-l {    bottom: calc(var(--grid-size) * 13); }
    .bottom-14-l {    bottom: calc(var(--grid-size) * 14); }
    .bottom-15-l {    bottom: calc(var(--grid-size) * 15); }
    .bottom-16-l {    bottom: calc(var(--grid-size) * 16); }
    .bottom-17-l {    bottom: calc(var(--grid-size) * 17); }
    .bottom-18-l {    bottom: calc(var(--grid-size) * 18); }
    .bottom-19-l {    bottom: calc(var(--grid-size) * 19); }
    .bottom-20-l {    bottom: calc(var(--grid-size) * 20); }
    .bottom-25-l {    bottom: calc(var(--grid-size) * 25); }
    .bottom-30-l {    bottom: calc(var(--grid-size) * 30); }
    .bottom-40-l {    bottom: calc(var(--grid-size) * 40); }
    .bottom-50-l {    bottom: calc(var(--grid-size) * 50); }

    .left-0-l {    left: 0; }
    .left-1-l {    left: calc(var(--grid-size) * 1); }
    .left-2-l {    left: calc(var(--grid-size) * 2); }
    .left-3-l {    left: calc(var(--grid-size) * 3); }
    .left-4-l {    left: calc(var(--grid-size) * 4); }
    .left-5-l {    left: calc(var(--grid-size) * 5); }
    .left-6-l {    left: calc(var(--grid-size) * 6); }
    .left-7-l {    left: calc(var(--grid-size) * 7); }
    .left-8-l {    left: calc(var(--grid-size) * 8); }
    .left-9-l {    left: calc(var(--grid-size) * 9); }
    .left-10-l {    left: calc(var(--grid-size) * 10); }
    .left-11-l {    left: calc(var(--grid-size) * 11); }
    .left-12-l {    left: calc(var(--grid-size) * 12); }
    .left-13-l {    left: calc(var(--grid-size) * 13); }
    .left-14-l {    left: calc(var(--grid-size) * 14); }
    .left-15-l {    left: calc(var(--grid-size) * 15); }
    .left-16-l {    left: calc(var(--grid-size) * 16); }
    .left-17-l {    left: calc(var(--grid-size) * 17); }
    .left-18-l {    left: calc(var(--grid-size) * 18); }
    .left-19-l {    left: calc(var(--grid-size) * 19); }
    .left-20-l {    left: calc(var(--grid-size) * 20); }
    .left-25-l {    left: calc(var(--grid-size) * 25); }
    .left-30-l {    left: calc(var(--grid-size) * 30); }
    .left-40-l {    left: calc(var(--grid-size) * 40); }
    .left-50-l {    left: calc(var(--grid-size) * 50); }

    .absolute--fill-l {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
}
