/*

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.strike       { text-decoration: line-through; }
.underline    { text-decoration: underline; }
.no-underline { text-decoration: none; }


@media (--breakpoint-not-small) {
  .strike-ns       { text-decoration: line-through; }
  .underline-ns    { text-decoration: underline; }
  .no-underline-ns { text-decoration: none; }
}

@media (--breakpoint-medium) {
  .strike-m       { text-decoration: line-through; }
  .underline-m    { text-decoration: underline; }
  .no-underline-m { text-decoration: none; }
}

@media (--breakpoint-large) {
  .strike-l       { text-decoration: line-through; }
  .underline-l {    text-decoration: underline; }
  .no-underline-l { text-decoration: none; }
}
