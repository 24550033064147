.fullscreen-modal-gh-wn-modal {
    max-width: 640px;
}

.gh-wn-header {
    position: relative;
    display: flex;
    align-items: center;
    margin: -32px -32px 0;
    padding: 18px 18px 12px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--pink);
    background: linear-gradient(135deg, color-mod(var(--pink) h(-10) s(+5%) l(-10%)) 0%, rgba(173,38,180,1) 100%);
}

.gh-wn-header .background-img {
    position: absolute;
    top: -30px;
    left: 0;
}

.gh-wn-header h2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFF;
    margin: 0 8px 4px;
}

.gh-wn-header svg path {
    fill: #fff;
}

.gh-wn-content {
    margin: 0 -32px;
    padding: 0;
    max-height: calc(100vh - 170px);
    overflow-y: scroll;
}

.gh-wn-close {
    stroke: #FFF;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

.gh-wn-close:hover {
    opacity: 1.0;
}

.gh-wn-entry {
    margin: 0 0 3vw;
    padding: 0 4vw 1vw;
    border-bottom: 1px solid var(--whitegrey);
}

.gh-wn-entry:last-of-type {
    margin-bottom: 0px;
    border-bottom: none;
    padding-bottom: 0px;
}

.gh-wn-entry h4 {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin: 24px 0 4px;
    color: var(--midlightgrey);
}

.gh-wn-entry p {
    margin: 0 0 20px;
    padding: 0;
}

.gh-wn-entry img {
    height: auto;
}


/* Bookmark card details */
.gh-wn-entry .kg-bookmark-card {
    margin-bottom: 20px;
}

.gh-wn-entry .kg-bookmark-container {
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: var(--darkgrey);
    text-decoration: none;
    min-height: 148px;
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
}

.gh-wn-entry .kg-bookmark-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
}

.gh-wn-entry .kg-bookmark-title {
    font-size: 1.3rem;
    line-height: 1.5em;
    font-weight: 600;
    color: color(var(--midgrey) l(-30%));
}

.gh-wn-entry .kg-bookmark-container:hover .kg-bookmark-title {
    color: var(--blue);
}

.gh-wn-entry .kg-bookmark-description {
    display: -webkit-box;
    font-size: 1.25rem;
    line-height: 1.5em;
    color: color(var(--midgrey) l(-10%));
    font-weight: 400;
    margin-top: 12px;
    max-height: 36px;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.gh-wn-entry .kg-bookmark-thumbnail {
    position: relative;
    min-width: 40%;
    max-height: 100%;
}

.gh-wn-entry .kg-bookmark-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 3px 3px 0;
}

.gh-wn-entry .kg-bookmark-metadata {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 400;
    color: color(var(--midgrey) l(-10%));
    margin-top: 14px;
    flex-wrap: wrap;
}

.gh-wn-entry .kg-bookmark-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.gh-wn-entry .kg-bookmark-author {
    line-height: 1.5em;
}

.gh-wn-entry .kg-bookmark-author:after {
    content: "•";
    margin: 0 6px;
}

.gh-wn-entry .kg-bookmark-publisher {
    overflow: hidden;
    line-height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
}

.gh-wn-entry .gh-wn-footer {
    margin: 0 -32px -32px;
    padding: 14px 32px 16px;
    border-top: 1px solid var(--whitegrey);
    justify-content: space-between;
}

.gh-wn-footer {
    position: relative;
    margin-top: 14px;
    margin-bottom: -13px;
}

.gh-wn-footer:before {
    position: absolute;
    content: "";
    top: -14px;
    left: -32px;
    right: -32px;
    height: 6px;
    background: rgba(255,255,255,0);
    box-shadow:
        0 -0.3px 1px rgba(0, 0, 0, 0.03),
        0 -4px 7px rgba(0, 0, 0, 0.06);
}