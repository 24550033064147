.ember-power-calendar {
  box-sizing: border-box;
  position: relative;
}

.ember-power-calendar-nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.ember-power-calendar-nav * {
  box-sizing: border-box;
}

.ember-power-calendar-days,
.ember-power-calendar-days * {
  box-sizing: border-box;
}

.ember-power-calendar-nav-title {
  flex: 1;
  text-align: center;
}

.ember-power-calendar-row {
  display: flex;
  justify-content: space-between;
}

.ember-power-calendar-weekday {
  -webkit-appearance: none;
  flex: 1 1 100%;
  padding: 0;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  justify-content: center;
  display: flex;
  align-items: center;
}

.ember-power-calendar-day {
  -webkit-appearance: none;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  flex: 1 1 100%;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.ember-power-calendar-nav-control {
  -webkit-appearance: none;
  background-color: transparent;
  background-color: initial;
  border: none;
  border: initial;
  outline: none;
  outline: initial;
  font-size: inherit;
}

/* Theme styles */
.ember-power-calendar {
  font-size: 14px;
  line-height: 1.65;
}

.ember-power-calendar-weekdays {
  color: var(--darkgrey);
  padding: 4px 0;
}

.ember-power-calendar-day {
  color: var(--midgrey);
}

.ember-power-calendar-nav {
  padding: 0 4px 8px;
}

.ember-power-calendar-nav-control {
  position: relative;
  bottom: 2px;
  line-height: 1;
  font-size: 150%;
  color: var(--black);
}

.ember-power-calendar-nav-control:focus {
  color: color-mod(var(--black) l(+10%));
}

.ember-power-calendar-day--current-month {
  color: var(--darkgrey);
}

.ember-power-calendar-day--today,
.ember-power-calendar-day:not([disabled]):hover {
  background-color: var(--whitegrey);
  border-radius: 3px;
}

.ember-power-calendar-day--other-month:not([disabled]):hover {
  color: var(--midgrey);
}

.ember-power-calendar-day--selected,
.ember-power-calendar-day--selected:not([disabled]):hover {
  font-weight: bold;
  background-color: color-mod(var(--green) l(+30%));
  color: var(--darkgrey);
  border-radius: 3px;
}

.ember-power-calendar-day--selected.ember-power-calendar-day--range-start,
.ember-power-calendar-day--selected.ember-power-calendar-day--range-end {
  background-color: color-mod(var(--green) l(+40%));
}

.ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover,
.ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover {
    background-color: color-mod(var(--green) l(+40%));
}

.ember-power-calendar-day--focused {
  box-shadow: inset 0px -2px 0px 0px var(--green);
}

.ember-power-calendar-day--interactive[disabled] {
  opacity: 0.4;
}

/*
@mixin ember-power-calendar(
  $cell-size: null,
  $cell-width: $cell-size,
  $cell-height: $cell-size,
  $cell-with-spacing-width: $cell-width + 2px,
  $row-padding-top: 0,
  $row-padding-bottom: 0,
  $row-padding-left: 0,
  $row-padding-right: 0,
  $row-width: $cell-with-spacing-width * 7 - 2px + $row-padding-left + $row-padding-right) {

  width: $row-width;
  .ember-power-calendar-week:first-child {
    &[data-missing-days="1"] {
      padding-left: $cell-with-spacing-width * 1;
    }
    &[data-missing-days="2"] {
      padding-left: $cell-with-spacing-width * 2;
    }
    &[data-missing-days="3"] {
      padding-left: $cell-with-spacing-width * 3;
    }
    &[data-missing-days="4"] {
      padding-left: $cell-with-spacing-width * 4;
    }
    &[data-missing-days="5"] {
      padding-left: $cell-with-spacing-width * 5;
    }
    &[data-missing-days="6"] {
      padding-left: $cell-with-spacing-width * 6;
    }
  }
  .ember-power-calendar-week:last-child {
    &[data-missing-days="1"] {
      padding-right: $cell-with-spacing-width * 1;
    }
    &[data-missing-days="2"] {
      padding-right: $cell-with-spacing-width * 2;
    }
    &[data-missing-days="3"] {
      padding-right: $cell-with-spacing-width * 3;
    }
    &[data-missing-days="4"] {
      padding-right: $cell-with-spacing-width * 4;
    }
    &[data-missing-days="5"] {
      padding-right: $cell-with-spacing-width * 5;
    }
    &[data-missing-days="6"] {
      padding-right: $cell-with-spacing-width * 6;
    }
  }
  .ember-power-calendar-day, .ember-power-calendar-weekday {
    max-width: $cell-width;
    max-height: $cell-height;
    width: $cell-width;
    height: $cell-height;
  }
  .ember-power-calendar-weekdays, .ember-power-calendar-week {
    height: $cell-height + 2px;
    padding-left: $row-padding-left;
    padding-right: $row-padding-right;
  }
}

.datepicker-small {
  @include ember-power-calendar($cell-size: 30px);
}
*/


/* Datepicker */
.ember-power-datepicker-content {
  min-width: 212px;
  padding: 12px;
}

.ember-power-datepicker-trigger:focus {
    outline: none;
}
