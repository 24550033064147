/* Settings menu
/* ---------------------------------------------------------- */
.gh-nav-settings-close {
    margin: 26px 0;
    padding: 0;
}

.gh-nav-settings-close h4 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2px 28px;
    font-size: 1.9rem;
}

.gh-nav-settings-close a {
    display: flex;
    padding: 5px 4px 2px;
    width: 30px;
    height: 30px;
    border-radius: 999px;
    margin: 0 -12px 0 0;
    align-items: center;
    justify-content: center;
}

.gh-nav-settings-close a:hover {
    background: var(--mainmenu-color-active-bg);
}

.gh-nav-settings-close a svg {
    width: 16px;
    height: 16px;
    margin-top: -3px;
}

.gh-nav-settings-main {
    margin: 7px 0;
}

.gh-nav-settings-main .active {
    background: none !important;
    font-weight: 400;
    color: color-mod(var(--middarkgrey) l(-10%));
}


/* Settings
/* ---------------------------------------------------------- */
.gh-settings-main-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: var(--white);
    border-radius: .5rem;;
}

.gh-settings-main-grid .gh-setting-group {
    display: flex;
    color: var(--darkgrey);
    padding: 20px 20px 20px 0;
    min-height: 85px;
    text-align: left;
}

.gh-settings-main-grid .gh-setting-group span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: var(--black);
    width: 48px;
    height: 48px;
    min-width: 48px;;
    border-radius: 999px;
    color: #fff;
}

.gh-settings-main-grid .gh-setting-group span.yellow {
    background: var(--yellow);
}

.gh-settings-main-grid .gh-setting-group span.green {
    background: var(--green);
}

.gh-settings-main-grid .gh-setting-group span.blue {
    background: var(--blue);
}

.gh-settings-main-grid .gh-setting-group span.pink {
    background: var(--pink);
}

.gh-settings-main-grid .gh-setting-group:hover span {
    opacity: 0.9;
}

.gh-settings-main-grid .gh-setting-group svg {
    width: 20px;
    height: 20px;
}

.gh-settings-main-grid .gh-setting-group.portal svg {
    width: 24px;
    height: 24px;
}

.gh-settings-main-grid .gh-setting-group div {
    margin-left: 14px;
    flex-shrink: 1;
}

.gh-settings-main-grid .gh-setting-group h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 4px 0 2px;
}

.gh-settings-main-grid .gh-setting-group p {
    color: var(--midgrey);
    margin: 4px 0 0;
    padding: 0;
    line-height: 1.4em;
}

@media (max-width: 1100px) {
    .gh-settings-main-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 680px) {
    .gh-settings-main-grid {
        grid-template-columns: 1fr;
    }
}

/* Setting headers */

.gh-setting-header {
    margin: 4vw 0 0 1px;
    color: var(--black);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.35px;
    font-size: 1.2rem;
    padding: 8px 0;
    border-bottom: 1px solid var(--main-color-area-divider);
}

.gh-first-header {
    margin-top: 0;
}

.gh-setting,
.gh-setting-first,
.gh-setting-last {
    display: flex;
    justify-content: space-between;
    padding: 18px 0;
    margin: 0;
}

.gh-setting-first {
    border: none;
    padding-top: 0px;
}

.gh-setting-last {
    padding-bottom: 0px;
}

.gh-setting-content {
    width: 100%;
    margin: 0 50px 0 0;
}

.gh-members-setting-content {
    width: 100%;
    margin: 0;
}

.gh-setting-content--no-action {
    margin: 0;
}

.gh-setting-title {
    margin-bottom: 2px;
    font-size: 1.5rem;
    line-height: 1.15em;
    font-weight: 600;
    color: var(--black);
}

.gh-setting-title.m {
    font-size: 1.4rem;
    font-weight: 500;
}

.gh-setting-desc {
    line-height: 1.4em;
    color: var(--middarkgrey);
    letter-spacing: 0.3px;
    font-size: 1.3rem;
    font-weight: 400;
    margin: 4px 0 12px;
}

.gh-setting-desc.mb0 {
    margin-bottom: 0;
}

.gh-setting-error {
    margin-top: 1em;
    line-height: 1.3em;
    color: var(--red);
    font-weight: 300;
    letter-spacing: 0.3px;
}

.gh-setting-action {
    flex-shrink: 0;
    margin: 1px 0 0 0;
    align-self: center;
}

.gh-setting-action .for-checkbox label,
.gh-setting-action .for-radio label {
    padding-bottom: 0;
    margin-bottom: 0;
}

.gh-setting-content-extended label {
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--darkgrey);
    margin-bottom: 4px;
}

.gh-setting-content-extended textarea {
    font-size: 1.5rem;
    line-height: 1.4em;
    max-width: initial;
}

.gh-setting-content-extended .gh-image-uploader {
    margin: 0;
    border: 1px solid var(--whitegrey-d2);
}

.gh-setting-content-extended .gh-btn span {
    height: 36px;
    line-height: 36px;
}

.gh-setting-liquid-section .liquid-container,
.gh-setting-liquid-section .liquid-child {
    padding: 0 20px;
    margin: 0 -20px;
}

.gh-settings-portal-section {
    box-shadow:
        0 0 1px rgba(0,0,0,.07),
        0 1.5px 1.2px -11px rgba(0, 0, 0, 0.028),
        0 5.1px 4px -11px rgba(0, 0, 0, 0.042),
        0 23px 18px -16px rgba(0, 0, 0, 0.07)
    ;
}

.gh-settings-portal-border {
    position: absolute;
    content: "";
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
    border: 1px solid var(--blue);
    border-radius: 8px;
}


/* Images */

.gh-setting-action-smallimg {
    position: relative;
}

.gh-setting-action-smallimg img {
    height: 50px;
    width: auto;
    max-width: 250px;
}

.gh-setting-action-largeimg img {
    min-height: 80px;
    width: auto;
    max-width: 250px;
}

@media (max-width: 500px) {
    .gh-setting-action-largeimg img {
        max-width: 190px;
    }
}

.gh-setting-action-smallimg img:hover,
.gh-setting-action-largeimg img:hover {
    cursor: pointer;
}

.gh-setting-action-smallimg-delete,
.gh-setting-action-largeimg-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--midgrey);
    margin-top: 8px;
    text-decoration: none;
    font-size: 13px;
    line-height: 10px;
}

.gh-setting-action-smallimg-delete:hover,
.gh-setting-action-largeimg-delete:hover {
    color: var(--red);
    text-decoration: underline;
}

.gh-setting-action-smallimg-delete svg path,
.gh-setting-action-largeimg-delete svg path {
    fill: #fff;
}

.gh-setting-action .gh-progress-container {
    width: 113px;
    height: 100%;
}

.gh-setting-action .gh-progress-container-progress {
    width: 100%;
}

.gh-setting-action .gh-progress-bar {
    height: 9px;
}

/* Checkboxes */

.gh-setting-action .input-toggle-component {
    float: none;
    margin-right: 0;
    width: 24px;
    height: 24px;
}

.gh-setting-action .input-toggle-component:before {
    top: 6px;
    left: 5px;
    width: 12px;
    height: 7px;
}

.gh-setting-content-extended {
    width: 100%;
}

/* Theme Directory
/* ---------------------------------------------------------- */

.gh-td-marketplace {
    display: inline-block;
    outline: none;
    color: var(--green-d1);
    font-weight: 500;
    text-decoration: none !important;
    text-transform: none;
}

.gh-td-marketplace span {
    display: block;
    overflow: hidden;
    font-size: 1.35rem;
    letter-spacing: 0.2px;
}

.gh-td-marketplace span svg {
    position: relative;
    top: 1px;
    width: .7em;
    height: .7em;
    margin-left: 4px;
}

.gh-td-marketplace span svg path {
    stroke: var(--green-d1);
    stroke-width: 4px;
}

.gh-theme-directory-container {
    padding: 25px 0 0;
}

.theme-directory {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    max-width: 1320px;
    margin: 0 0 4vw;
}

.td-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: var(--darkgrey);
    transition: all .8s ease;
}

.td-item:nth-child(6) {
    display: none;
}

.td-item img {
    box-shadow: 0 0 1px rgba(0,0,0,.02), 0 9px 25px -10px rgba(0,0,0,0.2);
    transition: all .8s ease;
    border-radius: 3px;
}

.td-item svg circle {
    stroke: var(--midlightgrey);
}

.td-item:hover {
    transform: translateY(-1%);
    transition: all .3s ease;
}

.td-item:hover img {
    box-shadow: 0 0 1px rgba(0,0,0,.02), 0 19px 35px -14px rgba(0,0,0,.2);
    transition: all .3s ease;
}

.td-item-desc {
    display: flex;
    width: 100%;
    margin-top: 16px;
    text-transform: uppercase;
    font-weight: 700;
}

.td-item-desc span {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
    text-transform: none;
    font-weight: 400;
    font-size: 1em;
    color: color-mod(var(--midgrey) l(-5%));
}

.td-item-screenshot {
    line-height: 0;
    border-radius: 3px;
}

.td-item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.td-item-overlay:hover,
.td-item-overlay:focus {
    background-color: var(--white-90);
    transition: all .3s ease;
}

.td-item-action {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease;}

.td-item-overlay:hover .td-item-action {
    visibility: visible;
    opacity: 1;
}

.td-item-action.gh-btn {
    width: 80px;
}

@media (max-width: 1400px) {
    .theme-directory {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .td-item:nth-child(5),
    .td-item:nth-child(6) {
        display: none;
    }
}

@media (max-width: 1200px) {
    .td-cta {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1100px) {
    .theme-directory {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .td-item:nth-child(4) {
        display: none;
    }
}

@media (max-width: 1000px) {
    .theme-directory {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .td-item:nth-child(4),
    .td-item:nth-child(5),
    .td-item:nth-child(6) {
        display: flex;
    }
}

@media (max-width: 600px) {
    .theme-directory {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 25px;
    }
    .td-item:nth-child(5),
    .td-item:nth-child(6) {
        display: none;
    }
    .td-cta {
        margin: 50px 0;
    }
}

/* General
/* ---------------------------------------------------------- */

.gh-seo-settings {
    display: flex;
}

@media (max-width: 1360px) {
    .gh-seo-settings {
        flex-direction: column;
    }

    .gh-seo-settings .form-group {
        max-width: 100%;
    }

    .gh-seo-settings-left,
    .gh-seo-container {
        max-width: 591px;
    }
}

@media (min-width: 1360px) {
    .gh-seo-settings-left {
        margin-right: 2.4rem;
    }

    .gh-seo-container {
        max-width: 1091px;
    }
}

.gh-seo-container {
    display: flex;
    width: 100%;
    margin-bottom: 2.4rem;
    padding: 20px 30px 16px;
    border: 1px solid var(--whitegrey-d1);
    font-family: Arial, sans-serif;
    background: #fff;
    border-radius: 3px;
}

.gh-seo-container svg {
    width: 92px;
    height: 30px;
    margin-right: 32px;
}

.gh-twitter-settings {
    display: flex;
}

@media (max-width: 1360px) {
    .gh-twitter-settings {
        flex-direction: column;
    }

    .gh-twitter-settings .form-group {
        max-width: 100%;
    }

    .gh-twitter-settings-left {
        max-width: 591px;
    }
}

@media (min-width: 1360px) {
    .gh-twitter-settings-left {
        margin-right: 2.4rem;
    }
}

.gh-twitter-container {
    width: 591px;
    margin-bottom: 2.4rem;
    border: 1px solid var(--whitegrey-d1);
    background: #fff;
    border-radius: 3px;
}

@media (max-width: 1080px) {
    .gh-twitter-container {
        width: 100%;
        max-width: 591px;
    }
}

.gh-og-settings {
    display: flex;
}

@media (max-width: 1360px) {
    .gh-og-settings {
        flex-direction: column;
    }

    .gh-og-settings .form-group {
        max-width: 100%;
    }

    .gh-og-settings-left {
        max-width: 591px;
    }
}

@media (min-width: 1360px) {
    .gh-og-settings-left {
        margin-right: 2rem;
    }
}

.gh-og-container {
    width: 476px;
    margin-bottom: 2.4rem;
    border: 1px solid var(--whitegrey-d1);
    background: #fff;
    border-radius: 3px;
}

@media (max-width: 1080px) {
    .gh-og-container {
        width: 100%;
        max-width: 476px;
    }
}

/* Navigation
/* ---------------------------------------------------------- */

.gh-blognav-container {
    padding: 25px 0;
    border-top: var(--lightgrey) 1px solid;
}

.gh-blognav {
    margin: 8px 0 0;
}

.gh-blognav-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.gh-blognav-item--error {
    margin-bottom: calc(1em + 10px);
}

.gh-blognav-item .response {
    position: absolute;
    margin-bottom: 0;
}

.gh-blognav-grab {
    padding: 6px 16px 0 0;
    width: 16px;
    text-indent: -4px;
    cursor: move;
}

.gh-blognav-grab svg {
    width: 16px;
    height: 16px;
    fill: color-mod(var(--midgrey) l(+15%));
}

.gh-blognav-line {
    display: flex;
    width: 100%;
}

.gh-blognav-label {
    flex-grow: 1;
    margin-right: 10px;
}

.gh-blognav-url {
    flex-grow: 3;
}

.gh-blognav-delete {
    padding: 8px 0 8px 10px;
    display: flex;
    align-items: center;
}

.gh-blognav-delete svg {
    fill: color-mod(var(--midgrey) l(+15%));
    height: 14px;
    width: 14px;
    transition: fill 0.1s linear;
}

.gh-blognav-delete svg:hover,
.gh-blognav-delete svg:focus {
    fill: var(--red);
}

.gh-blognav-add {
    margin-right: -1px;
    margin-left: 9px;
    width: 16px;
    height: 16px;
    background: var(--green);
    border-radius: 2px;
    transition: background 0.1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gh-blognav-add svg {
    fill: #fff;
    height: 9px;
    width: 9px;
}

.gh-blognav-add:hover,
.gh-blognav-add:focus {
    background: color-mod(var(--green) lightness(-10%));
}

.gh-blognav-item:not(.gh-blognav-item--sortable) {
    padding-left: 16px;
    margin-bottom: 0;
}

/* Remove space between inputs on smaller screens */
@media (max-width: 800px) {
    .gh-blognav-label {
        margin-right: -1px;
    }
    .gh-blognav-label input {
        border-right-color: color-mod(var(--lightgrey) l(-5%) s(-10%));
        border-radius: 4px 0 0 4px;
    }
    .gh-blognav-url input {
        border-left-color: color-mod(var(--lightgrey) l(-5%) s(-10%));
        border-radius: 0 4px 4px 0;
    }
    .gh-blognav-item input:focus {
        position: relative;
        z-index: 100;
    }
}


/* Email newsletter
/* ---------------------------------------------------------- */

.member-segments .select-members {
    margin-bottom: .4rem;
}

.member-segments .segment-totals {
    position: relative;
    top: 2px;
    line-height: 1.65;
}


/* Code injection
/* ---------------------------------------------------------- */

.settings-code {
    max-width: 100%;
}

.settings-code label {
    font-size: 1.5rem;
    margin-bottom: 2px;
}

.settings-code p {
    margin: 0 0 8px;
    font-size: 1.3rem;
}

.settings-code code {
    background-color: rgb(242, 244, 247);
    border: 1px solid var(--lightgrey);
    vertical-align: middle;
    font-size: 1.2rem;
}

.settings-code-editor {
    padding: 0;
    min-width: 250px;
    min-height: 300px;
    max-width: 1224px;
    width: calc(100vw - 416px) !important;
    height: auto;
    line-height: 22px;
    border: 1px solid var(--lightgrey);
}

.settings-code-editor:hover {
    cursor: text;
}

.settings-code-editor textarea {
    width: 100%;
    max-width: none;
    min-height: 300px;
    line-height: 22px;
    border: none;
}

.settings-code-editor .CodeMirror {
    padding: 0;
    border: none;
    border-radius: inherit;
    background: var(--white);
    color: var(--darkgrey);
}

.settings-code-editor .CodeMirror-gutters {
    background-color: var(--whitegrey-l2);
    border-right: 1px solid var(--lightgrey);
}

.settings-code-editor .CodeMirror-cursor {
    border: 1px solid var(--midgrey);
}

.settings-code-editor .cm-s-xq-light span.cm-meta {
    color: #000;
}

@media (max-width: 800px) {
    .settings-code-editor {
        width: calc(100vw - 8vw - 40px) !important;
    }
}



/* Labs
/* ---------------------------------------------------------- */

#startupload {
    line-height: inherit;
}

@media (max-width: 500px) {
    #importfile {
        flex-direction: column;
    }
    #importfile input {
        width: 150px;
    }

    #startupload {
        margin-left: 0;
        margin-top: 5px;
    }
}

.gh-import-errors {
    position: relative;
    padding: 12px 10px 14px 10px;
    border: 1px solid var(--lightgrey);
    border-left-width: 5px;
    border-left-color: var(--red);
    color: var(--midgrey);
    line-height: 1.4em;
    letter-spacing: 0.2px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 25px;
}

.gh-import-errors-alert {
    border-left-color: color-mod(var(--yellow) l(-8%) s(+10%));
}

.gh-import-errors-title {
    margin-bottom: 1em;
    font-size: 1.8rem;
    line-height: 1.15em;
    font-weight: 600;
    color: var(--red);
}

.gh-import-errors-alert .gh-import-errors-title {
    color: color-mod(var(--yellow) l(-8%) s(+10%));
}

.gh-import-error {
    margin-bottom: 1.75em;
}

.gh-import-error:last-of-type {
    margin-bottom: 0;
}

.gh-import-error-message {
    margin-bottom: 0.5em;
    font-weight: 300;
}

.gh-import-error-entry pre {
    margin: 0;
    font-size: 10px;
}

.gh-setting-linkrow:hover {
    background: var(--whitegrey-l2);
}

/* Themes
/* ---------------------------------------------------------- */

@media (max-width: 500px) {
    .gh-themes-container .apps-configured {
        justify-content: flex-end;
    }
    .gh-themes-container .apps-card-meta {
        flex-basis: auto;
    }
}

/*Errors */
.theme-validation-container {
    overflow-y: auto;
    margin: -32px -32px 0;
    padding: 32px 32px 0;
    max-height: calc(100vh - 20vw);
}

@media (max-height: 960px) {
    .theme-validation-container {
        max-height: calc(100vh - 180px);
    }
}

.theme-validation-item {
    margin: 12px 0 0;
    padding: 12px 16px;
    border: 1px solid #e5eff5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background: var(--whitegrey);
    border: 1px solid var(--lightgrey);
}

.theme-validation-item h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5em;
}

.theme-validation-rule-text {
    flex-grow: 1;
}

.theme-validation-item.theme-fatal-error {
    background: color-mod(var(--red) alpha(0.04));
    border: 1px solid color-mod(var(--red) alpha(0.4));
}

.theme-validation-item.theme-fatal-error .theme-validation-rule-text::before,
.theme-validation-item.theme-error .theme-validation-rule-text::before,
.theme-validation-item.theme-warning .theme-validation-rule-text::before
{
    font-weight: 600;
}

.theme-validation-item.theme-fatal-error .theme-validation-rule-text::before {
    content: "Fatal error:";
    color: var(--red);
}

.theme-validation-item.theme-error .theme-validation-rule-text::before {
    content: "Error:";
}

.theme-validation-item.theme-warning .theme-validation-rule-text::before {
    content: "Warning:";
}

.theme-fatal-error .theme-validation-type-label::before,
.theme-error .theme-validation-type-label::before,
.theme-warning .theme-validation-type-label::before {
    content: "";
    display: block;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 8px;
    height: 16px;
    margin-top: 3px;
    margin-left: -17px;
}

.theme-fatal-error .theme-validation-type-label::before,
.theme-error .theme-validation-type-label::before {
    background: color-mod(var(--red) alpha(0.85));
}

.theme-warning .theme-validation-type-label::before {
    background: color-mod(var(--yellow));
}

.theme-validation-list ul {
    list-style: disc;
}

.theme-validation-list code,
.theme-validation-rule-text code {
    font-size: 0.9em;
}

.theme-validation-item h6 {
    font-size: 1.3rem;
    font-weight: 500;
}

.theme-validation-item {
    background: var(--whitegrey-l2);
}

.theme-validation-toggle-details {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-start;
    padding: 0;
    color: var(--darkgrey);
    text-decoration: none!important;
    font-size: 1.3rem;
}

.theme-validation-rule-icon {
    flex-shrink: 0;
    margin-left: 5px;
    width: 13px;
    color: var(--midgrey);
    transition: all 0.1s ease-out;
}

.theme-validation-rule-icon svg {
    margin-top: 3px;
}

.theme-validation-rule-icon svg path {
    fill: var(--midgrey);
}

.theme-validation-details {
    margin-top: 12px;
    padding-top: 12px;
    font-size: 1.3rem;
    border-top: 1px solid var(--lightgrey);
}

p.theme-validation-details {
    font-size: 1.3rem;
}

.theme-validation-screenshot img {
    margin-bottom: 2rem;
    border: 1px solid var(--main-color-area-divider);
    border-radius: 3px;
}


/* Publication identity
/* ---------------------------------------------------------- */
.blog-logo,
.blog-icon {
    max-height: 50px;
    height: auto !important;
}

/** CSS for accent color */
.input-color-form-group {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 0;
}

.input-color {
    display: flex;
    position: relative;
}

.input-color:after {
    content: "#";
    position: absolute;
    top: 9px;
    left: 43px;
    color: var(--midlightgrey);
    font-family: "Consolas", monaco, monospace;
    font-size: 13px;
}

.input-color:focus {
    border: none;
}

.input-color input {
    padding-left: 52px;
    width: 112px;
    height: 38px;
    padding-right: 8px;
    font-family: "Consolas", monaco, monospace;
    font-size: 13px;
}

.input-color .color-box {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 36px;
    height: 36px;
    display: inline-block;
    background-color: var(--lightgrey);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid var(--input-border);
    box-shadow: inset 0 0 0 1px var(--white);
}

.input-color input:focus + .color-box {
    top: 2px;
    left: 2px;
    width: 35px;
    height: 34px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.gh-setting-unsplash-checkbox {
    margin-bottom: 0;
}

/* Branding
/* ---------------------------------------------------- */
.fullscreen-modal-branding-modal {
    margin: 30px;
    max-width: 100%;
}

.fullscreen-modal-branding-modal .modal-content {
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 0;
}

.fullscreen-modal-branding-modal .modal-body {
    margin: 0;
    padding: 20px 24px;
}

.gh-branding-settings {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.gh-branding-settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--whitegrey);
    margin: -20px -24px;
    padding: 16px 24px;
}

.gh-branding-settings-header h4 {
    margin: 0;
    padding: 0;
    font-size: 1.9rem;
    font-weight: 600;
}

.gh-branding-settings-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.gh-branding-settings-actions .close {
    padding: 4px;
    margin-right: 12px;
}

.gh-branding-settings-options {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    border-right: 1px solid var(--whitegrey);
    min-width: 320px;
    max-width: 400px;
    margin: 20px 0 -20px;
    padding: 24px 24px 24px 0;
    overflow-y: auto;
    height: calc(100vh - 136px);
}

.gh-branding-image-container {
    position: relative;
    align-self: flex-start;
    height: 50px;
}

.gh-branding-image-container.largeimg {
    width: 100%;
    display: flex;
    height: unset;
    min-height: 80px;
    align-items: center;
}

.gh-branding-image-container.transparent-bg {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3ERectangle%3C/title%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23E6EEF2' d='M0 0h24v24H0z'/%3E%3Cpath fill='%23D8E2E8' d='M0 0h12v12H0zM12 12h12v12H12z'/%3E%3C/g%3E%3C/svg%3E");
}

.gh-branding-settings-options .gh-setting-action-largeimg-delete,
.gh-branding-settings-options .gh-setting-action-smallimg-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding: 5px;
    margin: 0;
    border-radius: 3px;
    opacity: 0;
}

.gh-branding-settings-options .gh-setting-action-largeimg-delete:hover,
.gh-branding-settings-options .gh-setting-action-smallimg-delete:hover {
    background: var(--red);
    border-color: transparent;
}

.gh-branding-image-container:hover .gh-setting-action-largeimg-delete,
.gh-branding-image-container:hover .gh-setting-action-smallimg-delete {
    opacity: 1;
}

.gh-branding-settings-right {
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 20px -24px -20px 0;
    background: var(--whitegrey-l1);
}

.gh-branding-settings-previewcontainer {
    margin: 32px 68px 68px;
}

.gh-branding-settings-previewcontainer .site-frame,
.gh-launch-wizard-preview {
    width: 133.33333%;
    height: 133.33333%;
    transform: scale(0.75);
    transform-origin: 0 0;
}

.gh-branding-settings .input-color input {
    position: relative;
    height: 30px;
    width: 102px;
    padding: 3px 4px 3px 44px;
    font-size: 1.3rem;
}

.gh-branding-settings .input-color::after {
    top: 5px;
    left: 34px;
}

.gh-branding-settings .color-picker-horizontal-divider {
    position: absolute;
    display: block;
    content: "";
    width: 1px;
    top: 0;
    left: 29px;
    bottom: 0;
    background: var(--input-border-color);
}

.gh-branding-settings .input-color input:focus + .color-picker-horizontal-divider {
    top: 2px;
    bottom: 2px;
}

.gh-branding-settings .color-box-container {
    height: 26px;
    width: 26px;
    position: absolute;
    overflow: hidden;
    top: 2px;
    left: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.gh-branding-settings .color-box-container .color-picker {
    position: absolute;
    top: -10px;
    left: -10px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
}

.gh-branding-settings .gh-accent-color .gh-setting-action {
    align-self: flex-start;
    margin-top: 22px;
}

.gh-branding-settings .gh-accent-color .response {
    margin: -8px 0 0;
    font-size: 1.3rem;
}

/* Membership */
.gh-setting-members-header {
    position: relative;
    background: none;
}

.gh-setting-members-canvas::before {
    position: absolute;
    display: block;
    content: "";
    top: -450px;
    right: -250px;
    width: 970px;
    height: 970px;
    border-radius: 50%;
    z-index: 0;
    background: var(--main-color-content-greybg);
    opacity: 0.5;
}

@media (max-width: 1140px) {
    .gh-setting-members-canvas::before {
        display: none;
    }
}

.gh-setting-members-basics {
    display: grid;
    grid-template-columns: auto 460px;
    grid-gap: 32px;
}

@media (max-width: 1320px) {
    .gh-setting-members-basics {
        grid-gap: 0;
    }
}

@media (max-width: 1140px) {
    .gh-setting-members-basics {
        display: flex;
    }
}

.gh-setting-members-basicsform {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gh-setting-members-basicsform .intro {
    margin: 0;
    font-size: 1.6rem;
}

.gh-setting-members-portalcta {
    background: linear-gradient(to left, color-mod(var(--main-color-content-greybg) l(-3%)), var(--main-color-content-greybg));
}

.gh-setting-members-portalcta .gh-expandable-header button {
    margin-left: 16px;
}

@media (max-width: 500px), (min-width: 1140px) and (max-width: 1260px) {
    .gh-setting-members-portalcta .gh-expandable-header {
        flex-direction: column;
        align-items: inherit;
    }

    .gh-setting-members-portalcta .gh-expandable-header button {
        margin-top: 1rem;
        margin-left: 0;
    }
}

.gh-setting-members-portalcta .gh-expandable-description {
    padding-top: 2px;
    line-height: 1.4;
}

.gh-setting-members-access {
    margin-bottom: 30px;
}

.gh-setting-members-portalpreview {
    justify-self: end;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--midgrey);
}

@media (max-width: 1140px) {
    .gh-setting-members-portalpreview {
        display: none;
    }
}

.gh-setting-dropdown {
    margin-top: 1.2rem;
    cursor: pointer;
    background: var(--white);
}

.gh-setting-dropdown[aria-disabled="true"] {
    background: var(--whitegrey-l2);
}

.gh-setting-dropdown[aria-disabled="true"] svg path {
    fill: var(--lightgrey-d1);
}

.gh-setting-dropdown[aria-disabled="true"] .gh-radio-label {
    opacity: .65;
}

.gh-setting-dropdown:focus-visible {
    outline: none;
}

.gh-setting-dropdown .ember-power-select-status-icon {
    right: 24px;
}

.gh-setting-dropdown .gh-setting-dropdown-content {
    display: flex;
    align-items: center;
    margin: 1.6rem 2.4rem 1.6rem 1rem;
}

.gh-setting-dropdown-list {
    margin-top: -1px;
    border-top: 1px solid var(--input-border-color) !important;
}

.gh-setting-dropdown-list .ember-power-select-option {
    padding: 6px 8px;
}

.gh-setting-dropdown-list .gh-setting-dropdown-content {
    display: flex;
    align-items: center;
    margin: 1.4rem 1rem;
}

.gh-setting-dropdown-content svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1.2rem;
}

.gh-setting-richdd-container {
    margin: 36px 0;
}

.gh-default-post-access .ember-power-select-multiple-trigger {
    padding: 8px;
}

.gh-default-post-access .segment-totals {
    display: none;
}

.gh-setting-members-access .ember-power-select-status-icon {
    right: 20px;
}

.gh-setting-members-tierscontainer {
    margin-top: 4vmin;
}

.gh-settings-members-tiersheader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.gh-settings-members-tiersheader .gh-btn-stripe-status {
    margin-bottom: 12px;
}

.gh-settings-members-tiersheader .gh-btn-stripe-status span {
    height: 28px;
    line-height: 28px;
    font-size: 1.25rem;
}

.gh-setting-members-tierscontainer .gh-expandable:not(:first-of-type) {
    margin-top: 20px;
}

.gh-setting-members-portal-mock {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07)
    ;
    width: 420px;
    height: 562px;
    margin-bottom: 32px;
    border-radius: 5px;
    pointer-events: none;
    transition: height 0.17s ease-out;
}

.gh-setting-members-portal-disabled {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px;
    text-align: center;
}

.gh-setting-members-portal-disabled svg {
    width: 44px;
    height: 44px;
}

.gh-setting-members-portal-disabled svg path {
    stroke-width: 1.2px;
}

.gh-setting-members-portal-disabled h4 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--darkgrey);
}

.gh-setting-members-portal-disabled p {
    max-width: 240px;
    font-weight: 400;
}

.gh-setting-members-portal-mock .site-frame {
    border-radius: 5px;
}

.gh-settings-members-pricelabelcont {
    display: flex;
    align-items: baseline;
}

.gh-settings-members-pricelabelcont span {
    margin: 0 4px;
}

.gh-settings-members-pricelabelcont span,
.gh-settings-members-pricelabelcont div {
    display: inline-block;
    margin-bottom: 4px;
}

.gh-settings-members-pricelabelcont .gh-select svg {
    position: unset;
    margin-top: -3px;
}

.gh-settings-members-pricelabelcont .gh-select {
    padding: 0;
    width: 60px;
    height: 16px;
    background: transparent;
    border: none;
    margin-left: -4px;
}

.gh-settings-members-pricelabelcont .gh-select select {
    background: transparent;
    font-size: 1.4rem;
    font-weight: 500;
    border: none;
    height: 16px;
    width: 46px;
    padding: 0;
}

.gh-setting-members-prices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.gh-setting-members-currency {
    position: relative;
}

.gh-setting-members-currencylabel {
    position: absolute;
    display: flex !important;
    align-items: center;
    top: 1px;
    left: 0px;
    background: var(--main-color-content-greybg);
    height: 20px;
    font-weight: 500;
    font-size: 1.4rem;
    color: var(--middarkgrey);
    text-transform: uppercase;
    pointer-events: none;
}

.gh-setting-members-currencylabel span {
    margin-right: 0;
    pointer-events: none;
}


/* Stripe Connect modal */
.fullscreen-modal-stripe-connect {
    max-width: 860px;
}

.fullscreen-modal-stripe-connected {
    max-width: 440px;
}

.fullscreen-modal-stripe-connect .gh-main-section {
    margin: 0 0 -32px;
}

.fullscreen-modal-stripe-connected .gh-main-section {
    margin-bottom: -20px;
}

.fullscreen-modal-stripe-connect:not(.fullscreen-modal-stripe-connected) .modal-footer .gh-btn {
    display: none;
}

.fullscreen-modal-stripe-connect.fullscreen-modal-stripe-connected .modal-header {
    display: none;
}

.gh-members-stripe-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gh-members-stripe-info-header h4 {
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #555ABF;
}

.gh-members-stripe-info {
    border-radius: 0.9rem;
    background: color-mod(#555ABF a(12%));
    padding: 12px;
    width: 380px;
    color: #555ABF;
}

.gh-members-stripe-badge {
    width: 180px;
}

.gh-members-stripe-link,
.gh-members-stripe-link:hover {
    color: #555ABF;
    text-decoration: underline;
}

.gh-members-connectbutton-container {
    margin-right: 4px;
}

.gh-members-connectbutton-container .for-switch {
    line-height: 1em;
}

.gh-members-connectbutton-container .for-switch label {
    width: 36px !important
}

.gh-members-connectbutton-container .for-switch input:checked + .input-toggle-component {
    background: #F1946A;
}

.gh-members-connect-testmodeon {
    color: #F1946A;
}

.gh-members-stripe-connect-token {
    background: var(--whitegrey-l2);
    min-height: unset;
    height: 80px;
    font-family: var(--font-family-mono);
    font-size: 1.3rem;
    resize: none;
}

.gh-members-connect-testmodelabel {
    display: inline-block;
    background: #f8e5b9;
    color: #983705;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1em;
    border-radius: 999px;
    padding: 4px 8px;
}

.gh-members-connect-savecontainer {
    height: 0px;
    overflow-y: hidden;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    margin-top: 16px;
    margin-bottom: 0;
}

.gh-members-connect-savecontainer.expanded {
    margin-bottom: 20px;
}

.gh-members-connect-savecontainer.expanded {
    height: 36px;
    opacity: 1.0;
}

.gh-stripe-connected-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gh-stripe-connected-container .check-circle {
    width: 60px;
    height: 60px;
    color: var(--green);
    margin-top: 20px;
}

.gh-stripe-connected-container .check-circle path {
    stroke-width: 1px;
}

.gh-stripe-connected-container h1 {
    font-size: 2.1rem;
    font-weight: 600;
    letter-spacing: -.1px;
    margin: 20px 0 4px;
}

.gh-stripe-connected-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
}

.gh-stripe-connected-info p {
    margin-bottom: 8px;
}

.gh-btn-stripe-disconnect {
    align-self: flex-start;
    margin-bottom: -34px;
}

.gh-stripe-error-hasactivesub {
    margin: 24px 24px -8px;
    color: var(--red);
}

@media (max-width: 500px) {
    .gh-members-stripe-info-header {
        flex-direction: column;
        align-items: stretch;
    }

    .gh-members-stripe-info-header h4 {
        order: 2;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid var(--whitegrey);
    }

    .gh-members-stripe-badge {
        order: 1;
        /* margin: -10px 0 0 -10px; */
    }

    .gh-members-stripe-info {
        width: 100%;
    }
}

.gh-setting-nossl {
    border-top: 1px solid var(--whitegrey-d1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px -24px -12px;
}

.gh-setting-nossl-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    text-align: center;
    max-width: 520px;
}

.gh-setting-nossl-container svg {
    width: 44px;
    height: 44px;
    margin-bottom: 12px;
}

.gh-setting-nossl-container svg path,
.gh-setting-nossl-container svg rect,
.gh-setting-nossl-container svg circle {
    stroke-width: 1px;
}

.gh-setting-nossl-container h4 {
    font-size: 1.5rem;
    font-weight: 600;
}

.gh-setting-nossl-container p {
    margin: 8px 0 0;
    color: var(--midgrey);
}
