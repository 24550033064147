/*

    POINTER EVENTS

    Base:
      pe = pointer-events

*/

.pe-auto              { pointer-events: auto; }
.pe-none              { pointer-events: none; }
.pe-inherit           { pointer-events: inherit; }
.pe-initial           { pointer-events: initial; }
.pe-unset             { pointer-events: unset; }

/* SVG only events */
.pe-visiblePainted    { pointer-events: visiblePainted; }
.pe-visibleFill       { pointer-events: visibleFill; }
.pe-visibleStroke     { pointer-events: visibleStroke; }
.pe-visible           { pointer-events: visible; }
.pe-painted           { pointer-events: painted; }
.pe-fill              { pointer-events: fill; }
.pe-stroke            { pointer-events: stroke; }
.pe-all               { pointer-events: all; }
