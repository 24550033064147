.gh-billing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: var(--main-bg-color);
}

.gh-billing-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.gh-billing.closed {
    display: none;
}

.gh-billing .close {
    position: absolute;
    top: 19px;
    right: 19px;
    z-index: 9999;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border: none;
}

.gh-billing .billing-frame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    transform: translate3d(0, 0, 0);
}

.gh-billing-close {
    width: calc(50vw - 200px)
}

.gh-billing-close button {
    stroke: var(--midgrey);
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    top: 25px;
}
