.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont,
               'avenir next', avenir,
               'helvetica neue', helvetica,
               ubuntu,
               roboto, noto,
               'segoe ui', arial,
               sans-serif;
}

.serif {
  font-family: georgia,
               serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}


/* Monospaced Typefaces (for code) */

/* From http://cssfontstack.com */
code, .code {
  font-family: monospace,monospace;
}

.courier {
  font-family: 'Courier Next',
               courier,
               monospace;
}


/* Sans-Serif Typefaces */

.whitney {
    font-family: "Whitney SSm A", "Whitney SSm B",
                 sans-serif;
}
