.gh-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--lightgrey-d1);
    padding: 3px 0 8px;
}

.gh-tab {
    padding: 4px 2px;
    margin-right: 28px;
    color: var(--midgrey-d2);
    border-radius: 4px;
}

.gh-tab.selected {
    font-weight: 700;
    color: var(--darkgrey);
    position: relative;
}

.gh-tab.selected::after {
    content: "";
    position: absolute;
    height: 3px;
    background: var(--blue);
    width: 100%;
    bottom: -9px;
    left: 0;
}