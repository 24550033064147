/*

    Base:
        p = padding
        m = margin

    Modifier:
        a = all
        r = right
        b = bottom
        l = left
        t = top

    Value:
        (n) = (n * grid size)

    Media Query Extensions:
        -ns = not-small
        -m  = medium
        -l  = large

 */

:root {
   --grid-size: .4rem;
}

.pa0 {    padding: 0; }
.pa1 {    padding: calc(var(--grid-size) * 1); }
.pa2 {    padding: calc(var(--grid-size) * 2); }
.pa3 {    padding: calc(var(--grid-size) * 3); }
.pa4 {    padding: calc(var(--grid-size) * 4); }
.pa5 {    padding: calc(var(--grid-size) * 5); }
.pa6 {    padding: calc(var(--grid-size) * 6); }
.pa7 {    padding: calc(var(--grid-size) * 7); }
.pa8 {    padding: calc(var(--grid-size) * 8); }
.pa9 {    padding: calc(var(--grid-size) * 9); }
.pa10 {    padding: calc(var(--grid-size) * 10); }
.pa11 {    padding: calc(var(--grid-size) * 11); }
.pa12 {    padding: calc(var(--grid-size) * 12); }
.pa13 {    padding: calc(var(--grid-size) * 13); }
.pa14 {    padding: calc(var(--grid-size) * 14); }
.pa15 {    padding: calc(var(--grid-size) * 15); }
.pa16 {    padding: calc(var(--grid-size) * 16); }
.pa17 {    padding: calc(var(--grid-size) * 17); }
.pa18 {    padding: calc(var(--grid-size) * 18); }
.pa19 {    padding: calc(var(--grid-size) * 19); }
.pa20 {    padding: calc(var(--grid-size) * 20); }
.pa25 {    padding: calc(var(--grid-size) * 25); }
.pa30 {    padding: calc(var(--grid-size) * 30); }
.pa40 {    padding: calc(var(--grid-size) * 40); }
.pa50 {    padding: calc(var(--grid-size) * 50); }

.pr0 {    padding-right: 0; }
.pr1 {    padding-right: calc(var(--grid-size) * 1); }
.pr2 {    padding-right: calc(var(--grid-size) * 2); }
.pr3 {    padding-right: calc(var(--grid-size) * 3); }
.pr4 {    padding-right: calc(var(--grid-size) * 4); }
.pr5 {    padding-right: calc(var(--grid-size) * 5); }
.pr6 {    padding-right: calc(var(--grid-size) * 6); }
.pr7 {    padding-right: calc(var(--grid-size) * 7); }
.pr8 {    padding-right: calc(var(--grid-size) * 8); }
.pr9 {    padding-right: calc(var(--grid-size) * 9); }
.pr10 {    padding-right: calc(var(--grid-size) * 10); }
.pr11 {    padding-right: calc(var(--grid-size) * 11); }
.pr12 {    padding-right: calc(var(--grid-size) * 12); }
.pr13 {    padding-right: calc(var(--grid-size) * 13); }
.pr14 {    padding-right: calc(var(--grid-size) * 14); }
.pr15 {    padding-right: calc(var(--grid-size) * 15); }
.pr16 {    padding-right: calc(var(--grid-size) * 16); }
.pr17 {    padding-right: calc(var(--grid-size) * 17); }
.pr18 {    padding-right: calc(var(--grid-size) * 18); }
.pr19 {    padding-right: calc(var(--grid-size) * 19); }
.pr20 {    padding-right: calc(var(--grid-size) * 20); }
.pr25 {    padding-right: calc(var(--grid-size) * 25); }
.pr30 {    padding-right: calc(var(--grid-size) * 30); }
.pr40 {    padding-right: calc(var(--grid-size) * 40); }
.pr50 {    padding-right: calc(var(--grid-size) * 50); }

.pb0 {    padding-bottom: 0; }
.pb1 {    padding-bottom: calc(var(--grid-size) * 1); }
.pb2 {    padding-bottom: calc(var(--grid-size) * 2); }
.pb3 {    padding-bottom: calc(var(--grid-size) * 3); }
.pb4 {    padding-bottom: calc(var(--grid-size) * 4); }
.pb5 {    padding-bottom: calc(var(--grid-size) * 5); }
.pb6 {    padding-bottom: calc(var(--grid-size) * 6); }
.pb7 {    padding-bottom: calc(var(--grid-size) * 7); }
.pb8 {    padding-bottom: calc(var(--grid-size) * 8); }
.pb9 {    padding-bottom: calc(var(--grid-size) * 9); }
.pb10 {    padding-bottom: calc(var(--grid-size) * 10); }
.pb11 {    padding-bottom: calc(var(--grid-size) * 11); }
.pb12 {    padding-bottom: calc(var(--grid-size) * 12); }
.pb13 {    padding-bottom: calc(var(--grid-size) * 13); }
.pb14 {    padding-bottom: calc(var(--grid-size) * 14); }
.pb15 {    padding-bottom: calc(var(--grid-size) * 15); }
.pb16 {    padding-bottom: calc(var(--grid-size) * 16); }
.pb17 {    padding-bottom: calc(var(--grid-size) * 17); }
.pb18 {    padding-bottom: calc(var(--grid-size) * 18); }
.pb19 {    padding-bottom: calc(var(--grid-size) * 19); }
.pb20 {    padding-bottom: calc(var(--grid-size) * 20); }
.pb25 {    padding-bottom: calc(var(--grid-size) * 25); }
.pb30 {    padding-bottom: calc(var(--grid-size) * 30); }
.pb40 {    padding-bottom: calc(var(--grid-size) * 40); }
.pb50 {    padding-bottom: calc(var(--grid-size) * 50); }

.pl0 {    padding-left: 0; }
.pl1 {    padding-left: calc(var(--grid-size) * 1); }
.pl2 {    padding-left: calc(var(--grid-size) * 2); }
.pl3 {    padding-left: calc(var(--grid-size) * 3); }
.pl4 {    padding-left: calc(var(--grid-size) * 4); }
.pl5 {    padding-left: calc(var(--grid-size) * 5); }
.pl6 {    padding-left: calc(var(--grid-size) * 6); }
.pl7 {    padding-left: calc(var(--grid-size) * 7); }
.pl8 {    padding-left: calc(var(--grid-size) * 8); }
.pl9 {    padding-left: calc(var(--grid-size) * 9); }
.pl10 {    padding-left: calc(var(--grid-size) * 10); }
.pl11 {    padding-left: calc(var(--grid-size) * 11); }
.pl12 {    padding-left: calc(var(--grid-size) * 12); }
.pl13 {    padding-left: calc(var(--grid-size) * 13); }
.pl14 {    padding-left: calc(var(--grid-size) * 14); }
.pl15 {    padding-left: calc(var(--grid-size) * 15); }
.pl16 {    padding-left: calc(var(--grid-size) * 16); }
.pl17 {    padding-left: calc(var(--grid-size) * 17); }
.pl18 {    padding-left: calc(var(--grid-size) * 18); }
.pl19 {    padding-left: calc(var(--grid-size) * 19); }
.pl20 {    padding-left: calc(var(--grid-size) * 20); }
.pl25 {    padding-left: calc(var(--grid-size) * 25); }
.pl30 {    padding-left: calc(var(--grid-size) * 30); }
.pl40 {    padding-left: calc(var(--grid-size) * 40); }
.pl50 {    padding-left: calc(var(--grid-size) * 50); }

.pt0 {    padding-top: 0; }
.pt1 {    padding-top: calc(var(--grid-size) * 1); }
.pt2 {    padding-top: calc(var(--grid-size) * 2); }
.pt3 {    padding-top: calc(var(--grid-size) * 3); }
.pt4 {    padding-top: calc(var(--grid-size) * 4); }
.pt5 {    padding-top: calc(var(--grid-size) * 5); }
.pt6 {    padding-top: calc(var(--grid-size) * 6); }
.pt7 {    padding-top: calc(var(--grid-size) * 7); }
.pt8 {    padding-top: calc(var(--grid-size) * 8); }
.pt9 {    padding-top: calc(var(--grid-size) * 9); }
.pt10 {    padding-top: calc(var(--grid-size) * 10); }
.pt11 {    padding-top: calc(var(--grid-size) * 11); }
.pt12 {    padding-top: calc(var(--grid-size) * 12); }
.pt13 {    padding-top: calc(var(--grid-size) * 13); }
.pt14 {    padding-top: calc(var(--grid-size) * 14); }
.pt15 {    padding-top: calc(var(--grid-size) * 15); }
.pt16 {    padding-top: calc(var(--grid-size) * 16); }
.pt17 {    padding-top: calc(var(--grid-size) * 17); }
.pt18 {    padding-top: calc(var(--grid-size) * 18); }
.pt19 {    padding-top: calc(var(--grid-size) * 19); }
.pt20 {    padding-top: calc(var(--grid-size) * 20); }
.pt25 {    padding-top: calc(var(--grid-size) * 25); }
.pt30 {    padding-top: calc(var(--grid-size) * 30); }
.pt40 {    padding-top: calc(var(--grid-size) * 40); }
.pt50 {    padding-top: calc(var(--grid-size) * 50); }

.ma0 {    margin: 0; }
.ma1 {    margin: calc(var(--grid-size) * 1); }
.ma2 {    margin: calc(var(--grid-size) * 2); }
.ma3 {    margin: calc(var(--grid-size) * 3); }
.ma4 {    margin: calc(var(--grid-size) * 4); }
.ma5 {    margin: calc(var(--grid-size) * 5); }
.ma6 {    margin: calc(var(--grid-size) * 6); }
.ma7 {    margin: calc(var(--grid-size) * 7); }
.ma8 {    margin: calc(var(--grid-size) * 8); }
.ma9 {    margin: calc(var(--grid-size) * 9); }
.ma10 {    margin: calc(var(--grid-size) * 10); }
.ma11 {    margin: calc(var(--grid-size) * 11); }
.ma12 {    margin: calc(var(--grid-size) * 12); }
.ma13 {    margin: calc(var(--grid-size) * 13); }
.ma14 {    margin: calc(var(--grid-size) * 14); }
.ma15 {    margin: calc(var(--grid-size) * 15); }
.ma16 {    margin: calc(var(--grid-size) * 16); }
.ma17 {    margin: calc(var(--grid-size) * 17); }
.ma18 {    margin: calc(var(--grid-size) * 18); }
.ma19 {    margin: calc(var(--grid-size) * 19); }
.ma20 {    margin: calc(var(--grid-size) * 20); }
.ma25 {    margin: calc(var(--grid-size) * 25); }
.ma30 {    margin: calc(var(--grid-size) * 30); }
.ma40 {    margin: calc(var(--grid-size) * 40); }
.ma50 {    margin: calc(var(--grid-size) * 50); }

.mr0 {    margin-right: 0; }
.mr1 {    margin-right: calc(var(--grid-size) * 1); }
.mr2 {    margin-right: calc(var(--grid-size) * 2); }
.mr3 {    margin-right: calc(var(--grid-size) * 3); }
.mr4 {    margin-right: calc(var(--grid-size) * 4); }
.mr5 {    margin-right: calc(var(--grid-size) * 5); }
.mr6 {    margin-right: calc(var(--grid-size) * 6); }
.mr7 {    margin-right: calc(var(--grid-size) * 7); }
.mr8 {    margin-right: calc(var(--grid-size) * 8); }
.mr9 {    margin-right: calc(var(--grid-size) * 9); }
.mr10 {    margin-right: calc(var(--grid-size) * 10); }
.mr11 {    margin-right: calc(var(--grid-size) * 11); }
.mr12 {    margin-right: calc(var(--grid-size) * 12); }
.mr13 {    margin-right: calc(var(--grid-size) * 13); }
.mr14 {    margin-right: calc(var(--grid-size) * 14); }
.mr15 {    margin-right: calc(var(--grid-size) * 15); }
.mr16 {    margin-right: calc(var(--grid-size) * 16); }
.mr17 {    margin-right: calc(var(--grid-size) * 17); }
.mr18 {    margin-right: calc(var(--grid-size) * 18); }
.mr19 {    margin-right: calc(var(--grid-size) * 19); }
.mr20 {    margin-right: calc(var(--grid-size) * 20); }
.mr25 {    margin-right: calc(var(--grid-size) * 25); }
.mr30 {    margin-right: calc(var(--grid-size) * 30); }
.mr40 {    margin-right: calc(var(--grid-size) * 40); }
.mr50 {    margin-right: calc(var(--grid-size) * 50); }

.mb0 {    margin-bottom: 0; }
.mb1 {    margin-bottom: calc(var(--grid-size) * 1); }
.mb2 {    margin-bottom: calc(var(--grid-size) * 2); }
.mb3 {    margin-bottom: calc(var(--grid-size) * 3); }
.mb4 {    margin-bottom: calc(var(--grid-size) * 4); }
.mb5 {    margin-bottom: calc(var(--grid-size) * 5); }
.mb6 {    margin-bottom: calc(var(--grid-size) * 6); }
.mb7 {    margin-bottom: calc(var(--grid-size) * 7); }
.mb8 {    margin-bottom: calc(var(--grid-size) * 8); }
.mb9 {    margin-bottom: calc(var(--grid-size) * 9); }
.mb10 {    margin-bottom: calc(var(--grid-size) * 10); }
.mb11 {    margin-bottom: calc(var(--grid-size) * 11); }
.mb12 {    margin-bottom: calc(var(--grid-size) * 12); }
.mb13 {    margin-bottom: calc(var(--grid-size) * 13); }
.mb14 {    margin-bottom: calc(var(--grid-size) * 14); }
.mb15 {    margin-bottom: calc(var(--grid-size) * 15); }
.mb16 {    margin-bottom: calc(var(--grid-size) * 16); }
.mb17 {    margin-bottom: calc(var(--grid-size) * 17); }
.mb18 {    margin-bottom: calc(var(--grid-size) * 18); }
.mb19 {    margin-bottom: calc(var(--grid-size) * 19); }
.mb20 {    margin-bottom: calc(var(--grid-size) * 20); }
.mb25 {    margin-bottom: calc(var(--grid-size) * 25); }
.mb30 {    margin-bottom: calc(var(--grid-size) * 30); }
.mb40 {    margin-bottom: calc(var(--grid-size) * 40); }
.mb50 {    margin-bottom: calc(var(--grid-size) * 50); }

.ml0 {    margin-left: 0; }
.ml1 {    margin-left: calc(var(--grid-size) * 1); }
.ml2 {    margin-left: calc(var(--grid-size) * 2); }
.ml3 {    margin-left: calc(var(--grid-size) * 3); }
.ml4 {    margin-left: calc(var(--grid-size) * 4); }
.ml5 {    margin-left: calc(var(--grid-size) * 5); }
.ml6 {    margin-left: calc(var(--grid-size) * 6); }
.ml7 {    margin-left: calc(var(--grid-size) * 7); }
.ml8 {    margin-left: calc(var(--grid-size) * 8); }
.ml9 {    margin-left: calc(var(--grid-size) * 9); }
.ml10 {    margin-left: calc(var(--grid-size) * 10); }
.ml11 {    margin-left: calc(var(--grid-size) * 11); }
.ml12 {    margin-left: calc(var(--grid-size) * 12); }
.ml13 {    margin-left: calc(var(--grid-size) * 13); }
.ml14 {    margin-left: calc(var(--grid-size) * 14); }
.ml15 {    margin-left: calc(var(--grid-size) * 15); }
.ml16 {    margin-left: calc(var(--grid-size) * 16); }
.ml17 {    margin-left: calc(var(--grid-size) * 17); }
.ml18 {    margin-left: calc(var(--grid-size) * 18); }
.ml19 {    margin-left: calc(var(--grid-size) * 19); }
.ml20 {    margin-left: calc(var(--grid-size) * 20); }
.ml25 {    margin-left: calc(var(--grid-size) * 25); }
.ml30 {    margin-left: calc(var(--grid-size) * 30); }
.ml40 {    margin-left: calc(var(--grid-size) * 40); }
.ml50 {    margin-left: calc(var(--grid-size) * 50); }

.mt0 {    margin-top: 0; }
.mt1 {    margin-top: calc(var(--grid-size) * 1); }
.mt2 {    margin-top: calc(var(--grid-size) * 2); }
.mt3 {    margin-top: calc(var(--grid-size) * 3); }
.mt4 {    margin-top: calc(var(--grid-size) * 4); }
.mt5 {    margin-top: calc(var(--grid-size) * 5); }
.mt6 {    margin-top: calc(var(--grid-size) * 6); }
.mt7 {    margin-top: calc(var(--grid-size) * 7); }
.mt8 {    margin-top: calc(var(--grid-size) * 8); }
.mt9 {    margin-top: calc(var(--grid-size) * 9); }
.mt10 {    margin-top: calc(var(--grid-size) * 10); }
.mt11 {    margin-top: calc(var(--grid-size) * 11); }
.mt12 {    margin-top: calc(var(--grid-size) * 12); }
.mt13 {    margin-top: calc(var(--grid-size) * 13); }
.mt14 {    margin-top: calc(var(--grid-size) * 14); }
.mt15 {    margin-top: calc(var(--grid-size) * 15); }
.mt16 {    margin-top: calc(var(--grid-size) * 16); }
.mt17 {    margin-top: calc(var(--grid-size) * 17); }
.mt18 {    margin-top: calc(var(--grid-size) * 18); }
.mt19 {    margin-top: calc(var(--grid-size) * 19); }
.mt20 {    margin-top: calc(var(--grid-size) * 20); }
.mt25 {    margin-top: calc(var(--grid-size) * 25); }
.mt30 {    margin-top: calc(var(--grid-size) * 30); }
.mt40 {    margin-top: calc(var(--grid-size) * 40); }
.mt50 {    margin-top: calc(var(--grid-size) * 50); }

@media (--breakpoint-not-small) {
    .pa0-ns {    padding: 0; }
    .pa1-ns {    padding: calc(var(--grid-size) * 1); }
    .pa2-ns {    padding: calc(var(--grid-size) * 2); }
    .pa3-ns {    padding: calc(var(--grid-size) * 3); }
    .pa4-ns {    padding: calc(var(--grid-size) * 4); }
    .pa5-ns {    padding: calc(var(--grid-size) * 5); }
    .pa6-ns {    padding: calc(var(--grid-size) * 6); }
    .pa7-ns {    padding: calc(var(--grid-size) * 7); }
    .pa8-ns {    padding: calc(var(--grid-size) * 8); }
    .pa9-ns {    padding: calc(var(--grid-size) * 9); }
    .pa10-ns {    padding: calc(var(--grid-size) * 10); }
    .pa11-ns {    padding: calc(var(--grid-size) * 11); }
    .pa12-ns {    padding: calc(var(--grid-size) * 12); }
    .pa13-ns {    padding: calc(var(--grid-size) * 13); }
    .pa14-ns {    padding: calc(var(--grid-size) * 14); }
    .pa15-ns {    padding: calc(var(--grid-size) * 15); }
    .pa16-ns {    padding: calc(var(--grid-size) * 16); }
    .pa17-ns {    padding: calc(var(--grid-size) * 17); }
    .pa18-ns {    padding: calc(var(--grid-size) * 18); }
    .pa19-ns {    padding: calc(var(--grid-size) * 19); }
    .pa20-ns {    padding: calc(var(--grid-size) * 20); }
    .pa25-ns {    padding: calc(var(--grid-size) * 25); }
    .pa30-ns {    padding: calc(var(--grid-size) * 30); }
    .pa40-ns {    padding: calc(var(--grid-size) * 40); }
    .pa50-ns {    padding: calc(var(--grid-size) * 50); }

    .pr0-ns {    padding-right: 0; }
    .pr1-ns {    padding-right: calc(var(--grid-size) * 1); }
    .pr2-ns {    padding-right: calc(var(--grid-size) * 2); }
    .pr3-ns {    padding-right: calc(var(--grid-size) * 3); }
    .pr4-ns {    padding-right: calc(var(--grid-size) * 4); }
    .pr5-ns {    padding-right: calc(var(--grid-size) * 5); }
    .pr6-ns {    padding-right: calc(var(--grid-size) * 6); }
    .pr7-ns {    padding-right: calc(var(--grid-size) * 7); }
    .pr8-ns {    padding-right: calc(var(--grid-size) * 8); }
    .pr9-ns {    padding-right: calc(var(--grid-size) * 9); }
    .pr10-ns {    padding-right: calc(var(--grid-size) * 10); }
    .pr11-ns {    padding-right: calc(var(--grid-size) * 11); }
    .pr12-ns {    padding-right: calc(var(--grid-size) * 12); }
    .pr13-ns {    padding-right: calc(var(--grid-size) * 13); }
    .pr14-ns {    padding-right: calc(var(--grid-size) * 14); }
    .pr15-ns {    padding-right: calc(var(--grid-size) * 15); }
    .pr16-ns {    padding-right: calc(var(--grid-size) * 16); }
    .pr17-ns {    padding-right: calc(var(--grid-size) * 17); }
    .pr18-ns {    padding-right: calc(var(--grid-size) * 18); }
    .pr19-ns {    padding-right: calc(var(--grid-size) * 19); }
    .pr20-ns {    padding-right: calc(var(--grid-size) * 20); }
    .pr25-ns {    padding-right: calc(var(--grid-size) * 25); }
    .pr30-ns {    padding-right: calc(var(--grid-size) * 30); }
    .pr40-ns {    padding-right: calc(var(--grid-size) * 40); }
    .pr50-ns {    padding-right: calc(var(--grid-size) * 50); }

    .pb0-ns {    padding-bottom: 0; }
    .pb1-ns {    padding-bottom: calc(var(--grid-size) * 1); }
    .pb2-ns {    padding-bottom: calc(var(--grid-size) * 2); }
    .pb3-ns {    padding-bottom: calc(var(--grid-size) * 3); }
    .pb4-ns {    padding-bottom: calc(var(--grid-size) * 4); }
    .pb5-ns {    padding-bottom: calc(var(--grid-size) * 5); }
    .pb6-ns {    padding-bottom: calc(var(--grid-size) * 6); }
    .pb7-ns {    padding-bottom: calc(var(--grid-size) * 7); }
    .pb8-ns {    padding-bottom: calc(var(--grid-size) * 8); }
    .pb9-ns {    padding-bottom: calc(var(--grid-size) * 9); }
    .pb10-ns {    padding-bottom: calc(var(--grid-size) * 10); }
    .pb11-ns {    padding-bottom: calc(var(--grid-size) * 11); }
    .pb12-ns {    padding-bottom: calc(var(--grid-size) * 12); }
    .pb13-ns {    padding-bottom: calc(var(--grid-size) * 13); }
    .pb14-ns {    padding-bottom: calc(var(--grid-size) * 14); }
    .pb15-ns {    padding-bottom: calc(var(--grid-size) * 15); }
    .pb16-ns {    padding-bottom: calc(var(--grid-size) * 16); }
    .pb17-ns {    padding-bottom: calc(var(--grid-size) * 17); }
    .pb18-ns {    padding-bottom: calc(var(--grid-size) * 18); }
    .pb19-ns {    padding-bottom: calc(var(--grid-size) * 19); }
    .pb20-ns {    padding-bottom: calc(var(--grid-size) * 20); }
    .pb25-ns {    padding-bottom: calc(var(--grid-size) * 25); }
    .pb30-ns {    padding-bottom: calc(var(--grid-size) * 30); }
    .pb40-ns {    padding-bottom: calc(var(--grid-size) * 40); }
    .pb50-ns {    padding-bottom: calc(var(--grid-size) * 50); }

    .pl0-ns {    padding-left: 0; }
    .pl1-ns {    padding-left: calc(var(--grid-size) * 1); }
    .pl2-ns {    padding-left: calc(var(--grid-size) * 2); }
    .pl3-ns {    padding-left: calc(var(--grid-size) * 3); }
    .pl4-ns {    padding-left: calc(var(--grid-size) * 4); }
    .pl5-ns {    padding-left: calc(var(--grid-size) * 5); }
    .pl6-ns {    padding-left: calc(var(--grid-size) * 6); }
    .pl7-ns {    padding-left: calc(var(--grid-size) * 7); }
    .pl8-ns {    padding-left: calc(var(--grid-size) * 8); }
    .pl9-ns {    padding-left: calc(var(--grid-size) * 9); }
    .pl10-ns {    padding-left: calc(var(--grid-size) * 10); }
    .pl11-ns {    padding-left: calc(var(--grid-size) * 11); }
    .pl12-ns {    padding-left: calc(var(--grid-size) * 12); }
    .pl13-ns {    padding-left: calc(var(--grid-size) * 13); }
    .pl14-ns {    padding-left: calc(var(--grid-size) * 14); }
    .pl15-ns {    padding-left: calc(var(--grid-size) * 15); }
    .pl16-ns {    padding-left: calc(var(--grid-size) * 16); }
    .pl17-ns {    padding-left: calc(var(--grid-size) * 17); }
    .pl18-ns {    padding-left: calc(var(--grid-size) * 18); }
    .pl19-ns {    padding-left: calc(var(--grid-size) * 19); }
    .pl20-ns {    padding-left: calc(var(--grid-size) * 20); }
    .pl25-ns {    padding-left: calc(var(--grid-size) * 25); }
    .pl30-ns {    padding-left: calc(var(--grid-size) * 30); }
    .pl40-ns {    padding-left: calc(var(--grid-size) * 40); }
    .pl50-ns {    padding-left: calc(var(--grid-size) * 50); }

    .pt0-ns {    padding-top: 0; }
    .pt1-ns {    padding-top: calc(var(--grid-size) * 1); }
    .pt2-ns {    padding-top: calc(var(--grid-size) * 2); }
    .pt3-ns {    padding-top: calc(var(--grid-size) * 3); }
    .pt4-ns {    padding-top: calc(var(--grid-size) * 4); }
    .pt5-ns {    padding-top: calc(var(--grid-size) * 5); }
    .pt6-ns {    padding-top: calc(var(--grid-size) * 6); }
    .pt7-ns {    padding-top: calc(var(--grid-size) * 7); }
    .pt8-ns {    padding-top: calc(var(--grid-size) * 8); }
    .pt9-ns {    padding-top: calc(var(--grid-size) * 9); }
    .pt10-ns {    padding-top: calc(var(--grid-size) * 10); }
    .pt11-ns {    padding-top: calc(var(--grid-size) * 11); }
    .pt12-ns {    padding-top: calc(var(--grid-size) * 12); }
    .pt13-ns {    padding-top: calc(var(--grid-size) * 13); }
    .pt14-ns {    padding-top: calc(var(--grid-size) * 14); }
    .pt15-ns {    padding-top: calc(var(--grid-size) * 15); }
    .pt16-ns {    padding-top: calc(var(--grid-size) * 16); }
    .pt17-ns {    padding-top: calc(var(--grid-size) * 17); }
    .pt18-ns {    padding-top: calc(var(--grid-size) * 18); }
    .pt19-ns {    padding-top: calc(var(--grid-size) * 19); }
    .pt20-ns {    padding-top: calc(var(--grid-size) * 20); }
    .pt25-ns {    padding-top: calc(var(--grid-size) * 25); }
    .pt30-ns {    padding-top: calc(var(--grid-size) * 30); }
    .pt40-ns {    padding-top: calc(var(--grid-size) * 40); }
    .pt50-ns {    padding-top: calc(var(--grid-size) * 50); }

    .ma0-ns {    margin: 0; }
    .ma1-ns {    margin: calc(var(--grid-size) * 1); }
    .ma2-ns {    margin: calc(var(--grid-size) * 2); }
    .ma3-ns {    margin: calc(var(--grid-size) * 3); }
    .ma4-ns {    margin: calc(var(--grid-size) * 4); }
    .ma5-ns {    margin: calc(var(--grid-size) * 5); }
    .ma6-ns {    margin: calc(var(--grid-size) * 6); }
    .ma7-ns {    margin: calc(var(--grid-size) * 7); }
    .ma8-ns {    margin: calc(var(--grid-size) * 8); }
    .ma9-ns {    margin: calc(var(--grid-size) * 9); }
    .ma10-ns {    margin: calc(var(--grid-size) * 10); }
    .ma11-ns {    margin: calc(var(--grid-size) * 11); }
    .ma12-ns {    margin: calc(var(--grid-size) * 12); }
    .ma13-ns {    margin: calc(var(--grid-size) * 13); }
    .ma14-ns {    margin: calc(var(--grid-size) * 14); }
    .ma15-ns {    margin: calc(var(--grid-size) * 15); }
    .ma16-ns {    margin: calc(var(--grid-size) * 16); }
    .ma17-ns {    margin: calc(var(--grid-size) * 17); }
    .ma18-ns {    margin: calc(var(--grid-size) * 18); }
    .ma19-ns {    margin: calc(var(--grid-size) * 19); }
    .ma20-ns {    margin: calc(var(--grid-size) * 20); }
    .ma25-ns {    margin: calc(var(--grid-size) * 25); }
    .ma30-ns {    margin: calc(var(--grid-size) * 30); }
    .ma40-ns {    margin: calc(var(--grid-size) * 40); }
    .ma50-ns {    margin: calc(var(--grid-size) * 50); }

    .mr0-ns {    margin-right: 0; }
    .mr1-ns {    margin-right: calc(var(--grid-size) * 1); }
    .mr2-ns {    margin-right: calc(var(--grid-size) * 2); }
    .mr3-ns {    margin-right: calc(var(--grid-size) * 3); }
    .mr4-ns {    margin-right: calc(var(--grid-size) * 4); }
    .mr5-ns {    margin-right: calc(var(--grid-size) * 5); }
    .mr6-ns {    margin-right: calc(var(--grid-size) * 6); }
    .mr7-ns {    margin-right: calc(var(--grid-size) * 7); }
    .mr8-ns {    margin-right: calc(var(--grid-size) * 8); }
    .mr9-ns {    margin-right: calc(var(--grid-size) * 9); }
    .mr10-ns {    margin-right: calc(var(--grid-size) * 10); }
    .mr11-ns {    margin-right: calc(var(--grid-size) * 11); }
    .mr12-ns {    margin-right: calc(var(--grid-size) * 12); }
    .mr13-ns {    margin-right: calc(var(--grid-size) * 13); }
    .mr14-ns {    margin-right: calc(var(--grid-size) * 14); }
    .mr15-ns {    margin-right: calc(var(--grid-size) * 15); }
    .mr16-ns {    margin-right: calc(var(--grid-size) * 16); }
    .mr17-ns {    margin-right: calc(var(--grid-size) * 17); }
    .mr18-ns {    margin-right: calc(var(--grid-size) * 18); }
    .mr19-ns {    margin-right: calc(var(--grid-size) * 19); }
    .mr20-ns {    margin-right: calc(var(--grid-size) * 20); }
    .mr25-ns {    margin-right: calc(var(--grid-size) * 25); }
    .mr30-ns {    margin-right: calc(var(--grid-size) * 30); }
    .mr40-ns {    margin-right: calc(var(--grid-size) * 40); }
    .mr50-ns {    margin-right: calc(var(--grid-size) * 50); }

    .mb0-ns {    margin-bottom: 0; }
    .mb1-ns {    margin-bottom: calc(var(--grid-size) * 1); }
    .mb2-ns {    margin-bottom: calc(var(--grid-size) * 2); }
    .mb3-ns {    margin-bottom: calc(var(--grid-size) * 3); }
    .mb4-ns {    margin-bottom: calc(var(--grid-size) * 4); }
    .mb5-ns {    margin-bottom: calc(var(--grid-size) * 5); }
    .mb6-ns {    margin-bottom: calc(var(--grid-size) * 6); }
    .mb7-ns {    margin-bottom: calc(var(--grid-size) * 7); }
    .mb8-ns {    margin-bottom: calc(var(--grid-size) * 8); }
    .mb9-ns {    margin-bottom: calc(var(--grid-size) * 9); }
    .mb10-ns {    margin-bottom: calc(var(--grid-size) * 10); }
    .mb11-ns {    margin-bottom: calc(var(--grid-size) * 11); }
    .mb12-ns {    margin-bottom: calc(var(--grid-size) * 12); }
    .mb13-ns {    margin-bottom: calc(var(--grid-size) * 13); }
    .mb14-ns {    margin-bottom: calc(var(--grid-size) * 14); }
    .mb15-ns {    margin-bottom: calc(var(--grid-size) * 15); }
    .mb16-ns {    margin-bottom: calc(var(--grid-size) * 16); }
    .mb17-ns {    margin-bottom: calc(var(--grid-size) * 17); }
    .mb18-ns {    margin-bottom: calc(var(--grid-size) * 18); }
    .mb19-ns {    margin-bottom: calc(var(--grid-size) * 19); }
    .mb20-ns {    margin-bottom: calc(var(--grid-size) * 20); }
    .mb25-ns {    margin-bottom: calc(var(--grid-size) * 25); }
    .mb30-ns {    margin-bottom: calc(var(--grid-size) * 30); }
    .mb40-ns {    margin-bottom: calc(var(--grid-size) * 40); }
    .mb50-ns {    margin-bottom: calc(var(--grid-size) * 50); }

    .ml0-ns {    margin-left: 0; }
    .ml1-ns {    margin-left: calc(var(--grid-size) * 1); }
    .ml2-ns {    margin-left: calc(var(--grid-size) * 2); }
    .ml3-ns {    margin-left: calc(var(--grid-size) * 3); }
    .ml4-ns {    margin-left: calc(var(--grid-size) * 4); }
    .ml5-ns {    margin-left: calc(var(--grid-size) * 5); }
    .ml6-ns {    margin-left: calc(var(--grid-size) * 6); }
    .ml7-ns {    margin-left: calc(var(--grid-size) * 7); }
    .ml8-ns {    margin-left: calc(var(--grid-size) * 8); }
    .ml9-ns {    margin-left: calc(var(--grid-size) * 9); }
    .ml10-ns {    margin-left: calc(var(--grid-size) * 10); }
    .ml11-ns {    margin-left: calc(var(--grid-size) * 11); }
    .ml12-ns {    margin-left: calc(var(--grid-size) * 12); }
    .ml13-ns {    margin-left: calc(var(--grid-size) * 13); }
    .ml14-ns {    margin-left: calc(var(--grid-size) * 14); }
    .ml15-ns {    margin-left: calc(var(--grid-size) * 15); }
    .ml16-ns {    margin-left: calc(var(--grid-size) * 16); }
    .ml17-ns {    margin-left: calc(var(--grid-size) * 17); }
    .ml18-ns {    margin-left: calc(var(--grid-size) * 18); }
    .ml19-ns {    margin-left: calc(var(--grid-size) * 19); }
    .ml20-ns {    margin-left: calc(var(--grid-size) * 20); }
    .ml25-ns {    margin-left: calc(var(--grid-size) * 25); }
    .ml30-ns {    margin-left: calc(var(--grid-size) * 30); }
    .ml40-ns {    margin-left: calc(var(--grid-size) * 40); }
    .ml50-ns {    margin-left: calc(var(--grid-size) * 50); }

    .mt0-ns {    margin-top: 0; }
    .mt1-ns {    margin-top: calc(var(--grid-size) * 1); }
    .mt2-ns {    margin-top: calc(var(--grid-size) * 2); }
    .mt3-ns {    margin-top: calc(var(--grid-size) * 3); }
    .mt4-ns {    margin-top: calc(var(--grid-size) * 4); }
    .mt5-ns {    margin-top: calc(var(--grid-size) * 5); }
    .mt6-ns {    margin-top: calc(var(--grid-size) * 6); }
    .mt7-ns {    margin-top: calc(var(--grid-size) * 7); }
    .mt8-ns {    margin-top: calc(var(--grid-size) * 8); }
    .mt9-ns {    margin-top: calc(var(--grid-size) * 9); }
    .mt10-ns {    margin-top: calc(var(--grid-size) * 10); }
    .mt11-ns {    margin-top: calc(var(--grid-size) * 11); }
    .mt12-ns {    margin-top: calc(var(--grid-size) * 12); }
    .mt13-ns {    margin-top: calc(var(--grid-size) * 13); }
    .mt14-ns {    margin-top: calc(var(--grid-size) * 14); }
    .mt15-ns {    margin-top: calc(var(--grid-size) * 15); }
    .mt16-ns {    margin-top: calc(var(--grid-size) * 16); }
    .mt17-ns {    margin-top: calc(var(--grid-size) * 17); }
    .mt18-ns {    margin-top: calc(var(--grid-size) * 18); }
    .mt19-ns {    margin-top: calc(var(--grid-size) * 19); }
    .mt20-ns {    margin-top: calc(var(--grid-size) * 20); }
    .mt25-ns {    margin-top: calc(var(--grid-size) * 25); }
    .mt30-ns {    margin-top: calc(var(--grid-size) * 30); }
    .mt40-ns {    margin-top: calc(var(--grid-size) * 40); }
    .mt50-ns {    margin-top: calc(var(--grid-size) * 50); }
}

@media (--breakpoint-medium) {
    .pa0-m {    padding: 0; }
    .pa1-m {    padding: calc(var(--grid-size) * 1); }
    .pa2-m {    padding: calc(var(--grid-size) * 2); }
    .pa3-m {    padding: calc(var(--grid-size) * 3); }
    .pa4-m {    padding: calc(var(--grid-size) * 4); }
    .pa5-m {    padding: calc(var(--grid-size) * 5); }
    .pa6-m {    padding: calc(var(--grid-size) * 6); }
    .pa7-m {    padding: calc(var(--grid-size) * 7); }
    .pa8-m {    padding: calc(var(--grid-size) * 8); }
    .pa9-m {    padding: calc(var(--grid-size) * 9); }
    .pa10-m {    padding: calc(var(--grid-size) * 10); }
    .pa11-m {    padding: calc(var(--grid-size) * 11); }
    .pa12-m {    padding: calc(var(--grid-size) * 12); }
    .pa13-m {    padding: calc(var(--grid-size) * 13); }
    .pa14-m {    padding: calc(var(--grid-size) * 14); }
    .pa15-m {    padding: calc(var(--grid-size) * 15); }
    .pa16-m {    padding: calc(var(--grid-size) * 16); }
    .pa17-m {    padding: calc(var(--grid-size) * 17); }
    .pa18-m {    padding: calc(var(--grid-size) * 18); }
    .pa19-m {    padding: calc(var(--grid-size) * 19); }
    .pa20-m {    padding: calc(var(--grid-size) * 20); }
    .pa25-m {    padding: calc(var(--grid-size) * 25); }
    .pa30-m {    padding: calc(var(--grid-size) * 30); }
    .pa40-m {    padding: calc(var(--grid-size) * 40); }
    .pa50-m {    padding: calc(var(--grid-size) * 50); }

    .pr0-m {    padding-right: 0; }
    .pr1-m {    padding-right: calc(var(--grid-size) * 1); }
    .pr2-m {    padding-right: calc(var(--grid-size) * 2); }
    .pr3-m {    padding-right: calc(var(--grid-size) * 3); }
    .pr4-m {    padding-right: calc(var(--grid-size) * 4); }
    .pr5-m {    padding-right: calc(var(--grid-size) * 5); }
    .pr6-m {    padding-right: calc(var(--grid-size) * 6); }
    .pr7-m {    padding-right: calc(var(--grid-size) * 7); }
    .pr8-m {    padding-right: calc(var(--grid-size) * 8); }
    .pr9-m {    padding-right: calc(var(--grid-size) * 9); }
    .pr10-m {    padding-right: calc(var(--grid-size) * 10); }
    .pr11-m {    padding-right: calc(var(--grid-size) * 11); }
    .pr12-m {    padding-right: calc(var(--grid-size) * 12); }
    .pr13-m {    padding-right: calc(var(--grid-size) * 13); }
    .pr14-m {    padding-right: calc(var(--grid-size) * 14); }
    .pr15-m {    padding-right: calc(var(--grid-size) * 15); }
    .pr16-m {    padding-right: calc(var(--grid-size) * 16); }
    .pr17-m {    padding-right: calc(var(--grid-size) * 17); }
    .pr18-m {    padding-right: calc(var(--grid-size) * 18); }
    .pr19-m {    padding-right: calc(var(--grid-size) * 19); }
    .pr20-m {    padding-right: calc(var(--grid-size) * 20); }
    .pr25-m {    padding-right: calc(var(--grid-size) * 25); }
    .pr30-m {    padding-right: calc(var(--grid-size) * 30); }
    .pr40-m {    padding-right: calc(var(--grid-size) * 40); }
    .pr50-m {    padding-right: calc(var(--grid-size) * 50); }

    .pb0-m {    padding-bottom: 0; }
    .pb1-m {    padding-bottom: calc(var(--grid-size) * 1); }
    .pb2-m {    padding-bottom: calc(var(--grid-size) * 2); }
    .pb3-m {    padding-bottom: calc(var(--grid-size) * 3); }
    .pb4-m {    padding-bottom: calc(var(--grid-size) * 4); }
    .pb5-m {    padding-bottom: calc(var(--grid-size) * 5); }
    .pb6-m {    padding-bottom: calc(var(--grid-size) * 6); }
    .pb7-m {    padding-bottom: calc(var(--grid-size) * 7); }
    .pb8-m {    padding-bottom: calc(var(--grid-size) * 8); }
    .pb9-m {    padding-bottom: calc(var(--grid-size) * 9); }
    .pb10-m {    padding-bottom: calc(var(--grid-size) * 10); }
    .pb11-m {    padding-bottom: calc(var(--grid-size) * 11); }
    .pb12-m {    padding-bottom: calc(var(--grid-size) * 12); }
    .pb13-m {    padding-bottom: calc(var(--grid-size) * 13); }
    .pb14-m {    padding-bottom: calc(var(--grid-size) * 14); }
    .pb15-m {    padding-bottom: calc(var(--grid-size) * 15); }
    .pb16-m {    padding-bottom: calc(var(--grid-size) * 16); }
    .pb17-m {    padding-bottom: calc(var(--grid-size) * 17); }
    .pb18-m {    padding-bottom: calc(var(--grid-size) * 18); }
    .pb19-m {    padding-bottom: calc(var(--grid-size) * 19); }
    .pb20-m {    padding-bottom: calc(var(--grid-size) * 20); }
    .pb25-m {    padding-bottom: calc(var(--grid-size) * 25); }
    .pb30-m {    padding-bottom: calc(var(--grid-size) * 30); }
    .pb40-m {    padding-bottom: calc(var(--grid-size) * 40); }
    .pb50-m {    padding-bottom: calc(var(--grid-size) * 50); }

    .pl0-m {    padding-left: 0; }
    .pl1-m {    padding-left: calc(var(--grid-size) * 1); }
    .pl2-m {    padding-left: calc(var(--grid-size) * 2); }
    .pl3-m {    padding-left: calc(var(--grid-size) * 3); }
    .pl4-m {    padding-left: calc(var(--grid-size) * 4); }
    .pl5-m {    padding-left: calc(var(--grid-size) * 5); }
    .pl6-m {    padding-left: calc(var(--grid-size) * 6); }
    .pl7-m {    padding-left: calc(var(--grid-size) * 7); }
    .pl8-m {    padding-left: calc(var(--grid-size) * 8); }
    .pl9-m {    padding-left: calc(var(--grid-size) * 9); }
    .pl10-m {    padding-left: calc(var(--grid-size) * 10); }
    .pl11-m {    padding-left: calc(var(--grid-size) * 11); }
    .pl12-m {    padding-left: calc(var(--grid-size) * 12); }
    .pl13-m {    padding-left: calc(var(--grid-size) * 13); }
    .pl14-m {    padding-left: calc(var(--grid-size) * 14); }
    .pl15-m {    padding-left: calc(var(--grid-size) * 15); }
    .pl16-m {    padding-left: calc(var(--grid-size) * 16); }
    .pl17-m {    padding-left: calc(var(--grid-size) * 17); }
    .pl18-m {    padding-left: calc(var(--grid-size) * 18); }
    .pl19-m {    padding-left: calc(var(--grid-size) * 19); }
    .pl20-m {    padding-left: calc(var(--grid-size) * 20); }
    .pl25-m {    padding-left: calc(var(--grid-size) * 25); }
    .pl30-m {    padding-left: calc(var(--grid-size) * 30); }
    .pl40-m {    padding-left: calc(var(--grid-size) * 40); }
    .pl50-m {    padding-left: calc(var(--grid-size) * 50); }

    .pt0-m {    padding-top: 0; }
    .pt1-m {    padding-top: calc(var(--grid-size) * 1); }
    .pt2-m {    padding-top: calc(var(--grid-size) * 2); }
    .pt3-m {    padding-top: calc(var(--grid-size) * 3); }
    .pt4-m {    padding-top: calc(var(--grid-size) * 4); }
    .pt5-m {    padding-top: calc(var(--grid-size) * 5); }
    .pt6-m {    padding-top: calc(var(--grid-size) * 6); }
    .pt7-m {    padding-top: calc(var(--grid-size) * 7); }
    .pt8-m {    padding-top: calc(var(--grid-size) * 8); }
    .pt9-m {    padding-top: calc(var(--grid-size) * 9); }
    .pt10-m {    padding-top: calc(var(--grid-size) * 10); }
    .pt11-m {    padding-top: calc(var(--grid-size) * 11); }
    .pt12-m {    padding-top: calc(var(--grid-size) * 12); }
    .pt13-m {    padding-top: calc(var(--grid-size) * 13); }
    .pt14-m {    padding-top: calc(var(--grid-size) * 14); }
    .pt15-m {    padding-top: calc(var(--grid-size) * 15); }
    .pt16-m {    padding-top: calc(var(--grid-size) * 16); }
    .pt17-m {    padding-top: calc(var(--grid-size) * 17); }
    .pt18-m {    padding-top: calc(var(--grid-size) * 18); }
    .pt19-m {    padding-top: calc(var(--grid-size) * 19); }
    .pt20-m {    padding-top: calc(var(--grid-size) * 20); }
    .pt25-m {    padding-top: calc(var(--grid-size) * 25); }
    .pt30-m {    padding-top: calc(var(--grid-size) * 30); }
    .pt40-m {    padding-top: calc(var(--grid-size) * 40); }
    .pt50-m {    padding-top: calc(var(--grid-size) * 50); }

    .ma0-m {    margin: 0; }
    .ma1-m {    margin: calc(var(--grid-size) * 1); }
    .ma2-m {    margin: calc(var(--grid-size) * 2); }
    .ma3-m {    margin: calc(var(--grid-size) * 3); }
    .ma4-m {    margin: calc(var(--grid-size) * 4); }
    .ma5-m {    margin: calc(var(--grid-size) * 5); }
    .ma6-m {    margin: calc(var(--grid-size) * 6); }
    .ma7-m {    margin: calc(var(--grid-size) * 7); }
    .ma8-m {    margin: calc(var(--grid-size) * 8); }
    .ma9-m {    margin: calc(var(--grid-size) * 9); }
    .ma10-m {    margin: calc(var(--grid-size) * 10); }
    .ma11-m {    margin: calc(var(--grid-size) * 11); }
    .ma12-m {    margin: calc(var(--grid-size) * 12); }
    .ma13-m {    margin: calc(var(--grid-size) * 13); }
    .ma14-m {    margin: calc(var(--grid-size) * 14); }
    .ma15-m {    margin: calc(var(--grid-size) * 15); }
    .ma16-m {    margin: calc(var(--grid-size) * 16); }
    .ma17-m {    margin: calc(var(--grid-size) * 17); }
    .ma18-m {    margin: calc(var(--grid-size) * 18); }
    .ma19-m {    margin: calc(var(--grid-size) * 19); }
    .ma20-m {    margin: calc(var(--grid-size) * 20); }
    .ma25-m {    margin: calc(var(--grid-size) * 25); }
    .ma30-m {    margin: calc(var(--grid-size) * 30); }
    .ma40-m {    margin: calc(var(--grid-size) * 40); }
    .ma50-m {    margin: calc(var(--grid-size) * 50); }

    .mr0-m {    margin-right: 0; }
    .mr1-m {    margin-right: calc(var(--grid-size) * 1); }
    .mr2-m {    margin-right: calc(var(--grid-size) * 2); }
    .mr3-m {    margin-right: calc(var(--grid-size) * 3); }
    .mr4-m {    margin-right: calc(var(--grid-size) * 4); }
    .mr5-m {    margin-right: calc(var(--grid-size) * 5); }
    .mr6-m {    margin-right: calc(var(--grid-size) * 6); }
    .mr7-m {    margin-right: calc(var(--grid-size) * 7); }
    .mr8-m {    margin-right: calc(var(--grid-size) * 8); }
    .mr9-m {    margin-right: calc(var(--grid-size) * 9); }
    .mr10-m {    margin-right: calc(var(--grid-size) * 10); }
    .mr11-m {    margin-right: calc(var(--grid-size) * 11); }
    .mr12-m {    margin-right: calc(var(--grid-size) * 12); }
    .mr13-m {    margin-right: calc(var(--grid-size) * 13); }
    .mr14-m {    margin-right: calc(var(--grid-size) * 14); }
    .mr15-m {    margin-right: calc(var(--grid-size) * 15); }
    .mr16-m {    margin-right: calc(var(--grid-size) * 16); }
    .mr17-m {    margin-right: calc(var(--grid-size) * 17); }
    .mr18-m {    margin-right: calc(var(--grid-size) * 18); }
    .mr19-m {    margin-right: calc(var(--grid-size) * 19); }
    .mr20-m {    margin-right: calc(var(--grid-size) * 20); }
    .mr25-m {    margin-right: calc(var(--grid-size) * 25); }
    .mr30-m {    margin-right: calc(var(--grid-size) * 30); }
    .mr40-m {    margin-right: calc(var(--grid-size) * 40); }
    .mr50-m {    margin-right: calc(var(--grid-size) * 50); }

    .mb0-m {    margin-bottom: 0; }
    .mb1-m {    margin-bottom: calc(var(--grid-size) * 1); }
    .mb2-m {    margin-bottom: calc(var(--grid-size) * 2); }
    .mb3-m {    margin-bottom: calc(var(--grid-size) * 3); }
    .mb4-m {    margin-bottom: calc(var(--grid-size) * 4); }
    .mb5-m {    margin-bottom: calc(var(--grid-size) * 5); }
    .mb6-m {    margin-bottom: calc(var(--grid-size) * 6); }
    .mb7-m {    margin-bottom: calc(var(--grid-size) * 7); }
    .mb8-m {    margin-bottom: calc(var(--grid-size) * 8); }
    .mb9-m {    margin-bottom: calc(var(--grid-size) * 9); }
    .mb10-m {    margin-bottom: calc(var(--grid-size) * 10); }
    .mb11-m {    margin-bottom: calc(var(--grid-size) * 11); }
    .mb12-m {    margin-bottom: calc(var(--grid-size) * 12); }
    .mb13-m {    margin-bottom: calc(var(--grid-size) * 13); }
    .mb14-m {    margin-bottom: calc(var(--grid-size) * 14); }
    .mb15-m {    margin-bottom: calc(var(--grid-size) * 15); }
    .mb16-m {    margin-bottom: calc(var(--grid-size) * 16); }
    .mb17-m {    margin-bottom: calc(var(--grid-size) * 17); }
    .mb18-m {    margin-bottom: calc(var(--grid-size) * 18); }
    .mb19-m {    margin-bottom: calc(var(--grid-size) * 19); }
    .mb20-m {    margin-bottom: calc(var(--grid-size) * 20); }
    .mb25-m {    margin-bottom: calc(var(--grid-size) * 25); }
    .mb30-m {    margin-bottom: calc(var(--grid-size) * 30); }
    .mb40-m {    margin-bottom: calc(var(--grid-size) * 40); }
    .mb50-m {    margin-bottom: calc(var(--grid-size) * 50); }

    .ml0-m {    margin-left: 0; }
    .ml1-m {    margin-left: calc(var(--grid-size) * 1); }
    .ml2-m {    margin-left: calc(var(--grid-size) * 2); }
    .ml3-m {    margin-left: calc(var(--grid-size) * 3); }
    .ml4-m {    margin-left: calc(var(--grid-size) * 4); }
    .ml5-m {    margin-left: calc(var(--grid-size) * 5); }
    .ml6-m {    margin-left: calc(var(--grid-size) * 6); }
    .ml7-m {    margin-left: calc(var(--grid-size) * 7); }
    .ml8-m {    margin-left: calc(var(--grid-size) * 8); }
    .ml9-m {    margin-left: calc(var(--grid-size) * 9); }
    .ml10-m {    margin-left: calc(var(--grid-size) * 10); }
    .ml11-m {    margin-left: calc(var(--grid-size) * 11); }
    .ml12-m {    margin-left: calc(var(--grid-size) * 12); }
    .ml13-m {    margin-left: calc(var(--grid-size) * 13); }
    .ml14-m {    margin-left: calc(var(--grid-size) * 14); }
    .ml15-m {    margin-left: calc(var(--grid-size) * 15); }
    .ml16-m {    margin-left: calc(var(--grid-size) * 16); }
    .ml17-m {    margin-left: calc(var(--grid-size) * 17); }
    .ml18-m {    margin-left: calc(var(--grid-size) * 18); }
    .ml19-m {    margin-left: calc(var(--grid-size) * 19); }
    .ml20-m {    margin-left: calc(var(--grid-size) * 20); }
    .ml25-m {    margin-left: calc(var(--grid-size) * 25); }
    .ml30-m {    margin-left: calc(var(--grid-size) * 30); }
    .ml40-m {    margin-left: calc(var(--grid-size) * 40); }
    .ml50-m {    margin-left: calc(var(--grid-size) * 50); }

    .mt0-m {    margin-top: 0; }
    .mt1-m {    margin-top: calc(var(--grid-size) * 1); }
    .mt2-m {    margin-top: calc(var(--grid-size) * 2); }
    .mt3-m {    margin-top: calc(var(--grid-size) * 3); }
    .mt4-m {    margin-top: calc(var(--grid-size) * 4); }
    .mt5-m {    margin-top: calc(var(--grid-size) * 5); }
    .mt6-m {    margin-top: calc(var(--grid-size) * 6); }
    .mt7-m {    margin-top: calc(var(--grid-size) * 7); }
    .mt8-m {    margin-top: calc(var(--grid-size) * 8); }
    .mt9-m {    margin-top: calc(var(--grid-size) * 9); }
    .mt10-m {    margin-top: calc(var(--grid-size) * 10); }
    .mt11-m {    margin-top: calc(var(--grid-size) * 11); }
    .mt12-m {    margin-top: calc(var(--grid-size) * 12); }
    .mt13-m {    margin-top: calc(var(--grid-size) * 13); }
    .mt14-m {    margin-top: calc(var(--grid-size) * 14); }
    .mt15-m {    margin-top: calc(var(--grid-size) * 15); }
    .mt16-m {    margin-top: calc(var(--grid-size) * 16); }
    .mt17-m {    margin-top: calc(var(--grid-size) * 17); }
    .mt18-m {    margin-top: calc(var(--grid-size) * 18); }
    .mt19-m {    margin-top: calc(var(--grid-size) * 19); }
    .mt20-m {    margin-top: calc(var(--grid-size) * 20); }
    .mt25-m {    margin-top: calc(var(--grid-size) * 25); }
    .mt30-m {    margin-top: calc(var(--grid-size) * 30); }
    .mt40-m {    margin-top: calc(var(--grid-size) * 40); }
    .mt50-m {    margin-top: calc(var(--grid-size) * 50); }
}

@media (--breakpoint-large) {
    .pa0-l {    padding: 0; }
    .pa1-l {    padding: calc(var(--grid-size) * 1); }
    .pa2-l {    padding: calc(var(--grid-size) * 2); }
    .pa3-l {    padding: calc(var(--grid-size) * 3); }
    .pa4-l {    padding: calc(var(--grid-size) * 4); }
    .pa5-l {    padding: calc(var(--grid-size) * 5); }
    .pa6-l {    padding: calc(var(--grid-size) * 6); }
    .pa7-l {    padding: calc(var(--grid-size) * 7); }
    .pa8-l {    padding: calc(var(--grid-size) * 8); }
    .pa9-l {    padding: calc(var(--grid-size) * 9); }
    .pa10-l {    padding: calc(var(--grid-size) * 10); }
    .pa11-l {    padding: calc(var(--grid-size) * 11); }
    .pa12-l {    padding: calc(var(--grid-size) * 12); }
    .pa13-l {    padding: calc(var(--grid-size) * 13); }
    .pa14-l {    padding: calc(var(--grid-size) * 14); }
    .pa15-l {    padding: calc(var(--grid-size) * 15); }
    .pa16-l {    padding: calc(var(--grid-size) * 16); }
    .pa17-l {    padding: calc(var(--grid-size) * 17); }
    .pa18-l {    padding: calc(var(--grid-size) * 18); }
    .pa19-l {    padding: calc(var(--grid-size) * 19); }
    .pa20-l {    padding: calc(var(--grid-size) * 20); }
    .pa25-l {    padding: calc(var(--grid-size) * 25); }
    .pa30-l {    padding: calc(var(--grid-size) * 30); }
    .pa40-l {    padding: calc(var(--grid-size) * 40); }
    .pa50-l {    padding: calc(var(--grid-size) * 50); }

    .pr0-l {    padding-right: 0; }
    .pr1-l {    padding-right: calc(var(--grid-size) * 1); }
    .pr2-l {    padding-right: calc(var(--grid-size) * 2); }
    .pr3-l {    padding-right: calc(var(--grid-size) * 3); }
    .pr4-l {    padding-right: calc(var(--grid-size) * 4); }
    .pr5-l {    padding-right: calc(var(--grid-size) * 5); }
    .pr6-l {    padding-right: calc(var(--grid-size) * 6); }
    .pr7-l {    padding-right: calc(var(--grid-size) * 7); }
    .pr8-l {    padding-right: calc(var(--grid-size) * 8); }
    .pr9-l {    padding-right: calc(var(--grid-size) * 9); }
    .pr10-l {    padding-right: calc(var(--grid-size) * 10); }
    .pr11-l {    padding-right: calc(var(--grid-size) * 11); }
    .pr12-l {    padding-right: calc(var(--grid-size) * 12); }
    .pr13-l {    padding-right: calc(var(--grid-size) * 13); }
    .pr14-l {    padding-right: calc(var(--grid-size) * 14); }
    .pr15-l {    padding-right: calc(var(--grid-size) * 15); }
    .pr16-l {    padding-right: calc(var(--grid-size) * 16); }
    .pr17-l {    padding-right: calc(var(--grid-size) * 17); }
    .pr18-l {    padding-right: calc(var(--grid-size) * 18); }
    .pr19-l {    padding-right: calc(var(--grid-size) * 19); }
    .pr20-l {    padding-right: calc(var(--grid-size) * 20); }
    .pr25-l {    padding-right: calc(var(--grid-size) * 25); }
    .pr30-l {    padding-right: calc(var(--grid-size) * 30); }
    .pr40-l {    padding-right: calc(var(--grid-size) * 40); }
    .pr50-l {    padding-right: calc(var(--grid-size) * 50); }

    .pb0-l {    padding-bottom: 0; }
    .pb1-l {    padding-bottom: calc(var(--grid-size) * 1); }
    .pb2-l {    padding-bottom: calc(var(--grid-size) * 2); }
    .pb3-l {    padding-bottom: calc(var(--grid-size) * 3); }
    .pb4-l {    padding-bottom: calc(var(--grid-size) * 4); }
    .pb5-l {    padding-bottom: calc(var(--grid-size) * 5); }
    .pb6-l {    padding-bottom: calc(var(--grid-size) * 6); }
    .pb7-l {    padding-bottom: calc(var(--grid-size) * 7); }
    .pb8-l {    padding-bottom: calc(var(--grid-size) * 8); }
    .pb9-l {    padding-bottom: calc(var(--grid-size) * 9); }
    .pb10-l {    padding-bottom: calc(var(--grid-size) * 10); }
    .pb11-l {    padding-bottom: calc(var(--grid-size) * 11); }
    .pb12-l {    padding-bottom: calc(var(--grid-size) * 12); }
    .pb13-l {    padding-bottom: calc(var(--grid-size) * 13); }
    .pb14-l {    padding-bottom: calc(var(--grid-size) * 14); }
    .pb15-l {    padding-bottom: calc(var(--grid-size) * 15); }
    .pb16-l {    padding-bottom: calc(var(--grid-size) * 16); }
    .pb17-l {    padding-bottom: calc(var(--grid-size) * 17); }
    .pb18-l {    padding-bottom: calc(var(--grid-size) * 18); }
    .pb19-l {    padding-bottom: calc(var(--grid-size) * 19); }
    .pb20-l {    padding-bottom: calc(var(--grid-size) * 20); }
    .pb25-l {    padding-bottom: calc(var(--grid-size) * 25); }
    .pb30-l {    padding-bottom: calc(var(--grid-size) * 30); }
    .pb40-l {    padding-bottom: calc(var(--grid-size) * 40); }
    .pb50-l {    padding-bottom: calc(var(--grid-size) * 50); }

    .pl0-l {    padding-left: 0; }
    .pl1-l {    padding-left: calc(var(--grid-size) * 1); }
    .pl2-l {    padding-left: calc(var(--grid-size) * 2); }
    .pl3-l {    padding-left: calc(var(--grid-size) * 3); }
    .pl4-l {    padding-left: calc(var(--grid-size) * 4); }
    .pl5-l {    padding-left: calc(var(--grid-size) * 5); }
    .pl6-l {    padding-left: calc(var(--grid-size) * 6); }
    .pl7-l {    padding-left: calc(var(--grid-size) * 7); }
    .pl8-l {    padding-left: calc(var(--grid-size) * 8); }
    .pl9-l {    padding-left: calc(var(--grid-size) * 9); }
    .pl10-l {    padding-left: calc(var(--grid-size) * 10); }
    .pl11-l {    padding-left: calc(var(--grid-size) * 11); }
    .pl12-l {    padding-left: calc(var(--grid-size) * 12); }
    .pl13-l {    padding-left: calc(var(--grid-size) * 13); }
    .pl14-l {    padding-left: calc(var(--grid-size) * 14); }
    .pl15-l {    padding-left: calc(var(--grid-size) * 15); }
    .pl16-l {    padding-left: calc(var(--grid-size) * 16); }
    .pl17-l {    padding-left: calc(var(--grid-size) * 17); }
    .pl18-l {    padding-left: calc(var(--grid-size) * 18); }
    .pl19-l {    padding-left: calc(var(--grid-size) * 19); }
    .pl20-l {    padding-left: calc(var(--grid-size) * 20); }
    .pl25-l {    padding-left: calc(var(--grid-size) * 25); }
    .pl30-l {    padding-left: calc(var(--grid-size) * 30); }
    .pl40-l {    padding-left: calc(var(--grid-size) * 40); }
    .pl50-l {    padding-left: calc(var(--grid-size) * 50); }

    .pt0-l {    padding-top: 0; }
    .pt1-l {    padding-top: calc(var(--grid-size) * 1); }
    .pt2-l {    padding-top: calc(var(--grid-size) * 2); }
    .pt3-l {    padding-top: calc(var(--grid-size) * 3); }
    .pt4-l {    padding-top: calc(var(--grid-size) * 4); }
    .pt5-l {    padding-top: calc(var(--grid-size) * 5); }
    .pt6-l {    padding-top: calc(var(--grid-size) * 6); }
    .pt7-l {    padding-top: calc(var(--grid-size) * 7); }
    .pt8-l {    padding-top: calc(var(--grid-size) * 8); }
    .pt9-l {    padding-top: calc(var(--grid-size) * 9); }
    .pt10-l {    padding-top: calc(var(--grid-size) * 10); }
    .pt11-l {    padding-top: calc(var(--grid-size) * 11); }
    .pt12-l {    padding-top: calc(var(--grid-size) * 12); }
    .pt13-l {    padding-top: calc(var(--grid-size) * 13); }
    .pt14-l {    padding-top: calc(var(--grid-size) * 14); }
    .pt15-l {    padding-top: calc(var(--grid-size) * 15); }
    .pt16-l {    padding-top: calc(var(--grid-size) * 16); }
    .pt17-l {    padding-top: calc(var(--grid-size) * 17); }
    .pt18-l {    padding-top: calc(var(--grid-size) * 18); }
    .pt19-l {    padding-top: calc(var(--grid-size) * 19); }
    .pt20-l {    padding-top: calc(var(--grid-size) * 20); }
    .pt25-l {    padding-top: calc(var(--grid-size) * 25); }
    .pt30-l {    padding-top: calc(var(--grid-size) * 30); }
    .pt40-l {    padding-top: calc(var(--grid-size) * 40); }
    .pt50-l {    padding-top: calc(var(--grid-size) * 50); }

    .ma0-l {    margin: 0; }
    .ma1-l {    margin: calc(var(--grid-size) * 1); }
    .ma2-l {    margin: calc(var(--grid-size) * 2); }
    .ma3-l {    margin: calc(var(--grid-size) * 3); }
    .ma4-l {    margin: calc(var(--grid-size) * 4); }
    .ma5-l {    margin: calc(var(--grid-size) * 5); }
    .ma6-l {    margin: calc(var(--grid-size) * 6); }
    .ma7-l {    margin: calc(var(--grid-size) * 7); }
    .ma8-l {    margin: calc(var(--grid-size) * 8); }
    .ma9-l {    margin: calc(var(--grid-size) * 9); }
    .ma10-l {    margin: calc(var(--grid-size) * 10); }
    .ma11-l {    margin: calc(var(--grid-size) * 11); }
    .ma12-l {    margin: calc(var(--grid-size) * 12); }
    .ma13-l {    margin: calc(var(--grid-size) * 13); }
    .ma14-l {    margin: calc(var(--grid-size) * 14); }
    .ma15-l {    margin: calc(var(--grid-size) * 15); }
    .ma16-l {    margin: calc(var(--grid-size) * 16); }
    .ma17-l {    margin: calc(var(--grid-size) * 17); }
    .ma18-l {    margin: calc(var(--grid-size) * 18); }
    .ma19-l {    margin: calc(var(--grid-size) * 19); }
    .ma20-l {    margin: calc(var(--grid-size) * 20); }
    .ma25-l {    margin: calc(var(--grid-size) * 25); }
    .ma30-l {    margin: calc(var(--grid-size) * 30); }
    .ma40-l {    margin: calc(var(--grid-size) * 40); }
    .ma50-l {    margin: calc(var(--grid-size) * 50); }

    .mr0-l {    margin-right: 0; }
    .mr1-l {    margin-right: calc(var(--grid-size) * 1); }
    .mr2-l {    margin-right: calc(var(--grid-size) * 2); }
    .mr3-l {    margin-right: calc(var(--grid-size) * 3); }
    .mr4-l {    margin-right: calc(var(--grid-size) * 4); }
    .mr5-l {    margin-right: calc(var(--grid-size) * 5); }
    .mr6-l {    margin-right: calc(var(--grid-size) * 6); }
    .mr7-l {    margin-right: calc(var(--grid-size) * 7); }
    .mr8-l {    margin-right: calc(var(--grid-size) * 8); }
    .mr9-l {    margin-right: calc(var(--grid-size) * 9); }
    .mr10-l {    margin-right: calc(var(--grid-size) * 10); }
    .mr11-l {    margin-right: calc(var(--grid-size) * 11); }
    .mr12-l {    margin-right: calc(var(--grid-size) * 12); }
    .mr13-l {    margin-right: calc(var(--grid-size) * 13); }
    .mr14-l {    margin-right: calc(var(--grid-size) * 14); }
    .mr15-l {    margin-right: calc(var(--grid-size) * 15); }
    .mr16-l {    margin-right: calc(var(--grid-size) * 16); }
    .mr17-l {    margin-right: calc(var(--grid-size) * 17); }
    .mr18-l {    margin-right: calc(var(--grid-size) * 18); }
    .mr19-l {    margin-right: calc(var(--grid-size) * 19); }
    .mr20-l {    margin-right: calc(var(--grid-size) * 20); }
    .mr25-l {    margin-right: calc(var(--grid-size) * 25); }
    .mr30-l {    margin-right: calc(var(--grid-size) * 30); }
    .mr40-l {    margin-right: calc(var(--grid-size) * 40); }
    .mr50-l {    margin-right: calc(var(--grid-size) * 50); }

    .mb0-l {    margin-bottom: 0; }
    .mb1-l {    margin-bottom: calc(var(--grid-size) * 1); }
    .mb2-l {    margin-bottom: calc(var(--grid-size) * 2); }
    .mb3-l {    margin-bottom: calc(var(--grid-size) * 3); }
    .mb4-l {    margin-bottom: calc(var(--grid-size) * 4); }
    .mb5-l {    margin-bottom: calc(var(--grid-size) * 5); }
    .mb6-l {    margin-bottom: calc(var(--grid-size) * 6); }
    .mb7-l {    margin-bottom: calc(var(--grid-size) * 7); }
    .mb8-l {    margin-bottom: calc(var(--grid-size) * 8); }
    .mb9-l {    margin-bottom: calc(var(--grid-size) * 9); }
    .mb10-l {    margin-bottom: calc(var(--grid-size) * 10); }
    .mb11-l {    margin-bottom: calc(var(--grid-size) * 11); }
    .mb12-l {    margin-bottom: calc(var(--grid-size) * 12); }
    .mb13-l {    margin-bottom: calc(var(--grid-size) * 13); }
    .mb14-l {    margin-bottom: calc(var(--grid-size) * 14); }
    .mb15-l {    margin-bottom: calc(var(--grid-size) * 15); }
    .mb16-l {    margin-bottom: calc(var(--grid-size) * 16); }
    .mb17-l {    margin-bottom: calc(var(--grid-size) * 17); }
    .mb18-l {    margin-bottom: calc(var(--grid-size) * 18); }
    .mb19-l {    margin-bottom: calc(var(--grid-size) * 19); }
    .mb20-l {    margin-bottom: calc(var(--grid-size) * 20); }
    .mb25-l {    margin-bottom: calc(var(--grid-size) * 25); }
    .mb30-l {    margin-bottom: calc(var(--grid-size) * 30); }
    .mb40-l {    margin-bottom: calc(var(--grid-size) * 40); }
    .mb50-l {    margin-bottom: calc(var(--grid-size) * 50); }

    .ml0-l {    margin-left: 0; }
    .ml1-l {    margin-left: calc(var(--grid-size) * 1); }
    .ml2-l {    margin-left: calc(var(--grid-size) * 2); }
    .ml3-l {    margin-left: calc(var(--grid-size) * 3); }
    .ml4-l {    margin-left: calc(var(--grid-size) * 4); }
    .ml5-l {    margin-left: calc(var(--grid-size) * 5); }
    .ml6-l {    margin-left: calc(var(--grid-size) * 6); }
    .ml7-l {    margin-left: calc(var(--grid-size) * 7); }
    .ml8-l {    margin-left: calc(var(--grid-size) * 8); }
    .ml9-l {    margin-left: calc(var(--grid-size) * 9); }
    .ml10-l {    margin-left: calc(var(--grid-size) * 10); }
    .ml11-l {    margin-left: calc(var(--grid-size) * 11); }
    .ml12-l {    margin-left: calc(var(--grid-size) * 12); }
    .ml13-l {    margin-left: calc(var(--grid-size) * 13); }
    .ml14-l {    margin-left: calc(var(--grid-size) * 14); }
    .ml15-l {    margin-left: calc(var(--grid-size) * 15); }
    .ml16-l {    margin-left: calc(var(--grid-size) * 16); }
    .ml17-l {    margin-left: calc(var(--grid-size) * 17); }
    .ml18-l {    margin-left: calc(var(--grid-size) * 18); }
    .ml19-l {    margin-left: calc(var(--grid-size) * 19); }
    .ml20-l {    margin-left: calc(var(--grid-size) * 20); }
    .ml25-l {    margin-left: calc(var(--grid-size) * 25); }
    .ml30-l {    margin-left: calc(var(--grid-size) * 30); }
    .ml40-l {    margin-left: calc(var(--grid-size) * 40); }
    .ml50-l {    margin-left: calc(var(--grid-size) * 50); }

    .mt0-l {    margin-top: 0; }
    .mt1-l {    margin-top: calc(var(--grid-size) * 1); }
    .mt2-l {    margin-top: calc(var(--grid-size) * 2); }
    .mt3-l {    margin-top: calc(var(--grid-size) * 3); }
    .mt4-l {    margin-top: calc(var(--grid-size) * 4); }
    .mt5-l {    margin-top: calc(var(--grid-size) * 5); }
    .mt6-l {    margin-top: calc(var(--grid-size) * 6); }
    .mt7-l {    margin-top: calc(var(--grid-size) * 7); }
    .mt8-l {    margin-top: calc(var(--grid-size) * 8); }
    .mt9-l {    margin-top: calc(var(--grid-size) * 9); }
    .mt10-l {    margin-top: calc(var(--grid-size) * 10); }
    .mt11-l {    margin-top: calc(var(--grid-size) * 11); }
    .mt12-l {    margin-top: calc(var(--grid-size) * 12); }
    .mt13-l {    margin-top: calc(var(--grid-size) * 13); }
    .mt14-l {    margin-top: calc(var(--grid-size) * 14); }
    .mt15-l {    margin-top: calc(var(--grid-size) * 15); }
    .mt16-l {    margin-top: calc(var(--grid-size) * 16); }
    .mt17-l {    margin-top: calc(var(--grid-size) * 17); }
    .mt18-l {    margin-top: calc(var(--grid-size) * 18); }
    .mt19-l {    margin-top: calc(var(--grid-size) * 19); }
    .mt20-l {    margin-top: calc(var(--grid-size) * 20); }
    .mt25-l {    margin-top: calc(var(--grid-size) * 25); }
    .mt30-l {    margin-top: calc(var(--grid-size) * 30); }
    .mt40-l {    margin-top: calc(var(--grid-size) * 40); }
    .mt50-l {    margin-top: calc(var(--grid-size) * 50); }
}
