/*

   CODE

*/

.pre {
  overflow-x: auto;
  overflow-y: hidden;
  overflow:   scroll;
}
