/*

  WORD BREAK

   Base:
     word = word-break

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.word-normal { word-break: normal; }
.word-wrap { word-break: break-all; }
.word-nowrap { word-break: keep-all; }

@media (--breakpoint-not-small) {
  .word-normal-ns { word-break: normal; }
  .word-wrap-ns { word-break: break-all; }
  .word-nowrap-ns { word-break: keep-all; }
}

@media (--breakpoint-medium) {
  .word-normal-m { word-break: normal; }
  .word-wrap-m { word-break: break-all; }
  .word-nowrap-m { word-break: keep-all; }
}

@media (--breakpoint-large) {
  .word-normal-l { word-break: normal; }
  .word-wrap-l { word-break: break-all; }
  .word-nowrap-l { word-break: keep-all; }
}

