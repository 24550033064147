:root {
    --dashboard-gap: 1.8vw;
}

.gh-dashboard {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: var(--dashboard-gap);
}

.gh-dashboard-area {
    display: flex;
    flex-direction: column;
}

.gh-dashboard-header {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .2px;
    margin: -4px 0 4px;
    padding: 0;
    color: var(--black);
}

.gh-dashboard-header svg {
    width: 12px;
    height: 12px;
    margin-left: 8px;
}

.gh-dashboard-header.secondary {
    color: var(--midgrey);
}

.gh-dashboard-summary.small .gh-dashboard-header {
    margin-bottom: 6px;
}

.gh-dashboard-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    justify-content: flex-start;
}

.gh-dashboard-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
}

.gh-dashboard-container.col-2 {
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--dashboard-gap);
}

/* Generic box containers */
.gh-dashboard-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--whitegrey);
    border-radius: 3px;
    padding: 28px;
    margin-bottom: var(--dashboard-gap);
}

.gh-dashboard-box .content {
    color: var(--darkgrey);
}

.gh-dashboard-box .content h2 {
    font-size: 1.65rem;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 8px;
    color: var(--black);
}

.gh-dashboard-box .content h3 {
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 6px;
    color: var(--black);
}

.gh-dashboard-box .content p {
    margin-bottom: 12px;
}

.gh-dashboard-box .footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.gh-dashboard-box.grey {
    background: var(--main-color-content-greybg);
    border: none;
}

.gh-dashboard-box.black {
    background: var(--black);
    border: none;
    color: var(--white);
}

.gh-dashboard-box.black .content h4 {
    color: var(--white);
}

.gh-dashboard-box.black .content p {
    color: var(--whitegrey);
}

.gh-dashboard-box.green {
    background: color-mod(var(--green) a(4%));
    border-color: color-mod(var(--green) a(60%));
}

/* Main areas in grid */
.gh-dashboard-area.charts {
    grid-column: 1 / 3;
}

.gh-dashboard-area.members-activity {
    grid-column: 2 / 3;
}

.gh-dashboard-area.mixed {
    grid-column: 1 / 2;
    border: none;
    border-radius: unset;
    padding: 0;
    align-items: stretch;
    justify-content: flex-start;
}

.gh-dashboard-area.lw-banner {
    grid-column: 1 / 3;
}

/* Launch Wizard Banner */
.gh-lw-banner {
    position: relative;
    border-radius: 3px;
    background: #15171A;
    padding: 28px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 35vw;
}

.gh-lw-banner h1 {
    font-size: 2.5rem;
    letter-spacing: -0.1px;
    color: #fff;
}

.gh-lw-banner p {
    font-size: 1.5rem;
    color: #C5D2D9;
    max-width: 480px;
}

.gh-dashboard-box.blogpost {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    align-items: center;
    overflow: hidden;
}

.gh-dashboard-box.blogpost .content {
    padding: 32px 28px 32px 32px;
    display: flex;
    flex-direction: column;
}

.gh-dashboard-box.blogpost p {
    margin-bottom: 8px;
}

.gh-dashboard-box.blogpost .thumbnail {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.gh-dashboard-box.thebrowser .thumbnail {
    background-position: top right;
}

.gh-dashboard-box .content a {
    color: var(--green);
}

.gh-dashboard-box .content h2 a {
    color: var(--black);
}

.gh-dashboard-box .content a:hover {
    color: var(--green-d2);
}

.gh-dashboard-box .content .read-time {
    font-size: 1.2rem;
    color: var(--midgrey);
}

a.gh-dashboard-container {
    color: currentColor;
}

.gh-dashboard-container.crunched .gh-dashboard-box{
    padding: 28px;
}

.gh-dashboard-container.start-contents .gh-dashboard-box {
    grid-row-gap: 0;
    align-items: flex-start;
}

.gh-dashboard-container.start-contents .gh-dashboard-box h2 {
    grid-column: 1 / 3;
    border-bottom: 1px solid var(--whitegrey);
    padding: 16px 28px;
    font-size: 1.65rem;
    font-weight: 600;
    line-height: 1.4em;
}

.gh-dashboard-container.start-contents .gh-dashboard-box a {
    display: flex;
    padding: 16px 28px;
    color: var(--black);
}

.gh-dashboard-container.start-contents .gh-dashboard-box a .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    min-width: 38px;
    height: 38px;
    margin-right: 14px;
    background: var(--pink);
    border-radius: 4px;
    color: #fff;
}

.gh-dashboard-container.start-contents .gh-dashboard-box a .icon svg {
    width: 18px;
    height: 18px;
}

.gh-dashboard-container.start-contents .gh-dashboard-box a .icon.green {
    background: var(--green);
}

.gh-dashboard-container.start-contents .gh-dashboard-box a h4 {
    font-size: 1.5rem;
    margin-bottom: 4px;
    font-weight: 500;
}

.gh-dashboard-container.start-contents .gh-dashboard-box a p {
    font-size: 1.3rem;
}

/* Charts */
.gh-dashboard-area.charts {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    border: 1px solid var(--whitegrey);
    border-radius: 3px;
    grid-column-gap: var(--dashboard-gap);
    grid-row-gap: 12px;
    align-items: stretch;
    padding: 16px 0 20px;
}

.gh-dashboard-area.charts .gh-dashboard-box {
    border: none;
    border-radius: 0;
    padding: 16px 0 0 28px;
    margin: 0;
}

.gh-dashboard-area.charts .gh-dashboard-header {
    margin: 3px 0 4px;
}

.gh-dashboard-chart-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.gh-dashboard-area.charts .mrr {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    padding: 0 0 0 28px;
}

.gh-dashboard-area.charts .total-members {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding: 0 28px 0 28px;
    height: 75px;
}

.gh-dashboard-area.charts .paid-members {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 0 28px 0 28px;
    height: 75px;
}

.gh-dashboard-area.charts .newsletter-open-rate {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    padding: 0 28px 0 28px;
    height: 75px;
}

.gh-dashboard-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 8px;
}

.gh-dashboard-summary.small {
    min-width: 120px;
    margin-top: 0;
}

.gh-dashboard-summary .data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.gh-dashboard-summary .data {
    display: flex;
    align-items: flex-start;
    font-size: 3.9rem;
    line-height: 4rem;
    font-weight: 600;
    color: var(--black);
    letter-spacing: -0.1px;
    line-height: 1;
    white-space: nowrap;
}

.gh-dashboard-summary .data .currency {
    font-size: 0.66em;
    line-height: 1.25em;
    margin-right: 2px;
}

.gh-dashboard-summary.small .data {
    font-size: 2.8rem;
    letter-spacing: -0.1px;
}

.gh-dashboard-summary .growth {
    background: var(--whitegrey-d1);
    border-radius: 3px;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 500;
    color: var(--midgrey);
    padding: 6px 8px;
    margin: 8px 0 0;
}

.gh-dashboard-summary.small .growth {
    font-size: 1.2rem;
    letter-spacing: 0;
    padding: 2px 4px;
    margin: 5px 0 1px 0;
}

.gh-dashboard-summary .growth.positive {
    background: color-mod(var(--green) a(13%));
    color: color-mod(var(--green) l(-5%));
}

.gh-dashboard-summary .growth.positive:before {
    content: "+";
}

.gh-dashboard-summary .growth.negative {
    background: color-mod(var(--yellow) a(20%));
    color: color-mod(var(--yellow) l(-8%));
}

.gh-dashboard-chart {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.gh-dashboard-chart.nodata {
    border: 1px dashed var(--lightgrey);
    font-size: 1.3rem;
    color: var(--midlightgrey);
}

.gh-dashboard-chart.nodata {
    font-size: 1.3rem;
    color: var(--midlightgrey);
}

.gh-dashboard-chart.small {
    margin-bottom: -6px;
    flex-grow: 1;
}

.gh-dashboard-chart-box {
    position: relative;
    display: flex;
    justify-content: stretch;
    height: 228px;
    width: 35.5vw;
    padding-top: 12px;
    margin-right: -10px;
}

.gh-dashboard-chart-box.small {
    position: relative;
    width: 14vw;
    min-width: 165px;
    height: 110px;
    padding-top: 0;
    margin-top: -13px;
    margin-bottom: -20px;
    margin-right: -6px;
    transform: scale(0.9);
    transform-origin: top right;
}

.gh-dashboard-careers {
    display: grid;
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: 3fr 1fr;
}

.gh-dashboard-careers p {
    margin-bottom: 0;
}

.gh-dashboard-careers-cta {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gh-dashboard-join-community {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 3px;
    padding: 54px;
    min-height: 440px;
    background-size: cover;
    background-position: center;
    color: #fff;
}

.gh-dashboard-join-community h2 {
    color: #fff;
    font-size: 2.6rem;
    letter-spacing: -0.2px;
    margin-bottom: 16px;
}

.gh-dashboard-join-community p {
    font-size: 1.8rem;
    opacity: 0.75;
    max-width: 480px;
    line-height: 1.5em;
    letter-spacing: -0.2px;
}

.gh-dashboard-join-community a.footer-link {
    align-self: center;
    margin-bottom: -32px;
    font-size: 1.2rem;
    color: #fff;
}

.gh-dashboard-join-community a:hover {
    opacity: 0.8;
}

/* Chart.js requires relative width to be in vw units to make responsive re-rendering work */
@media (max-width: 1600px) {
    .gh-dashboard-chart-box.small {
        width: 13vw;
    }
}

@media (max-width: 1500px) {
    .gh-dashboard-chart-box {
        width: 33vw;
    }

    .gh-dashboard-chart-box.small {
        width: 12vw;
    }

    .gh-dashboard-chart.small {
        max-width: 120px;
    }
}

/* Total members */
.gh-dashboard-area.total-members {
    grid-column: 1 / 2;
    grid-row: 2;
}

.gh-dashboard-area.total-members .gh-dashboard-chart,
.gh-dashboard-area.newsletter-open-rate .gh-dashboard-chart {
    height: 6vw;
    min-height: 80px;
    max-height: 150px;
}

/* Top members */
.gh-dashboard-area.top-members {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
}

.gh-dashboard-area.top-members .gh-dashboard-list {
    justify-content: space-between;
}

.gh-dashboard-top-members {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 8px 0 0;
    width: 100%;
}

.gh-dashboard-top-member {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    justify-content: stretch;
    margin: 8px 0;
    padding: 0;
}

.gh-dashboard-top-member .member-details {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.gh-dashboard-top-member .gh-member-gravatar {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
    width: 34px;
    height: 34px;
}

.gh-dashboard-top-member .gh-member-initials {
    width: 34px;
    height: 34px;
}

.gh-dashboard-top-member .gh-member-list-avatar {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    line-height: 1;
}

.gh-dashboard-top-member .name,
.gh-dashboard-top-member .email {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--black);
}

.gh-dashboard-top-member .open-rate {
    white-space: nowrap;
    margin-left: 12px;
    color: var(--midgrey);
    font-size: 1.3rem;
    text-align: right;
    line-height: 1.35em;
}

.gh-dashboard-top-member .open-rate span {
    color: var(--midlightgrey);
    font-size: 1.2rem;
}

.gh-dashboard-top-members-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--whitegrey-d2);
    padding-top: 12px;
    margin-bottom: -4px;
    margin-top: 8px;
}

.gh-dashboard-top-members-footer a {
    display: flex;
    align-items: center;
    color: var(--black);
    font-weight: 500;
}

.gh-dashboard-top-members-footer a svg {
    width: 12px;
    height: 12px;
    margin-left: 4px;
}

.gh-dashboard-top-members-footer a svg path {
    fill: var(--black);
}

.gh-dashboard-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--whitegrey);
    padding-bottom: 10px;
}

.gh-dashboard-box.grey .gh-dashboard-header-container {
    border-color: var(--whitegrey-d2);
}

/* Activity feed */
.gh-dashboard-area.activity-feed {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    padding: 0;
    margin: 0;
}

.gh-dashboard-box.activity-feed .gh-dashboard-header {
    border-bottom: 1px solid var(--whitegrey-d2);
    padding-bottom: 14px;
}

.gh-dashboard-activity-feed {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 4px 0 0;
    width: 100%;
}

.gh-dashboard-activity-feed li {
    display: flex;
    align-items: baseline;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
    margin: 8px 0;
    padding: 0;
}

.gh-dashboard-activity-feed .activity {
    display: flex;
    align-items: baseline;
    margin-right: 32px;
    color: var(--midgrey);
    line-height: 1.5em;
    font-size: 1.3rem;
}

.gh-dashboard-activity-feed .member {
    font-weight: 500;
    color: var(--black);
    font-size: 1.4rem;
}

.gh-dashboard-activity-feed .activity svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    margin-top: 1px;
}

.gh-dashboard-activity-feed .time {
    white-space: nowrap;
    margin-left: 12px;
    color: var(--midgrey);
    font-size: 1.3rem;
}

.gh-no-data-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--midgrey-l2);
}

.gh-no-data-list svg {
    margin-top: 60px;
    width: 80px;
    height: auto;
}

.gh-no-data-list span {
    margin-top: 16px;
    font-size: 1.3rem;
    margin-bottom: 46px;
}

/* What's new? */

.gh-dashboard-box.whats-new .gh-dashboard-header-container svg {
    width: 20px;
    height: 20px;
    margin-top: -12px;
}

.gh-dashboard-box.whats-new .gh-dashboard-header-container svg path {
    fill: var(--pink);
}

.gh-dashboard-box.whats-new .content a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--darkgrey);
    margin: 12px 0;
    padding: 0 0 12px;
    border-bottom: 1px solid var(--whitegrey);
}

.gh-dashboard-box.whats-new .content a h2 {
    margin-bottom: 0;
}

.gh-dashboard-box.whats-new .content a span {
    font-size: 1.3rem;
    color: var(--midgrey);
}

.gh-dashboard-box.whats-new .content a p {
    margin-top: 4px;
}

.gh-dashboard-box.whats-new .footer {
    margin-bottom: -12px;
}

.gh-dashboard-dismiss {
    position: absolute;
    top: 20px;
    right: 20px;
}

.gh-dashboard-dismissbutton.dark {
    background: rgba(255, 255, 255, 0.1);
}

.gh-dashboard-dismissbutton svg {
    margin: 0;
}

.gh-dashboard-dismissbutton.dark svg {
    color: #fff;
    opacity: 0.75;
}

.gh-dashboard-dismissbutton.dark:hover svg {
    opacity: 1.0;
}

.gh-dashboard-dismissbutton span {
    height: 32px;
    line-height: 32px;
}

.gh-dashboard-dismiss-dropdown {
    top: calc(100% + 2px);
    left: auto;
    right: 0;
    min-width: 90px;
    line-height: 1;
}

.gh-dashboard-dismiss-dropdown.fade-out {
    animation-duration: .001s;
    pointer-events: none;
}

.gh-dashboard-dismiss-dropdown button {
    background: var(--white);
    width: 100%;
    border-radius: 0;
}

.gh-dashboard-dismiss-dropdown button span {
    line-height: 28px;
    height: 28px;
    font-size: 1.3rem;
}


@media (max-width: 1320px) {
    .gh-dashboard {
        grid-template-columns: 1fr 1fr;
    }

    .gh-dashboard-area.charts {
        grid-template-columns: 1fr 1fr;
    }

    .gh-dashboard-area.charts .mrr .gh-dashboard-chart-container {
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        flex-grow: 1;
    }

    .gh-dashboard-area.charts .mrr .gh-members-chart-box {
        width: 100%;
    }

    .gh-dashboard-chart {
        height: auto;
    }

    .gh-members-chart-box {
        height: 140px;
    }

    .gh-dashboard-summary.small{
        flex-basis: 100px;
        min-width: 100px;
    }

    .gh-dashboard-summary:not(.small) .data {
        font-size: 3.4rem;
    }

    .gh-dashboard-summary:not(.small) .growth {
        font-size: 1.5rem;
    }

    .gh-dashboard-container.col-2 {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .gh-dashboard-area.charts {
        min-height: 240px;
    }

    .gh-dashboard-area.charts .mrr .gh-dashboard-chart-box {
        height: 140px;
        width: 100%;
    }

    .gh-dashboard-area.charts .mrr .gh-dashboard-chart {
        margin-left: -20px;
    }

    .gh-dashboard-header {
        white-space: nowrap;
    }

    .gh-dashboard-chart.small {
        min-width: 50%;
    }

    .gh-dashboard-chart-box.small {
        width: 100%;
    }

    .gh-dashboard-box.blogpost {
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        grid-gap: 24px;
    }

    .gh-dashboard-box.blogpost .thumbnail {
        min-height: 220px;
        grid-row: 1 / 2;
    }

    .gh-dashboard-box.blogpost .content {
        padding-top: 0;
        grid-row: 2 / 3;
    }

    .gh-dashboard-container.crunched .chart {
        padding: 0;
        min-height: 140px;
    }

    .gh-dashboard-join-community {
        padding: 44px 32px;
    }

    .gh-dashboard-join-community .footer-link {
        margin-bottom: -24px;
    }

    .gh-dashboard-container.start-contents .gh-dashboard-box {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .gh-dashboard-container.start-contents .gh-dashboard-box h2 {
        grid-column: 1 / 2;
    }
}

@media (max-width: 680px),
(min-width: 800px) and (max-width: 1000px) {
    .gh-dashboard {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
    }

    .gh-dashboard-area.charts {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .gh-dashboard-area.charts .mrr,
    .gh-dashboard-area.charts .total-members,
    .gh-dashboard-area.charts .paid-members,
    .gh-dashboard-area.charts .newsletter-open-rate {
        grid-column: unset;
    }

    .gh-dashboard-area.members-activity {
        display: none;
    }

    .gh-dashboard-area.charts .mrr,
    .gh-dashboard-area.charts .total-members,
    .gh-dashboard-area.charts .paid-members,
    .gh-dashboard-area.charts .newsletter-open-rate {
        grid-row: unset;
    }

    .gh-dashboard-area.charts .mrr {
        padding: 0 28px;
    }

    .gh-dashboard-area.charts .total-members,
    .gh-dashboard-area.charts .paid-members,
    .gh-dashboard-area.charts .newsletter-open-rate {
        height: 55px;
    }

    .gh-dashboard-chart-box {
        padding: 28px 0;
    }

    .gh-dashboard-summary:not(.small) {
        flex-direction: row;
    }

    .gh-dashboard-summary .growth {
        margin-left: 8px;
    }

    .gh-dashboard-summary.small .data-container {
        flex-direction: row;
    }

    .gh-dashboard-summary.small .growth {
        margin: 3px 0px 0px 8px;
    }

    .gh-dashboard-summary.small .data {
        font-size: 2.0rem;
    }
}
