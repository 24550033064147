/*

    Base:
        nudge

    Modifier:
        -top = nudge from top (downwards)
        -right = nudge from right (to left)
        -bottom = nudge from bottom (upwards)
        -left = nudge from left (to right)

    Value:
        --(n) = n px to nudge by

    Media Query Extensions:
        -ns = not-small
        -m  = medium
        -l  = large

*/

.nudge-top--1 {
    position: relative;
    top: 1px;
}
.nudge-top--2 {
    position: relative;
    top: 2px;
}
.nudge-top--3 {
    position: relative;
    top: 3px;
}
.nudge-top--4 {
    position: relative;
    top: 4px;
}
.nudge-top--5 {
    position: relative;
    top: 5px;
}
.nudge-top--6 {
    position: relative;
    top: 6px;
}
.nudge-top--7 {
    position: relative;
    top: 7px;
}
.nudge-top--8 {
    position: relative;
    top: 8px;
}
.nudge-top--9 {
    position: relative;
    top: 9px;
}
.nudge-top--10 {
    position: relative;
    top: 10px;
}

.nudge-right--1 {
    position: relative;
    right: 1px;
}
.nudge-right--2 {
    position: relative;
    right: 2px;
}
.nudge-right--3 {
    position: relative;
    right: 3px;
}
.nudge-right--4 {
    position: relative;
    right: 4px;
}
.nudge-right--5 {
    position: relative;
    right: 5px;
}
.nudge-right--6 {
    position: relative;
    right: 6px;
}
.nudge-right--7 {
    position: relative;
    right: 7px;
}
.nudge-right--8 {
    position: relative;
    right: 8px;
}
.nudge-right--9 {
    position: relative;
    right: 9px;
}
.nudge-right--10 {
    position: relative;
    right: 10px;
}

.nudge-bottom--1 {
    position: relative;
    bottom: 1px;
}
.nudge-bottom--2 {
    position: relative;
    bottom: 2px;
}
.nudge-bottom--3 {
    position: relative;
    bottom: 3px;
}
.nudge-bottom--4 {
    position: relative;
    bottom: 4px;
}
.nudge-bottom--5 {
    position: relative;
    bottom: 5px;
}
.nudge-bottom--6 {
    position: relative;
    bottom: 6px;
}
.nudge-bottom--7 {
    position: relative;
    bottom: 7px;
}
.nudge-bottom--8 {
    position: relative;
    bottom: 8px;
}
.nudge-bottom--9 {
    position: relative;
    bottom: 9px;
}
.nudge-bottom--10 {
    position: relative;
    bottom: 10px;
}

.nudge-left--1 {
    position: relative;
    left: 1px;
}
.nudge-left--2 {
    position: relative;
    left: 2px;
}
.nudge-left--3 {
    position: relative;
    left: 3px;
}
.nudge-left--4 {
    position: relative;
    left: 4px;
}
.nudge-left--5 {
    position: relative;
    left: 5px;
}
.nudge-left--6 {
    position: relative;
    left: 6px;
}
.nudge-left--7 {
    position: relative;
    left: 7px;
}
.nudge-left--8 {
    position: relative;
    left: 8px;
}
.nudge-left--9 {
    position: relative;
    left: 9px;
}
.nudge-left--10 {
    position: relative;
    left: 10px;
}

@media (--breakpoint-not-small) {
    .nudge-top--1-ns {
        position: relative;
        top: 1px;
    }
    .nudge-top--2-ns {
        position: relative;
        top: 2px;
    }
    .nudge-top--3-ns {
        position: relative;
        top: 3px;
    }
    .nudge-top--4-ns {
        position: relative;
        top: 4px;
    }
    .nudge-top--5-ns {
        position: relative;
        top: 5px;
    }
    .nudge-top--6-ns {
        position: relative;
        top: 6px;
    }
    .nudge-top--7-ns {
        position: relative;
        top: 7px;
    }
    .nudge-top--8-ns {
        position: relative;
        top: 8px;
    }
    .nudge-top--9-ns {
        position: relative;
        top: 9px;
    }
    .nudge-top--10-ns {
        position: relative;
        top: 10px;
    }

    .nudge-right--1-ns {
        position: relative;
        right: 1px;
    }
    .nudge-right--2-ns {
        position: relative;
        right: 2px;
    }
    .nudge-right--3-ns {
        position: relative;
        right: 3px;
    }
    .nudge-right--4-ns {
        position: relative;
        right: 4px;
    }
    .nudge-right--5-ns {
        position: relative;
        right: 5px;
    }
    .nudge-right--6-ns {
        position: relative;
        right: 6px;
    }
    .nudge-right--7-ns {
        position: relative;
        right: 7px;
    }
    .nudge-right--8-ns {
        position: relative;
        right: 8px;
    }
    .nudge-right--9-ns {
        position: relative;
        right: 9px;
    }
    .nudge-right--10-ns {
        position: relative;
        right: 10px;
    }

    .nudge-bottom--1-ns {
        position: relative;
        bottom: 1px;
    }
    .nudge-bottom--2-ns {
        position: relative;
        bottom: 2px;
    }
    .nudge-bottom--3-ns {
        position: relative;
        bottom: 3px;
    }
    .nudge-bottom--4-ns {
        position: relative;
        bottom: 4px;
    }
    .nudge-bottom--5-ns {
        position: relative;
        bottom: 5px;
    }
    .nudge-bottom--6-ns {
        position: relative;
        bottom: 6px;
    }
    .nudge-bottom--7-ns {
        position: relative;
        bottom: 7px;
    }
    .nudge-bottom--8-ns {
        position: relative;
        bottom: 8px;
    }
    .nudge-bottom--9-ns {
        position: relative;
        bottom: 9px;
    }
    .nudge-bottom--10-ns {
        position: relative;
        bottom: 10px;
    }

    .nudge-left--1-ns {
        position: relative;
        left: 1px;
    }
    .nudge-left--2-ns {
        position: relative;
        left: 2px;
    }
    .nudge-left--3-ns {
        position: relative;
        left: 3px;
    }
    .nudge-left--4-ns {
        position: relative;
        left: 4px;
    }
    .nudge-left--5-ns {
        position: relative;
        left: 5px;
    }
    .nudge-left--6-ns {
        position: relative;
        left: 6px;
    }
    .nudge-left--7-ns {
        position: relative;
        left: 7px;
    }
    .nudge-left--8-ns {
        position: relative;
        left: 8px;
    }
    .nudge-left--9-ns {
        position: relative;
        left: 9px;
    }
    .nudge-left--10-ns {
        position: relative;
        left: 10px;
    }
}

@media (--breakpoint-medium) {
    .nudge-top--1-m {
        position: relative;
        top: 1px;
    }
    .nudge-top--2-m {
        position: relative;
        top: 2px;
    }
    .nudge-top--3-m {
        position: relative;
        top: 3px;
    }
    .nudge-top--4-m {
        position: relative;
        top: 4px;
    }
    .nudge-top--5-m {
        position: relative;
        top: 5px;
    }
    .nudge-top--6-m {
        position: relative;
        top: 6px;
    }
    .nudge-top--7-m {
        position: relative;
        top: 7px;
    }
    .nudge-top--8-m {
        position: relative;
        top: 8px;
    }
    .nudge-top--9-m {
        position: relative;
        top: 9px;
    }
    .nudge-top--10-m {
        position: relative;
        top: 10px;
    }

    .nudge-right--1-m {
        position: relative;
        right: 1px;
    }
    .nudge-right--2-m {
        position: relative;
        right: 2px;
    }
    .nudge-right--3-m {
        position: relative;
        right: 3px;
    }
    .nudge-right--4-m {
        position: relative;
        right: 4px;
    }
    .nudge-right--5-m {
        position: relative;
        right: 5px;
    }
    .nudge-right--6-m {
        position: relative;
        right: 6px;
    }
    .nudge-right--7-m {
        position: relative;
        right: 7px;
    }
    .nudge-right--8-m {
        position: relative;
        right: 8px;
    }
    .nudge-right--9-m {
        position: relative;
        right: 9px;
    }
    .nudge-right--10-m {
        position: relative;
        right: 10px;
    }

    .nudge-bottom--1-m {
        position: relative;
        bottom: 1px;
    }
    .nudge-bottom--2-m {
        position: relative;
        bottom: 2px;
    }
    .nudge-bottom--3-m {
        position: relative;
        bottom: 3px;
    }
    .nudge-bottom--4-m {
        position: relative;
        bottom: 4px;
    }
    .nudge-bottom--5-m {
        position: relative;
        bottom: 5px;
    }
    .nudge-bottom--6-m {
        position: relative;
        bottom: 6px;
    }
    .nudge-bottom--7-m {
        position: relative;
        bottom: 7px;
    }
    .nudge-bottom--8-m {
        position: relative;
        bottom: 8px;
    }
    .nudge-bottom--9-m {
        position: relative;
        bottom: 9px;
    }
    .nudge-bottom--10-m {
        position: relative;
        bottom: 10px;
    }

    .nudge-left--1-m {
        position: relative;
        left: 1px;
    }
    .nudge-left--2-m {
        position: relative;
        left: 2px;
    }
    .nudge-left--3-m {
        position: relative;
        left: 3px;
    }
    .nudge-left--4-m {
        position: relative;
        left: 4px;
    }
    .nudge-left--5-m {
        position: relative;
        left: 5px;
    }
    .nudge-left--6-m {
        position: relative;
        left: 6px;
    }
    .nudge-left--7-m {
        position: relative;
        left: 7px;
    }
    .nudge-left--8-m {
        position: relative;
        left: 8px;
    }
    .nudge-left--9-m {
        position: relative;
        left: 9px;
    }
    .nudge-left--10-m {
        position: relative;
        left: 10px;
    }
}

@media (--breakpoint-large) {
    .nudge-top--1-l {
        position: relative;
        top: 1px;
    }
    .nudge-top--2-l {
        position: relative;
        top: 2px;
    }
    .nudge-top--3-l {
        position: relative;
        top: 3px;
    }
    .nudge-top--4-l {
        position: relative;
        top: 4px;
    }
    .nudge-top--5-l {
        position: relative;
        top: 5px;
    }
    .nudge-top--6-l {
        position: relative;
        top: 6px;
    }
    .nudge-top--7-l {
        position: relative;
        top: 7px;
    }
    .nudge-top--8-l {
        position: relative;
        top: 8px;
    }
    .nudge-top--9-l {
        position: relative;
        top: 9px;
    }
    .nudge-top--10-l {
        position: relative;
        top: 10px;
    }

    .nudge-right--1-l {
        position: relative;
        right: 1px;
    }
    .nudge-right--2-l {
        position: relative;
        right: 2px;
    }
    .nudge-right--3-l {
        position: relative;
        right: 3px;
    }
    .nudge-right--4-l {
        position: relative;
        right: 4px;
    }
    .nudge-right--5-l {
        position: relative;
        right: 5px;
    }
    .nudge-right--6-l {
        position: relative;
        right: 6px;
    }
    .nudge-right--7-l {
        position: relative;
        right: 7px;
    }
    .nudge-right--8-l {
        position: relative;
        right: 8px;
    }
    .nudge-right--9-l {
        position: relative;
        right: 9px;
    }
    .nudge-right--10-l {
        position: relative;
        right: 10px;
    }

    .nudge-bottom--1-l {
        position: relative;
        bottom: 1px;
    }
    .nudge-bottom--2-l {
        position: relative;
        bottom: 2px;
    }
    .nudge-bottom--3-l {
        position: relative;
        bottom: 3px;
    }
    .nudge-bottom--4-l {
        position: relative;
        bottom: 4px;
    }
    .nudge-bottom--5-l {
        position: relative;
        bottom: 5px;
    }
    .nudge-bottom--6-l {
        position: relative;
        bottom: 6px;
    }
    .nudge-bottom--7-l {
        position: relative;
        bottom: 7px;
    }
    .nudge-bottom--8-l {
        position: relative;
        bottom: 8px;
    }
    .nudge-bottom--9-l {
        position: relative;
        bottom: 9px;
    }
    .nudge-bottom--10-l {
        position: relative;
        bottom: 10px;
    }

    .nudge-left--1-l {
        position: relative;
        left: 1px;
    }
    .nudge-left--2-l {
        position: relative;
        left: 2px;
    }
    .nudge-left--3-l {
        position: relative;
        left: 3px;
    }
    .nudge-left--4-l {
        position: relative;
        left: 4px;
    }
    .nudge-left--5-l {
        position: relative;
        left: 5px;
    }
    .nudge-left--6-l {
        position: relative;
        left: 6px;
    }
    .nudge-left--7-l {
        position: relative;
        left: 7px;
    }
    .nudge-left--8-l {
        position: relative;
        left: 8px;
    }
    .nudge-left--9-l {
        position: relative;
        left: 9px;
    }
    .nudge-left--10-l {
        position: relative;
        left: 10px;
    }
}
