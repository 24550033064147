/* Alerts
/* ---------------------------------------------------------- */

.gh-box {
    position: relative;
    margin-top: 3px;
    padding: 12px 10px 14px 40px;
    border: 1px solid var(--blue);
    color: var(--darkgrey);
    line-height: 1.55em;
    letter-spacing: 0.2px;
    background: color-mod(var(--blue) a(4%));
    border-radius: 3px;
}

.gh-box a {
    color: var(--black);
    font-weight: 600;
}

.gh-box svg:first-of-type {
    position: absolute;
    left: 12px;
    width: auto;
}

.gh-box-tip svg:first-of-type {
    top: 12px;
    height: 2rem;
    fill: var(--blue);
}

.gh-box-alert {
    border-color: var(--yellow);
    background: color-mod(var(--yellow) a(4%));
}

.gh-box-alert svg:first-of-type {
    top: 14px;
    height: 1.8rem;
    fill: color-mod(var(--yellow) l(-8%) s(+10%));
}

.gh-box-error {
    border: 1px solid var(--red);
    background: color-mod(var(--red) a(3%));
}

.gh-box-error svg:first-of-type {
    top: 14px;
    height: 1.8rem;
    fill: var(--red);
}

.gh-content-box {
    line-height: 1.4em;
    border: none;
    background: var(--main-color-content-greybg);
    border-radius: 3px;
}

.gh-content-box.pa { padding: 16px; }
.gh-content-box.pt { padding-top: 16px; }
.gh-content-box.pr { padding-right: 16px; }
.gh-content-box.pb { padding-bottom: 16px; }
.gh-content-box.pl { padding-left: 16px; }