/* Packages - Themes / Integrations
/* ---------------------------------------------------------- */

.package-filter {
    border-radius: 5px;
}
@media (max-width: 1460px) {
    .package-filter {
        max-width: 700px;
    }
}


/* Main Layout
/* ---------------------------------------------------------- */

.package-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: space-between;
    margin: -10px -10px 4vw -10px;
    max-width: 1200px;
}

/* 3 col themes */
.package-grid-themes .package-grid-cell {
    flex: 0 0 33.3333%;
}

/* 2 col themes */
@media (max-width: 1240px) {
    .package-grid-themes .package-grid-cell {
        flex: 0 0 100%;
    }
}

/* 1 col themes */
@media (max-width: 800px) {
    .package-grid-themes .package-grid-cell {
        flex: 1 1 100%;
    }
}

/* 2 col apps */
.package-grid-apps .package-grid-cell {
    flex: 0 0 100%;
}

/* 1 col apps */
@media (max-width: 1200px) {
    .package-grid-apps .package-grid-cell {
        flex: 1 1 100%;
    }
}


/* Package Card Theme
/* ---------------------------------------------------------- */

.package-card-theme {
    overflow: hidden;
    margin: 10px;
    border: rgba(0,0,0,0.1) 1px solid;
    border-radius: 5px;
}

.package-index .package-card-theme,
.package-featured .package-card-theme {
    flex: 1 1 240px;
}

.package-card-theme-image {
    position: relative;
    display: block;
}

.package-card-theme-image:hover img {
    filter: grayscale(0.5) blur(1px);

    -webkit-filter: grayscale(0.5) blur(1px);
}

.package-card-theme-image:hover .package-card-theme-overlay {
    opacity: 1;
    transition: all 0.2s ease;
}

.package-card-theme-image img {
    display: block;
    max-width: 100%;
    line-height: 0;
}

.package-card-theme-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    background: rgba(0,20,40,0.2);
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.package-card-theme-title {
    color: #fff;
    font-size: 2rem;
    line-height: 1.15em;
    font-weight: 300;
}

.package-card-theme .package-card-footer {
    margin: 0;
    padding: 16px 20px;
    border-top: rgba(0,0,0,0.1) 1px solid;
}

/* Package Card App
/* ---------------------------------------------------------- */

.package-card-app {
    overflow: hidden;
    margin: 10px;
    padding: 14px;
    height: 75px;
    /*max-width: 700px;*/
    border: rgba(0,0,0,0.1) 1px solid;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.package-card-app:hover {
    background: rgba(0,20,60,0.03);
    cursor: pointer;
    transition: background 0.1s ease;
}

.package-card-content {
    position: relative;
    display: flex;
}

.package-card-content .gh-btn {
    position: absolute;
    right: 20px;
}

.package-card-app-icon {
    flex: 0 0 47px;
    margin: 0 15px 0 0;
    width: 47px;
    height: 47px;
    background-position: center center;
    background-size: cover;
    border-radius: 15%;
}

.package-card-meta {
    position: relative;
    display: flex;
    flex-direction: column;
}

.package-card-app-title {
    overflow: hidden;
    margin: 0 0 4px 0;
    padding: 0 70px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.7rem;
    font-weight: normal;
}


/* Package Card Meta
/* ---------------------------------------------------------- */

.package-card-stats {
    position: absolute;
    top: -5px;
    right: 0;
    display: flex;
    align-items: center;
}

.package-downloads {
    display: flex;
    align-items: center;
    height: 26px;
    border: transparent 1px solid;
    color: var(--midgrey);
    font-size: 13px;
    line-height: 24px;
}

.package-downloads:hover {
    cursor: default;
}

.package-downloads svg {
    margin-right: 5px;
    height: 15px;
}

.package-download-count {
    font-size: 13px;
}

.package-card-app-desc {
    display: -webkit-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    max-height: 4.2rem;
    color: var(--midgrey);
    text-overflow: ellipsis;
    font-size: 1.4rem;
    line-height: 1.3em;
    font-weight: 300;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
@media (min-width: 600px) and (max-width: 1460px) {
    .package-card-app-desc {
        padding-right: 80px;
    }
}


/* Package Card Footer
/* ---------------------------------------------------------- */

.package-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}

.package-developer {
    display: flex;
    align-items: center;
    color: var(--midgrey);
}

.package-developer:hover {
    color: var(--blue);
}

.package-developer img {
    flex-shrink: 0;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.package-developer-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.4rem;
}

.package-controls {
    flex-shrink: 0;
    display: flex;
    overflow: hidden;
    border: rgba(0,0,0,0.1) 1px solid;
    border-radius: 4px;
}

.package-controls-button {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    border-left: rgba(0,0,0,0.1) 1px solid;
    background: #fff;
    color: var(--midgrey);
    font-size: 1.3rem;
    line-height: 1;
    transition: none;
}

.package-controls-button:first-child {
    border: none;
}

.package-controls-button:hover {
    color: var(--darkgrey);
}

.package-controls-button svg {
    margin-right: 5px;
    width: 11px;
    height: 11px;
}

.package-disable {
    border-right: var(--green) 3px solid;
}

.package-enable {
    border-right: var(--red) 3px solid;
}


/* Media Queries
/* ---------------------------------------------------------- */

@media (max-width: 800px) {
    .package-grid-apps {
        overflow: hidden;
        margin: 0 0 4vw 0;
        border: #dfe1e3 1px solid;
        border-radius: 5px;
    }

    .package-card-app {
        margin: 0;
        border: none;
        border-top: #dfe1e3 1px solid;
        border-radius: 0;
    }
    .package-grid-cell:first-of-type .package-card-app {
        border-top: none;
    }
}

@media (max-width: 760px) {
    .package-card-app {
        padding: 15px;
    }
    .package-card-app .package-developer {
        display: none;
    }
    .package-card-app .package-card-footer {
        justify-content: flex-end;
    }
    .package-card-theme .package-card-footer {
        margin: 0;
        padding: 15px;
    }
}

@media (max-width: 600px) {
    .package-grid {
        margin: -10px -10px 4vw -10px;
        border: none;
    }
    .package-grid-apps {
        margin: -10px -20px 4vw -20px;
    }
}

@media (max-width: 540px) {
    .package-card-footer {
        justify-content: flex-end;
    }
    .package-card-app .package-card-footer {
        flex-direction: column;
        align-items: flex-start;
    }
    .package-card-footer .package-developer {
        display: none;
    }
}
