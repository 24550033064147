/* Text colors */

.hover-blue:hover,
.hover-blue:focus { color: var(--blue); }
.hover-green:hover,
.hover-green:focus { color: var(--green); }
.hover-purple:hover,
.hover-purple:focus { color: var(--purple); }
.hover-yellow:hover,
.hover-yellow:focus { color: var(--yellow); }
.hover-red:hover,
.hover-red:focus { color: var(--red); }
.hover-pink:hover,
.hover-pink:focus { color: var(--pink); }
.hover-white:hover,
.hover-white:focus { color: var(--white); }

.hover-white-10:hover,
.hover-white-10:focus { color: var(--white-10); }
.hover-white-20:hover,
.hover-white-20:focus { color: var(--white-20); }
.hover-white-30:hover,
.hover-white-30:focus { color: var(--white-30); }
.hover-white-40:hover,
.hover-white-40:focus { color: var(--white-40); }
.hover-white-50:hover,
.hover-white-50:focus { color: var(--white-50); }
.hover-white-60:hover,
.hover-white-60:focus { color: var(--white-60); }
.hover-white-70:hover,
.hover-white-70:focus { color: var(--white-70); }
.hover-white-80:hover,
.hover-white-80:focus { color: var(--white-80); }
.hover-white-90:hover,
.hover-white-90:focus { color: var(--white-90); }

.hover-black-10:hover,
.hover-black-10:focus { color: var(--black-10); }
.hover-black-20:hover,
.hover-black-20:focus { color: var(--black-20); }
.hover-black-30:hover,
.hover-black-30:focus { color: var(--black-30); }
.hover-black-40:hover,
.hover-black-40:focus { color: var(--black-40); }
.hover-black-50:hover,
.hover-black-50:focus { color: var(--black-50); }
.hover-black-60:hover,
.hover-black-60:focus { color: var(--black-60); }
.hover-black-70:hover,
.hover-black-70:focus { color: var(--black-70); }
.hover-black-80:hover,
.hover-black-80:focus { color: var(--black-80); }
.hover-black-90:hover,
.hover-black-90:focus { color: var(--black-90); }

.hover-darkgrey:hover,
.hover-darkgrey:focus { color: var(--darkgrey); }
.hover-middarkgrey:hover,
.hover-middarkgrey:focus { color: var(--middarkgrey); }
.hover-midgrey:hover,
.hover-midgrey:focus { color: var(--midgrey); }
.hover-midlightgrey:hover,
.hover-midlightgrey:focus { color: var(--midlightgrey); }
.hover-lightgrey:hover,
.hover-lightgrey:focus { color: var(--lightgrey); }
.hover-whitegrey:hover,
.hover-whitegrey:focus { color: var(--whitegrey); }

/* Shades */
.hover-blue-l3:hover,
.hover-blue-l3:focus { color: var(--blue-l3); }
.hover-blue-l2:hover,
.hover-blue-l2:focus { color: var(--blue-l2); }
.hover-blue-l1:hover,
.hover-blue-l1:focus { color: var(--blue-l1); }
.hover-blue-d1:hover,
.hover-blue-d1:focus { color: var(--blue-d1); }
.hover-blue-d2:hover,
.hover-blue-d2:focus { color: var(--blue-d2); }
.hover-blue-d3:hover,
.hover-blue-d3:focus { color: var(--blue-d3); }

.hover-green-l3:hover,
.hover-green-l3:focus { color: var(--green-l3); }
.hover-green-l2:hover,
.hover-green-l2:focus { color: var(--green-l2); }
.hover-green-l1:hover,
.hover-green-l1:focus { color: var(--green-l1); }
.hover-green-d1:hover,
.hover-green-d1:focus { color: var(--green-d1); }
.hover-green-d2:hover,
.hover-green-d2:focus { color: var(--green-d2); }
.hover-green-d3:hover,
.hover-green-d3:focus { color: var(--green-d3); }

.hover-purple-l3:hover,
.hover-purple-l3:focus { color: var(--purple-l3); }
.hover-purple-l2:hover,
.hover-purple-l2:focus { color: var(--purple-l2); }
.hover-purple-l1:hover,
.hover-purple-l1:focus { color: var(--purple-l1); }
.hover-purple-d1:hover,
.hover-purple-d1:focus { color: var(--purple-d1); }
.hover-purple-d2:hover,
.hover-purple-d2:focus { color: var(--purple-d2); }
.hover-purple-d3:hover,
.hover-purple-d3:focus { color: var(--purple-d3); }

.hover-yellow-l3:hover,
.hover-yellow-l3:focus { color: var(--yellow-l3); }
.hover-yellow-l2:hover,
.hover-yellow-l2:focus { color: var(--yellow-l2); }
.hover-yellow-l1:hover,
.hover-yellow-l1:focus { color: var(--yellow-l1); }
.hover-yellow-d1:hover,
.hover-yellow-d1:focus { color: var(--yellow-d1); }
.hover-yellow-d2:hover,
.hover-yellow-d2:focus { color: var(--yellow-d2); }
.hover-yellow-d3:hover,
.hover-yellow-d3:focus { color: var(--yellow-d3); }

.hover-red-l3:hover,
.hover-red-l3:focus { color: var(--red-l3); }
.hover-red-l2:hover,
.hover-red-l2:focus { color: var(--red-l2); }
.hover-red-l1:hover,
.hover-red-l1:focus { color: var(--red-l1); }
.hover-red-d1:hover,
.hover-red-d1:focus { color: var(--red-d1); }
.hover-red-d2:hover,
.hover-red-d2:focus { color: var(--red-d2); }
.hover-red-d3:hover,
.hover-red-d3:focus { color: var(--red-d3); }

.hover-pink-l3:hover,
.hover-pink-l3:focus { color: var(--pink-l3); }
.hover-pink-l2:hover,
.hover-pink-l2:focus { color: var(--pink-l2); }
.hover-pink-l1:hover,
.hover-pink-l1:focus { color: var(--pink-l1); }
.hover-pink-d1:hover,
.hover-pink-d1:focus { color: var(--pink-d1); }
.hover-pink-d2:hover,
.hover-pink-d2:focus { color: var(--pink-d2); }
.hover-pink-d3:hover,
.hover-pink-d3:focus { color: var(--pink-d3); }

.hover-darkgrey-l2:hover,
.hover-darkgrey-l2:focus { color: var(--darkgrey-l2); }
.hover-darkgrey-l1:hover,
.hover-darkgrey-l1:focus { color: var(--darkgrey-l1); }
.hover-darkgrey-d1:hover,
.hover-darkgrey-d1:focus { color: var(--darkgrey-d1); }
.hover-darkgrey-d2:hover,
.hover-darkgrey-d2:focus { color: var(--darkgrey-d2); }

.hover-middarkgrey-l2:hover,
.hover-middarkgrey-l2:focus { color: var(--middarkgrey-l2); }
.hover-middarkgrey-l1:hover,
.hover-middarkgrey-l1:focus { color: var(--middarkgrey-l1); }
.hover-middarkgrey-d1:hover,
.hover-middarkgrey-d1:focus { color: var(--middarkgrey-d1); }
.hover-middarkgrey-d2:hover,
.hover-middarkgrey-d2:focus { color: var(--middarkgrey-d2); }

.hover-midgrey-l2:hover,
.hover-midgrey-l2:focus { color: var(--midgrey-l2); }
.hover-midgrey-l1:hover,
.hover-midgrey-l1:focus { color: var(--midgrey-l1); }
.hover-midgrey-d1:hover,
.hover-midgrey-d1:focus { color: var(--midgrey-d1); }
.hover-midgrey-d2:hover,
.hover-midgrey-d2:focus { color: var(--midgrey-d2); }

.hover-midlightgrey-l2:hover,
.hover-midlightgrey-l2:focus { color: var(--midlightgrey-l2); }
.hover-midlightgrey-l1:hover,
.hover-midlightgrey-l1:focus { color: var(--midlightgrey-l1); }
.hover-midlightgrey-d1:hover,
.hover-midlightgrey-d1:focus { color: var(--midlightgrey-d1); }
.hover-midlightgrey-d2:hover,
.hover-midlightgrey-d2:focus { color: var(--midlightgrey-d2); }

.hover-lightgrey-l2:hover,
.hover-lightgrey-l2:focus { color: var(--lightgrey-l2); }
.hover-lightgrey-l1:hover,
.hover-lightgrey-l1:focus { color: var(--lightgrey-l1); }
.hover-lightgrey-d1:hover,
.hover-lightgrey-d1:focus { color: var(--lightgrey-d1); }
.hover-lightgrey-d2:hover,
.hover-lightgrey-d2:focus { color: var(--lightgrey-d2); }

.hover-whitegrey-l2:hover,
.hover-whitegrey-l2:focus { color: var(--whitegrey-l2); }
.hover-whitegrey-l1:hover,
.hover-whitegrey-l1:focus { color: var(--whitegrey-l1); }
.hover-whitegrey-d1:hover,
.hover-whitegrey-d1:focus { color: var(--whitegrey-d1); }
.hover-whitegrey-d2:hover,
.hover-whitegrey-d2:focus { color: var(--whitegrey-d2); }

.hover-color-inherit:hover,
.hover-color-inherit:focus { color: inherit; }

/* Background colors */
.hover-bg-blue:hover,
.hover-bg-blue:focus { background-color: var(--blue); }
.hover-bg-green:hover,
.hover-bg-green:focus { background-color: var(--green); }
.hover-bg-purple:hover,
.hover-bg-purple:focus { background-color: var(--purple); }
.hover-bg-yellow:hover,
.hover-bg-yellow:focus { background-color: var(--yellow); }
.hover-bg-red:hover,
.hover-bg-red:focus { background-color: var(--red); }
.hover-bg-pink:hover,
.hover-bg-pink:focus { background-color: var(--pink); }
.hover-bg-white:hover,
.hover-bg-white:focus { background-color: var(--white); }

.hover-bg-white-10:hover,
.hover-bg-white-10:focus { background-color: var(--white-10); }
.hover-bg-white-20:hover,
.hover-bg-white-20:focus { background-color: var(--white-20); }
.hover-bg-white-30:hover,
.hover-bg-white-30:focus { background-color: var(--white-30); }
.hover-bg-white-40:hover,
.hover-bg-white-40:focus { background-color: var(--white-40); }
.hover-bg-white-50:hover,
.hover-bg-white-50:focus { background-color: var(--white-50); }
.hover-bg-white-60:hover,
.hover-bg-white-60:focus { background-color: var(--white-60); }
.hover-bg-white-70:hover,
.hover-bg-white-70:focus { background-color: var(--white-70); }
.hover-bg-white-80:hover,
.hover-bg-white-80:focus { background-color: var(--white-80); }
.hover-bg-white-90:hover,
.hover-bg-white-90:focus { background-color: var(--white-90); }

.hover-bg-black-10:hover,
.hover-bg-black-10:focus { background-color: var(--black-10); }
.hover-bg-black-20:hover,
.hover-bg-black-20:focus { background-color: var(--black-20); }
.hover-bg-black-30:hover,
.hover-bg-black-30:focus { background-color: var(--black-30); }
.hover-bg-black-40:hover,
.hover-bg-black-40:focus { background-color: var(--black-40); }
.hover-bg-black-50:hover,
.hover-bg-black-50:focus { background-color: var(--black-50); }
.hover-bg-black-60:hover,
.hover-bg-black-60:focus { background-color: var(--black-60); }
.hover-bg-black-70:hover,
.hover-bg-black-70:focus { background-color: var(--black-70); }
.hover-bg-black-80:hover,
.hover-bg-black-80:focus { background-color: var(--black-80); }
.hover-bg-black-90:hover,
.hover-bg-black-90:focus { background-color: var(--black-90); }

.hover-bg-darkgrey:hover,
.hover-bg-darkgrey:focus { background-color: var(--darkgrey); }
.hover-bg-middarkgrey:hover,
.hover-bg-middarkgrey:focus { background-color: var(--middarkgrey); }
.hover-bg-midgrey:hover,
.hover-bg-midgrey:focus { background-color: var(--midgrey); }
.hover-bg-midlightgrey:hover,
.hover-bg-midlightgrey:focus { background-color: var(--midlightgrey); }
.hover-bg-lightgrey:hover,
.hover-bg-lightgrey:focus { background-color: var(--lightgrey); }
.hover-bg-whitegrey:hover,
.hover-bg-whitegrey:focus { background-color: var(--whitegrey); }

/* Shades */
.hover-bg-blue-l3:hover,
.hover-bg-blue-l3:focus { background-color: var(--blue-l3); }
.hover-bg-blue-l2:hover,
.hover-bg-blue-l2:focus { background-color: var(--blue-l2); }
.hover-bg-blue-l1:hover,
.hover-bg-blue-l1:focus { background-color: var(--blue-l1); }
.hover-bg-blue-d1:hover,
.hover-bg-blue-d1:focus { background-color: var(--blue-d1); }
.hover-bg-blue-d2:hover,
.hover-bg-blue-d2:focus { background-color: var(--blue-d2); }
.hover-bg-blue-d3:hover,
.hover-bg-blue-d3:focus { background-color: var(--blue-d3); }

.hover-bg-green-l3:hover,
.hover-bg-green-l3:focus { background-color: var(--green-l3); }
.hover-bg-green-l2:hover,
.hover-bg-green-l2:focus { background-color: var(--green-l2); }
.hover-bg-green-l1:hover,
.hover-bg-green-l1:focus { background-color: var(--green-l1); }
.hover-bg-green-d1:hover,
.hover-bg-green-d1:focus { background-color: var(--green-d1); }
.hover-bg-green-d2:hover,
.hover-bg-green-d2:focus { background-color: var(--green-d2); }
.hover-bg-green-d3:hover,
.hover-bg-green-d3:focus { background-color: var(--green-d3); }

.hover-bg-purple-l3:hover,
.hover-bg-purple-l3:focus { background-color: var(--purple-l3); }
.hover-bg-purple-l2:hover,
.hover-bg-purple-l2:focus { background-color: var(--purple-l2); }
.hover-bg-purple-l1:hover,
.hover-bg-purple-l1:focus { background-color: var(--purple-l1); }
.hover-bg-purple-d1:hover,
.hover-bg-purple-d1:focus { background-color: var(--purple-d1); }
.hover-bg-purple-d2:hover,
.hover-bg-purple-d2:focus { background-color: var(--purple-d2); }
.hover-bg-purple-d3:hover,
.hover-bg-purple-d3:focus { background-color: var(--purple-d3); }

.hover-bg-yellow-l3:hover,
.hover-bg-yellow-l3:focus { background-color: var(--yellow-l3); }
.hover-bg-yellow-l2:hover,
.hover-bg-yellow-l2:focus { background-color: var(--yellow-l2); }
.hover-bg-yellow-l1:hover,
.hover-bg-yellow-l1:focus { background-color: var(--yellow-l1); }
.hover-bg-yellow-d1:hover,
.hover-bg-yellow-d1:focus { background-color: var(--yellow-d1); }
.hover-bg-yellow-d2:hover,
.hover-bg-yellow-d2:focus { background-color: var(--yellow-d2); }
.hover-bg-yellow-d3:hover,
.hover-bg-yellow-d3:focus { background-color: var(--yellow-d3); }

.hover-bg-red-l3:hover,
.hover-bg-red-l3:focus { background-color: var(--red-l3); }
.hover-bg-red-l2:hover,
.hover-bg-red-l2:focus { background-color: var(--red-l2); }
.hover-bg-red-l1:hover,
.hover-bg-red-l1:focus { background-color: var(--red-l1); }
.hover-bg-red-d1:hover,
.hover-bg-red-d1:focus { background-color: var(--red-d1); }
.hover-bg-red-d2:hover,
.hover-bg-red-d2:focus { background-color: var(--red-d2); }
.hover-bg-red-d3:hover,
.hover-bg-red-d3:focus { background-color: var(--red-d3); }

.hover-bg-pink-l3:hover,
.hover-bg-pink-l3:focus { background-color: var(--pink-l3); }
.hover-bg-pink-l2:hover,
.hover-bg-pink-l2:focus { background-color: var(--pink-l2); }
.hover-bg-pink-l1:hover,
.hover-bg-pink-l1:focus { background-color: var(--pink-l1); }
.hover-bg-pink-d1:hover,
.hover-bg-pink-d1:focus { background-color: var(--pink-d1); }
.hover-bg-pink-d2:hover,
.hover-bg-pink-d2:focus { background-color: var(--pink-d2); }
.hover-bg-pink-d3:hover,
.hover-bg-pink-d3:focus { background-color: var(--pink-d3); }

.hover-bg-darkgrey-l2:hover,
.hover-bg-darkgrey-l2:focus { background-color: var(--darkgrey-l2); }
.hover-bg-darkgrey-l1:hover,
.hover-bg-darkgrey-l1:focus { background-color: var(--darkgrey-l1); }
.hover-bg-darkgrey-d1:hover,
.hover-bg-darkgrey-d1:focus { background-color: var(--darkgrey-d1); }
.hover-bg-darkgrey-d2:hover,
.hover-bg-darkgrey-d2:focus { background-color: var(--darkgrey-d2); }

.hover-bg-middarkgrey-l2:hover,
.hover-bg-middarkgrey-l2:focus { background-color: var(--middarkgrey-l2); }
.hover-bg-middarkgrey-l1:hover,
.hover-bg-middarkgrey-l1:focus { background-color: var(--middarkgrey-l1); }
.hover-bg-middarkgrey-d1:hover,
.hover-bg-middarkgrey-d1:focus { background-color: var(--middarkgrey-d1); }
.hover-bg-middarkgrey-d2:hover,
.hover-bg-middarkgrey-d2:focus { background-color: var(--middarkgrey-d2); }

.hover-bg-midgrey-l2:hover,
.hover-bg-midgrey-l2:focus { background-color: var(--midgrey-l2); }
.hover-bg-midgrey-l1:hover,
.hover-bg-midgrey-l1:focus { background-color: var(--midgrey-l1); }
.hover-bg-midgrey-d1:hover,
.hover-bg-midgrey-d1:focus { background-color: var(--midgrey-d1); }
.hover-bg-midgrey-d2:hover,
.hover-bg-midgrey-d2:focus { background-color: var(--midgrey-d2); }

.hover-bg-midlightgrey-l2:hover,
.hover-bg-midlightgrey-l2:focus { background-color: var(--midlightgrey-l2); }
.hover-bg-midlightgrey-l1:hover,
.hover-bg-midlightgrey-l1:focus { background-color: var(--midlightgrey-l1); }
.hover-bg-midlightgrey-d1:hover,
.hover-bg-midlightgrey-d1:focus { background-color: var(--midlightgrey-d1); }
.hover-bg-midlightgrey-d2:hover,
.hover-bg-midlightgrey-d2:focus { background-color: var(--midlightgrey-d2); }

.hover-bg-lightgrey-l2:hover,
.hover-bg-lightgrey-l2:focus { background-color: var(--lightgrey-l2); }
.hover-bg-lightgrey-l1:hover,
.hover-bg-lightgrey-l1:focus { background-color: var(--lightgrey-l1); }
.hover-bg-lightgrey-d1:hover,
.hover-bg-lightgrey-d1:focus { background-color: var(--lightgrey-d1); }
.hover-bg-lightgrey-d2:hover,
.hover-bg-lightgrey-d2:focus { background-color: var(--lightgrey-d2); }

.hover-bg-whitegrey-l2:hover,
.hover-bg-whitegrey-l2:focus { background-color: var(--whitegrey-l2); }
.hover-bg-whitegrey-l1:hover,
.hover-bg-whitegrey-l1:focus { background-color: var(--whitegrey-l1); }
.hover-bg-whitegrey-d1:hover,
.hover-bg-whitegrey-d1:focus { background-color: var(--whitegrey-d1); }
.hover-bg-whitegrey-d2:hover,
.hover-bg-whitegrey-d2:focus { background-color: var(--whitegrey-d2); }

/* Special */
.hover-bg-table-cell:hover,
.hover-bg-table-cell:focus {
    background-color: color-mod(var(--lightgrey) l(+6%) s(-2%));
}