/* Sign in
/* ---------------------------------------------------------- */
.gh-signin, .gh-auth-email {
    position: relative;
    margin: 30px auto;
    padding: 40px;
    max-width: 620px;
    width: 100%;
    text-align: left;
}

.gh-signin header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gh-signin .gh-site-icon {
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
    background-color: transparent;
    background-size: 70px;
    border-radius: 3px;
}

.gh-signin h1 {
    font-size: 4.1rem;
    font-weight: 600;
    letter-spacing: -.025em;
    line-height: 1.15em;
    font-weight: 700;
    color: var(--black);
    max-width: 620px;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.gh-signin .form-group {
    margin-bottom: 1.5rem;
}

.gh-signin .gh-btn {
    margin: 0;
}

.gh-signin .gh-btn-login,
.gh-signin .gh-btn-reset {
    height: 54px;
    border-radius: 8px;
    line-height: 54px;
    margin-top: 40px;
    max-width: unset;
    font-weight: 300;
    transition: all 0.4s ease;
    -webkit-font-smoothing: subpixel-antialiased;
}

.gh-signin .gh-btn-login span,
.gh-signin .gh-btn-reset span {
    font-size: 1.8rem;
    color: #fff;
}

.gh-signin .gh-btn-reset {
    background: var(--black) !important;
    margin-top: 20px;
}

.gh-signin .gh-btn-login svg.gh-icon-spinner rect {
    fill: #fff !important;
}

.gh-signin .gh-btn-login:hover {
    opacity: 0.9;
}

.gh-signin label {
    font-size: 1.3rem;
    font-weight: 600 !important;
}

.forgotten-wrap {
    position: relative;
}

.forgotten-wrap input {
    padding-right: 7rem;
}

.forgotten-wrap .forgotten-link {
    position: absolute;
    top: 12px;
    right: 1px;
    bottom: 12px;
    border-left: color-mod(var(--lightgrey) l(-5%) s(-10%)) 1px solid;
    border-radius: 0;
}

.forgotten-link span {
    height: 19px;
    font-size: 1.4rem;
    line-height: 19px;
    padding: 0 15px 0 16px;
    margin-top: -1px;
    color: var(--midlightgrey);
}

.forgotten-link:hover {
    border-left: color-mod(var(--lightgrey) l(-15%) s(-10%)) 1px solid;
    text-decoration: none;
}

.forgotten-link:hover span {
    color: var(--black);
}

.forgotten-link:active {
    box-shadow: none;
    text-decoration: underline;
}

.gh-signin input,
.gh-signin input:-webkit-autofill::first-line {
    padding: 12px 16px;
    height: 54px;
    border-radius: 8px;
    font-size: 1.8rem;
}

.gh-signin input:focus {
    border-color: var(--midgrey) !important;
}

/* Email notification */
/* ---------------------------------------------------------- */
.gh-auth-animation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid var(--lightgrey);
    animation: 0.5s forwards 0.6s containerFadeIn;
    opacity: 0;
}

@keyframes containerFadeIn {
    0% { 
        opacity: 0;
    }
    100% { 
        opacity: 1;
    }
}

.gh-auth-email-animation {
    position: relative;
    width: 122px;
    height: 125px;
    margin-bottom: -24px;
    animation: 0.5s forwards 0.6s envelopeFadeIn;
    opacity: 0;
}

@keyframes envelopeFadeIn {
    0% { 
        transform: translateY(-6px);
        opacity: 0;
    }
    100% { 
        transform: translateY(0);
        opacity: 1;
    }
}

.gh-auth-email-animation .gh-auth-envelope-back {
    position: absolute;
    top: 0;
    left: 0;
}

.gh-auth-email-animation .gh-auth-envelope-front {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 100;
}

.gh-auth-email-animation .gh-auth-paper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 40px;
    left: 15px;
    width: 90px;
    height: 82px;
    background: white;
    border: 1px solid #C5D2D9;
    border-radius: 4px;
    animation: 1.2s ease forwards 1.15s paperIn;
}

.gh-auth-email-animation .gh-auth-lock {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    opacity: 0;
    animation: 0.45s forwards 1.35s lockIn;
}

@keyframes paperIn {
    0% { 
        transform: scale(1,1) translateY(0); 
    }
    10% { 
        transform: scale(1.05,.95) translateY(0); 
    }
    30% { 
        transform: scale(.95,1.05) translateY(-32px); 
    }
    50% { 
        transform: scale(1,1) translateY(-27px); 
    }
    100% { 
        transform: scale(1,1) translateY(-27px); 
    }
}

@keyframes lockIn {
    0% { 
        transform: scale(1) translateY(2px); 
        opacity: 0;
    }
    60% { 
        transform: scale(1.1) translateY(-2px); 
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}

.gh-auth-lock-body {
    margin-top: 48px;
    animation: 0.5s forwards 0.2s bodyFadeIn;
    opacity: 0;
}

.gh-auth-lock-body p {
    color: var(--midgrey);
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4em;
    text-align: center;
}

@keyframes bodyFadeIn {
    0% { 
        opacity: 0;
    }
    100% { 
        opacity: 1;
    }
}