/* Unsplash Integration
/* ---------------------------------------------------------- */


/* The parent container + layout
/* ---------------------------------------------------------- */

.gh-unsplash-window {
    padding: 25px;
    background: #fff;
}

.gh-unsplash-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 200px);
    max-width: 1200px;
    margin: 100px auto;
}

.gh-unsplash-logo {
    position: absolute;
    top: 23px;
    left: 25px;
    display: block;
}

.gh-unsplash-logo svg {
    width: 32px;
}

.gh-unsplash-header {
    text-align: center;
}

.gh-unsplash-header .gh-input-icon svg {
    left: 15px;
    fill: #777;
}

.gh-unsplash-header .gh-input-icon {
    display: block;
    max-width: 1000px;
    margin: 50px auto;
}

.gh-unsplash-search {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0 30px 1px 50px;
    outline: none;
    border: 1px solid color-mod(var(--lightgrey) l(-2%));
    color: var(--darkgrey);
    font-size: 14px;
    background-color: #fff;
    border-radius: 20px;
    appearance: none;
}

.gh-unsplash-search:focus {
    border-color: color-mod(var(--lightgrey) l(-10%) s(+6%));
}


/* Loading styles
/* ---------------------------------------------------------- */

.gh-unsplash-loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
}


/* Error styles
/* ---------------------------------------------------------- */

.gh-unsplash-error {
    text-align: center;
}

.gh-unsplash-error-404 {
    display: block;
    min-height: 225px;
    max-width: 300px;
    margin: 0 auto;
}


/* Photo grid and global styles
/* ---------------------------------------------------------- */

.gh-unsplash .gh-loading-spinner {
    display: block;
    margin: 0 auto;
}

.gh-unsplash-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    box-sizing: border-box;
    width: 100%;
}

.gh-unsplash-grid-column {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    margin-right: 24px;
}

.gh-unsplash-grid-column:last-of-type {
    margin-right: 0;
}

.gh-unsplash-photo {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 24px;
    color: #fff;
    cursor: zoom-in;
}

.gh-unsplash-photo-container > img {
    position: absolute;
    display: block;
    height: auto;
    width: 100%;
}

/* Hover overlay */
.gh-unsplash-photo-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: #fff;
    background-image: linear-gradient(180deg,rgba(0,0,0,0.2) 0,transparent 40%,transparent 60%,rgba(0,0,0,0.3));
    opacity: 0;
    transition: all 0.15s ease-in-out;
}

.gh-unsplash-photo:hover .gh-unsplash-photo-overlay {
    opacity: 1;
}

/* Buttons used within photo cards */
.gh-unsplash-button {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 8px 12px;
    color: #777;
    font-size: 1.4rem;
    line-height: 1.1em;
    font-weight: 500;
    background: #fff;
    border-radius: 5px;
    opacity: 0.9;
    transition: all 0.15s ease-in-out;
}

.gh-unsplash-button:hover {
    opacity: 1;
}


/* Photo overlay content
/* ---------------------------------------------------------- */

.gh-unsplash-photo-header {
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gh-unsplash-photo-author {
    display: flex;
    align-items: center;
    min-width: 0;
    font-size: 1.5rem;
    line-height: 1.15em;
}

.gh-unsplash-photo-author-img {
    flex-shrink: 0;
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 100%;
}

.gh-unsplash-photo-author-name {
    display: block;
    overflow: hidden;
    color: #fff;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gh-unsplash-button-likes svg {
    height: 15px;
    margin-right: 5px;
    fill: #ff3f49;
}

.gh-unsplash-photo-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gh-unsplash-button-download svg {
    height: 13px;
    margin: 2px 0 0 0;
    stroke: #777;
    stroke-width: 3px;
}


/* Photo Zoom Preview
/* ---------------------------------------------------------- */

.gh-unsplash-zoom {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 25px;
    background: rgba(255,255,255,0.8);

    backdrop-filter: blur(2px);
}

.gh-unsplash-zoom .gh-unsplash-photo {
    position: relative;
    width: auto;
    min-height: 400px;
    max-width: 1200px;
    max-height: calc(100vh - 50px);
    margin: 0;
    color: #fff;
    cursor: zoom-out;
    background: var(--darkgrey);
    box-shadow: rgba(39,44,49,0.1) 8px 14px 38px, rgba(39, 44, 49, 0.08) 1px 3px 8px;
}

.gh-unsplash-zoom .gh-unsplash-photo-container > img {
    position: static;
    display: block;
    width: auto;
    max-height: calc(100vh - 50px);
}

.gh-unsplash-zoom .gh-unsplash-photo-overlay {
    opacity: 1;
}
