.fullscreen-modal-email-preview {
    margin: 24px;
    max-width: 100%;
}

.fullscreen-modal-email-preview .modal-content {
    position: relative;
    height: 100%;
    padding: 0;
}

.fullscreen-modal-email-preview .modal-body {
    margin: 0;
}

.gh-pe-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 18px 32px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--white);
    z-index: 9999;
}

.gh-pe-header h2 {
    margin: 0;
    margin-right: auto;
    position: absolute;
    left: 24px;
}

.gh-pe-header .gh-btn-group {
    flex: 1;
}

.gh-pe-header-border {
    border-bottom: 1px solid var(--whitegrey);
}

/* .gh-pe-close {
    margin-left: auto;
    flex: 1;
} */

.modal-content .gh-pe-close button {
    stroke: var(--midgrey);
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    top: 25px;
}

.gh-pe-close button:hover {
    opacity: 1.0;
}

.gh-pe-btn-group button {
    width: 100px;
}

.gh-pe-btn-group button span {
    border-radius: 0;
}

.gh-pe-btn-group button:first-of-type span {
    border-right: none;
}

.gh-pe-btn-group button:last-of-type span {
    border-left: none;
}

.fullscreen-modal-email-preview .gh-pe-desktop-container {
    height: calc(100vh - 119px);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--whitegrey-l1);
    padding: 30px 30px 45px;
}

.gh-pe-desktop-container .gh-pe-emailclient-mockup {
    flex-grow: 1;
    overflow: hidden;
    max-width: 740px;
    width: 100%;
    padding: 0;
    background: var(--white);
    box-shadow:
        0 0 0 1px rgba(0,0,0,0.02),
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07);
    ;
    border-radius: 12px;
}

.gh-pe-desktop-container iframe {
    border: none;
    width: 100%;
    height: calc(100% - 67px);
}

.gh-pe-emailclient-sender {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 72px;
    border-bottom: 1px solid var(--whitegrey);
    margin: 0;
    padding: 0 20px;
}

.gh-pe-emailclient-sender p {
    padding: 0;
    margin: 0;
    color: var(--midlightgrey);
    line-height: 1.8em;
}

.gh-pe-emailclient-sender span {
    color: var(--darkgrey);
}

.gh-pe-emailclient-sender span.strong {
    font-weight: 600;
}

.fullscreen-modal-email-preview .gh-pe-mobile-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    background: var(--whitegrey-l1);
    padding: 30px 30px 45px;
    height: calc(100vh - 119px);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gh-pe-mobile-bezel {
    width: 320px;
    height: 657px;
    border-radius: 40px;
    display: flex;
    background: var(--whitegrey-l2);
    box-shadow:
        0 0 0 1px rgba(0,0,0,0.02),
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07);
    ;
}

.gh-pe-mobile-screen {
    width: 100%;
    margin: 14px;
    border: 1px solid var(--whitegrey-l1);
    background: var(--white);
    border-radius: 28px;
    flex-grow: 1;
    overflow: hidden;
}

.gh-pe-mobile-container .gh-pe-iframe {
    padding: 0;
    transform: scale(0.8);
    transform-origin: 0 0;
    overflow-x: hidden;
    width: 361px;
    height: 706px;
}

.gh-pe-mobile-container .gh-pe-iframe .left-col {
    display: none;
}

.gh-pe-mobile-container .gh-pe-emailclient-sender {
    height: 66px;
    padding: 6px 12px 0;
}

.gh-pe-mobile-container .gh-pe-emailclient-sender p {
    font-size: 1.1rem;
    line-height: 1.6em;
}

@media (max-width: 850px) {
    .gh-pe-header {
        flex-direction: column;
    }

    .gh-pe-header h2 {
        display: none
    }
}
