.fullscreen-modal-portal-settings {
    margin: 30px;
    max-width: 100%;
}

.fullscreen-modal-portal-settings .modal-content {
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 0;
}

.fullscreen-modal-portal-settings .modal-body {
    margin: 0;
}

.gh-ps-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 18px 32px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--white);
    z-index: 9999;
}

.gh-ps-header h2 {
    width: calc(50vw - 200px);
    margin: 0;
}

.gh-ps-header-border {
    border-bottom: 1px solid var(--whitegrey);
}

.gh-ps-close {
    width: calc(50vw - 200px);
}

.gh-ps-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.gh-show-modal-link-form .gh-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gh-portal-settings-sidebar {
    padding: 0;
    width: 342px;
}

.gh-portal-form-wrapper {
    overflow: hidden;
    width: 342px;
}

.gh-portal-settings-form {
    min-width: 292px;
    margin: 0 -80px 0 0 !important;
    padding: 4px 100px 0 20px;
    max-height: calc(100vh - 60px - 66px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.gh-portal-settings .form-group.space-l {
    margin-bottom: 1.6em;
}

.gh-portal-setting-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
}

.gh-portal-settings .for-switch.small {
    width: 36px !important;
    height: 22px !important;
}

.gh-portal-setting-sectionheading {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: var(--black);
    margin: 0;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--whitegrey);
}

.gh-portal-setting-section {
    margin: 0 -24px 3.5vw;
    padding: 0 28px;
}

.gh-portal-setting-section p {
    line-height: 1.5em;
}

.gh-portal-settings .input-color input {
    position: relative;
    height: 30px;
    width: 102px;
    padding: 3px 4px 3px 44px;
    font-size: 1.3rem;
}

.gh-portal-settings .input-color::after {
    top: 5px;
    left: 34px;
}

.gh-portal-settings .color-picker-horizontal-divider {
    position: absolute;
    display: block;
    content: "";
    width: 1px;
    top: 0;
    left: 29px;
    bottom: 0;
    background: var(--input-border-color);
}

.gh-portal-settings .input-color input:focus + .color-picker-horizontal-divider {
    top: 2px;
    bottom: 2px;
}

.gh-portal-settings .color-box-container {
    height: 26px;
    width: 26px;
    position: absolute;
    overflow: hidden;
    top: 2px;
    left: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.gh-portal-settings .color-box-container .color-picker {
    position: absolute;
    top: -10px;
    left: -10px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
}

.gh-portal-settings .gh-select svg {
    top: 19px;
    right: 9px;
}

.gh-portal-settings-topbarheight {
    height: 66px;
}

.gh-portal-settings-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    border-left: 1px solid var(--whitegrey);
}

.gh-portal-settings .modal-footer {
    margin-top: 28px;
}

.gh-portal-settings-maintabs {
    list-style: none;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    border: 1px solid color-mod(var(--midgrey) l(+35%) s(+10%));
    border-radius: 5px;
    letter-spacing: 0.2px;
    box-shadow: 0 2px 5px -3px rgba(0,0,0,.12);
}

.gh-portal-settings-maintabs li {
    padding: 0;
    margin: 0;
}

.gh-portal-settings-maintabs li:not(:last-of-type) {
    border-right: 1px solid color-mod(var(--midgrey) l(+35%) s(+10%));
}

.gh-portal-settings-maintabs li a {
    position: relative;
    display: inline-block;
    padding: 3px 10px 4px;
    margin: 4px;
    color: color-mod(var(--midgrey) l(-7%));
    background: var(--white);
    min-width: 56px;
    text-align: center;
    border-radius: 2px;
    outline: none;
    box-sizing: content-box;
    font-size: 1.3rem;
}

.gh-portal-settings-maintabs li.active a {
    color: var(--blue);
    font-weight: 500;
}

.gh-portal-settings-icons {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
}

.gh-portal-setting-first {
    margin: 12px 0 0;
}

.gh-portal-button-icon {
    display: inline-block;
    cursor: pointer;
    width: 38px;
    height: 40px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: center;
    border-radius: 2px;
    margin: 3px;
}

.gh-portal-button-icon:hover {
    box-shadow: 0px 0px 0px 1px color-mod(var(--green) a(40%));
}

.gh-portal-button-icon.selected-icon {
    box-shadow: 0px 0px 0px 2px var(--green);
}

.gh-portal-button-icon svg path {
    stroke: var(--midlightgrey-d1);
}

.gh-portal-button-icon.selected-icon svg path {
    stroke: var(--green);
}

.gh-portal-button-icon .gh-loading-spinner {
    width: 20px;
    height: 20px;
}

.gh-portal-button-icon .gh-loading-spinner:before {
    margin-top: -2px;
}

.gh-portal-button-uploadicon,
.gh-portal-button-uploadicon:hover,
.gh-portal-button-uploadicon:focus {
    height: 44px;
    width: 44px;
    border: none;
    box-shadow: none;
    background: transparent;
    border: 1px dashed var(--lightgrey);
}

.gh-portal-button-uploadicon span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gh-portal-button-uploadicon span svg {
    width: 18px;
    height: 18px;
    fill: var(--darkgrey);
}

.gh-portal-button-uploadicon:hover span svg {
    fill: var(--darkgrey);
}

.gh-portal-button-deleteicon,
.gh-portal-button-deleteicon:hover,
.gh-portal-button-deleteicon:focus {
    height: 44px;
    width: 44px;
    border: none;
    box-shadow: none;
}

.gh-portal-button-deleteicon span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gh-portal-button-deleteicon span svg {
    width: 18px;
    height: 18px;
}

.gh-portal-button-deleteicon:hover span svg {
    fill: var(--white);
}

.gh-portal-setting-copy {
    position: absolute;
    display: flex;
    align-items: center;
    top: 2px;
    right: 2px;
    height: 32px;
    padding: 0 8px 0 9px;
    border-radius: 2px;
    background: var(--whitegrey-l2);
    border-color: transparent;
    box-shadow: none;
    font-size: 1.3rem;
}

.gh-portal-setting-copy span {
    margin-top: -2px;
}

.gh-portal-siteiframe {
    pointer-events: none;
    transform: scale(0.95) !important;
    transform-origin: 0 0;
    width: calc((1 / 0.95) * 100%) !important;
    height: calc((1 / 0.95) * 100%) !important;
}

.gh-portal-site-frame-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #edf0f2;
    overflow: hidden;
    border: none;
}

.gh-portal-settings-title {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin: 0;
    height: 66px;
    font-size: 1.9rem;
    font-weight: 600;
    letter-spacing: -0.1px;
}

.gh-portal-settings-previewheader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 66px;
    padding: 0 24px;
    margin: 0;
    width: 100%;
}

.gh-portal-settings-previewheader .gh-btn-group .gh-btn {
    min-width: 90px;
}

.gh-portal-settings-actions {
    position: absolute;
    top: 16px;
    right: 24px;
    display: flex;
    align-items: center;
}

.gh-portal-preview-wrapper {
    overflow: hidden;
    max-height: calc(100vh - 60px - 66px);
    height: 100%;
    background: #EDF0F2;
}

.gh-portal-preview-container {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    background: #EDF0F2;
    max-height: calc(100vh - 60px - 66px);
}

.gh-portal-preview-wrapper .gh-portal-preview-container {
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0 -70px;
    padding: 0 70px;
    border: none;
    border-radius: 0;
    height: 100%;
}

.gh-portal-preview-container.hide {
    display: none
}

.gh-portal-links-container {
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 1.5rem;
    text-align: left;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: var(--white);
    width: 720px;
    padding: 32px;
    margin: 95px auto 32px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.02), 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035), 0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
}

.gh-portal-links-main h2 {
    font-weight: 500;
}

.gh-portal-links-main p {
    margin-bottom: 10px;
}

.gh-portal-links-table {
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
}

.gh-portal-links-table tr td {
    white-space: nowrap;
    padding: 10px 12px 0 0;
}

.gh-portal-links-table tr.header h4 {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--midlightgrey-d2);
    font-weight: 500;
    margin-bottom: 8px;
}

.gh-portal-links-table tr.header .gh-portal-links-cell {
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: none;
    color: var(--green);
}

.gh-portal-links-table tr td:last-of-type {
    padding-right: 0;
}

.gh-portal-links-table tr.header .toggle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gh-portal-links-table tr td.pagename {
    font-size: 1.4rem;
    width: 130px;
}

.gh-portal-links-table tr td.pagename.strong {
    font-weight: 600;
}

.gh-portal-page-url-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 5px 4px 5px 8px;
    height: 38px;
    background: var(--whitegrey-l2);
    border-radius: 4px;
    border: 1px solid var(--whitegrey);
    color: var(--darkgrey);
    font-weight: 500;
    width: 100%;
}

.gh-portal-page-url-container .page-url-slash {
    color: var(--midlightgrey);
    font-weight: 400;
}

.gh-portal-page-url-container .page-url-label {
    max-width: 470px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gh-show-modal-link-form .page-url-label {
    max-width: 230px;
}

.gh-portal-page-url-container .page-url-disabled {
    color: var(--midlightgrey);
    font-weight: 400;
}

.gh-portal-links-group-divider {
    margin: 8px -32px;
    border-top-color: var(--whitegrey);
}

.gh-portal-links-group-divider.first {
    margin-top: -4px;
}

.gh-portal-custom-icon {
    display: flex;
    justify-content: center;
    width: 50px;
}

.gh-portal-button-custom.selected-icon:hover {
    box-shadow: 0px 0px 0px 1px color-mod(var(--blue) a(40%));
}

.gh-portal-custom-icon:hover .gh-portal-button-custom.selected-icon {
    display: none;
}

.gh-portal-custom-icon:hover .gh-portal-button-deleteicon {
    display: inline-block;
}

.gh-portal-custom-icon .gh-portal-button-deleteicon {
    display: none;
    background: color-mod(var(--darkgrey) a(0.8));
}

.gh-portal-setting-no-stripe {
    padding: 20px;
    font-size: 1.3rem;
    text-align: center;
    background: var(--whitegrey-l2);
    border: 1px solid var(--whitegrey);
    border-radius: 4px;
    color: var(--midgrey);
}

.gh-portal-setting-section.redirects p {
    margin-top: 4px;
}
